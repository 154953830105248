import { SlideOverFlowConduit, useCancelFlow, useStartFlow } from '@/lib/flow';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageHeading from 'frontend/src/components/PageHeading';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PRODUCTS_QUERY_KEY } from '../api';
import SingleProductView from './components/singleProductView';
import { ProductForm } from '@/form/Product/base';
import toast from 'react-hot-toast';
import { queryClient } from '@/lib/queryClient';
import { productSchema } from 'shared/src/schemas/product';
import {
  fetchProducts,
  saveProduct,
} from 'frontend/src/services/productService';
import { generalTranslations } from '@/generalTranslations';

const CREATE_PRODUCT = 'CREATE_PRODUCT';
const EDIT_PRODUCT = 'EDIT_PRODUCT';

export default function ProductsPage() {
  const { formatMessage } = useIntl();
  const startFlow = useStartFlow();
  const closeFlow = useCancelFlow();

  const [page] = useState<number>(0);
  const [filters] = useState<Record<string, string>[]>([]);
  const { data: products } = useQuery({
    queryKey: [...PRODUCTS_QUERY_KEY, page, filters],

    queryFn: () => {
      return fetchProducts({});
    },
  });

  const { mutate: createProduct, error: errorWhileCreatingProduct } = useMutation({
    mutationFn: saveProduct,
    onSuccess: () => {
      toast.success(formatMessage(t.updatesSaved));
      queryClient
        .invalidateQueries({ queryKey: PRODUCTS_QUERY_KEY })
        .catch(console.error);
      closeFlow();
    },
  });

  if (errorWhileCreatingProduct) {
    console.error(errorWhileCreatingProduct);
    toast.error(
      formatMessage(generalTranslations.failedToSave, {
        resource: formatMessage(t.product).toLowerCase(),
      })
    );
  }

  return (
    <>
      <PageHeading
        title={formatMessage(t.productListPageTitle)}
        actions={[
          {
            label: formatMessage(t.createProduct),
            action: async () => {
              startFlow({ name: CREATE_PRODUCT });
            },
            requiredPermission: 'lessons.write',
          },
        ]}
      />
      <ul role="list" className="flex flex-col gap-4">
        {products?.map((product) => (
          <li key={product.id}>
            <SingleProductView
              product={product}
              onClick={(product) => {
                startFlow({ name: EDIT_PRODUCT, payload: { product } });
              }}
            />
          </li>
        ))}
      </ul>

      <SlideOverFlowConduit
        title={formatMessage(t.productListPageTitle)}
        flowMapping={{
          [CREATE_PRODUCT]: () => (
            <ProductForm
              onSubmit={(data) => {
                console.log(data);
                createProduct(data);
              }}
            />
          ),
          [EDIT_PRODUCT]: (params) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const product = productSchema.parse(params.product);
            return (
              <ProductForm
                onSubmit={(data) => {
                  console.log(data);
                  createProduct(data);
                }}
                defaultValues={product}
              />
            );
          },
        }}
      />
    </>
  );
}

const t = defineMessages({
  productListPageTitle: {
    defaultMessage: 'Products',
    id: 'productListPageTitle',
  },
  createProduct: {
    defaultMessage: 'Create product',
    id: 'createProduct',
  },
  updatesSaved: {
    defaultMessage: 'Updates saved',
    id: 'updatesSaved',
  },
  product: {
    defaultMessage: 'Product',
    id: 'product',
  },
});
