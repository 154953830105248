import PageHeading from 'frontend/src/components/PageHeading';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { generalTranslations } from '@/generalTranslations';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  INVOICES_COUNT_QUERY_KEY,
  INVOICES_QUERY_KEY,
} from '../api/invoicesApi';
import {
  downloadInvoices,
  fetchAmountOfInvoices,
  fetchInvoices,
  regenerateInvoice,
} from '@/services/invoicesService';
import Pagination from 'frontend/src/components/pagination';
import { downloadFile } from 'frontend/src/lib/storage';
import { ArrowDownTrayIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { formatAsValuta } from 'shared/src/utils/valutaFormatter';
import { queryClient } from '@/lib/queryClient';
import { useBusinessId } from 'frontend/src/services/businessService';

async function downloadInvoice(invoicePath: string) {
  const file = await downloadFile(invoicePath);
  window.open(file, '_blank');
}

export default function InvoicesPage() {
  const currentDate = new Date();
  const startDateTime = new Date(currentDate.getFullYear(), 0, 1).toISOString();
  const endDateTime = currentDate.toISOString();
  const businessId = useBusinessId();

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { data: invoices, error } = useQuery({
    queryKey: [...INVOICES_QUERY_KEY, page],

    queryFn: () => {
      return fetchInvoices({ page });
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.invoices).toLowerCase(),
      })
    );
  }

  const { data: amountOfInvoices } = useQuery({
    queryKey: [...INVOICES_COUNT_QUERY_KEY],

    queryFn: () => {
      return fetchAmountOfInvoices({});
    },

    initialData: 0,
  });

  const { mutate: regenInvoice, isPending: isGeneratingInvoice } = useMutation({
    mutationFn: (invoiceNr: string) => regenerateInvoice({ invoiceNr }),
    onSuccess: () => {
      toast.success(formatMessage(t.invoiceRegeneratedSuccessfully));
      queryClient.invalidateQueries({ queryKey: INVOICES_QUERY_KEY });
    },
    onError: (err) => {
      console.error(err);
      toast.error(formatMessage(t.someErrorOccurredWhileRegeneratingInvoice));
    },
  });

  return (
    <>
      <PageHeading
        title={formatMessage(t.invoices)}
        actions={[
          {
            label: formatMessage(t.download),
            action: () => {
              const toastId = toast.loading(formatMessage(t.downloadingInvoices));
              downloadInvoices({
                start: startDateTime,
                end: endDateTime,
                businessId: businessId,
              }).then((downloadUrl) => {
                console.log('Invoice download started:', downloadUrl);
                toast.success(formatMessage(t.downloadCompleted));
              }).catch((error) => {
                console.error('Error downloading invoices:', error);
                toast.error(formatMessage(generalTranslations.somethingWentWrong));
              }).finally(() => {
                toast.dismiss(toastId);
              })
            },
            requiredPermission: 'invoices.read',
          }
        ]}
      />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      {formatMessage(t.invoiceNumber)}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      {formatMessage(t.creationDate)}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {formatMessage(t.user)}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
                    >
                      {formatMessage(t.total)}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {invoices?.map((invoice) => (
                    <tr key={invoice.id}>
                      <td className="px-3 py-5 text-sm text-gray-900 font-medium">
                        {invoice.invoiceNumber}
                      </td>
                      <td className="px-3 py-5 text-sm text-gray-500">
                        <div className="font-medium text-gray-900">
                          {invoice.createdAt.toLocaleDateString()}
                        </div>
                        <div className="mt-1 text-gray-500">
                          {invoice.createdAt.toLocaleTimeString()}
                        </div>
                      </td>
                      <td className="px-3 py-5 text-sm text-gray-500">
                        <a
                          className="cursor-pointer underline hover:text-blue-900 text-blue-500"
                          onClick={() => {
                            navigate(`/users/${invoice.client.id}`);
                          }}
                        >
                          {invoice.client.firstName} {invoice.client.lastName}
                        </a>
                      </td>

                      <td className="px-3 py-5 text-sm text-gray-500 text-center">
                        {formatAsValuta(invoice.totals.total)}
                      </td>
                      <td>
                        <div className="flex justify-around">
                          <button
                            disabled={isGeneratingInvoice}
                            className="disabled:cursor-not-allowed disabled:bg-gray-700 disabled:text-gray-400 rounded-lg p-1"
                            onClick={() => regenInvoice(invoice.invoiceNumber)}
                          >
                            <ArrowPathIcon
                              className={`h-6 w-6 ${isGeneratingInvoice && 'animate-spin'
                                }`}
                            />
                          </button>
                          <button onClick={() => downloadInvoice(invoice.url)}>
                            <ArrowDownTrayIcon className="h-6 w-6" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalItems={amountOfInvoices}
                page={page}
                nextPage={() => setPage(page + 1)}
                previousPage={() => setPage(page - 1)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const t = defineMessages({
  invoices: {
    defaultMessage: 'Invoices',
    description: '',
    id: 'InvoicesPage-title',
  },
  invoiceNumber: {
    defaultMessage: 'Invoice number',
    description: '',
    id: 'InvoicesPage-invoiceNumber',
  },
  creationDate: {
    defaultMessage: 'Creation date',
    description: '',
    id: 'InvoicesPage-creationDate',
  },
  user: {
    defaultMessage: 'User',
    description: '',
    id: 'InvoicesPage-user',
  },
  products: {
    defaultMessage: 'Products',
    description: '',
    id: 'InvoicesPage-products',
  },
  total: {
    defaultMessage: 'Total (incl. vat)',
    description: '',
    id: 'InvoicesPage-total',
  },
  someErrorOccurredWhileRegeneratingInvoice: {
    defaultMessage: 'Some error occurred while regenerating invoice',
    description: '',
    id: 'InvoicesPage-someErrorOccurredWhileRegeneratingInvoice',
  },
  invoiceRegeneratedSuccessfully: {
    defaultMessage: 'Invoice regenerated successfully',
    description: '',
    id: 'InvoicesPage-invoiceRegeneratedSuccessfully',
  },
  download: {
    defaultMessage: 'Download all',
    description: '',
    id: `InvoicePage_create`,
  },
  downloadCompleted: {
    defaultMessage: 'Download completed',
    description: '',
    id: `InvoicePage_downloadCompleted`,
  },
  downloadingInvoices: {
    defaultMessage: 'Downloading invoices',
    description: '',
    id: `InvoicePage_downloadingInvoices`,
  },
});
