import { useState } from 'react';
import {
  Calendar,
  momentLocalizer,
  View,
  Formats,
  EventPropGetter,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { CalendarEvent } from 'shared/src/schemas/calendarEvent';
import { useQuery } from '@tanstack/react-query';

moment.locale('nl', {
  week: {
    dow: 1,
    doy: 1,
  },
});

export function EventsCalendar({
  events,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onViewChange = () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCreation = () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelection = () => { },
  defaultView,
}: {
  events: CalendarEvent[];
  onViewChange?: ViewChangeFn;
  onCreation?: ({ start, end }: { start: Date; end: Date }) => void;
  onSelection?: ({ eventId }: { eventId: string }) => void;
  defaultView?:
  | {
    start: Date;
    end: Date;
    view: string;
  }
  | undefined;
}) {
  const { changeView, calendarOptions } = useChangeView(
    onViewChange,
    defaultView
  );
  return (
    <Calendar
      selectable
      className="h-[calc(100vh-12rem)]"
      dayLayoutAlgorithm="no-overlap"
      eventPropGetter={eventStyle}
      events={events}
      formats={formats}
      defaultView={(defaultView?.view as View) || calendarOptions.view}
      defaultDate={calendarOptions.date}
      localizer={momentLocalizer(moment)}
      enableAutoScroll={true}
      onNavigate={(date, view) => {
        changeView({
          date,
          view,
        });
      }}
      onSelectEvent={(e) => {
        onSelection({ eventId: e.id });
      }}
      onSelectSlot={(info) => {
        onCreation({ start: info.start, end: info.end });
      }}
      onView={(view) => {
        changeView({
          ...calendarOptions,
          view,
        });
      }}
    ></Calendar>
  );
}

const formats: Formats = {
  dateFormat: 'DD/MM',
  weekdayFormat: 'ddd',
  dayRangeHeaderFormat: ({ start, end }) =>
    `${moment(start).format('DD/MM/YYYY')} - ${moment(end).format(
      'DD/MM/YYYY'
    )}`,
  dayHeaderFormat: (date) => `${moment(date).format('dddd DD/MM/YYYY')}`,
  monthHeaderFormat: (date) => `${moment(date).format('MMMM YYYY')}`,
  selectRangeFormat: ({ start, end }) =>
    `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
  timeGutterFormat: 'HH:mm',
  dayFormat: 'ddd DD/MM',
  agendaTimeFormat: 'HH:mm',
  agendaDateFormat: 'ddd DD/MM',
  agendaHeaderFormat: ({ start, end }) =>
    `${moment(start).format('DD/MM/YYYY')} - ${moment(end).format(
      'DD/MM/YYYY'
    )}`,
  agendaTimeRangeFormat: ({ start, end }) =>
    `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
  eventTimeRangeFormat: ({ start, end }) =>
    `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
};

type ViewChangeFn = ({
  startDate,
  endDate,
  view,
}: {
  startDate: Date;
  endDate: Date;
  view: View;
}) => void;

function useChangeView(
  onViewChange: ViewChangeFn,
  defaultView?: {
    start: Date;
    end: Date;
    view: string;
  }
) {
  const [calendarOptions, setCalendarOptions] = useState<{
    date: Date;
    view: View;
  }>({
    date: defaultView?.start || new Date(),
    view: (defaultView?.view as View) || 'week',
  });
  const changeView = (calendarOptions: { date: Date; view: View }) => {
    setCalendarOptions(calendarOptions);
    const { start: startDate, end: endDate } =
      getStartAndEndDate(calendarOptions);
    onViewChange({
      startDate,
      endDate,
      view: calendarOptions.view,
    });
  };
  useQuery({
    queryKey: ['calendarOptions', 'viewChanged'],

    queryFn: () => {
      const { start: startDate, end: endDate } =
        getStartAndEndDate(calendarOptions);
      onViewChange({
        startDate,
        endDate,
        view: calendarOptions.view,
      });
      return true;
    },
  });
  return {
    calendarOptions,
    changeView,
  };
}

function getStartAndEndDate(calendarOptions: { date: Date; view: View }) {
  let start = new Date();
  let end = new Date();
  switch (calendarOptions.view) {
    case 'month':
      start = moment(calendarOptions.date).startOf('month').toDate();
      end = moment(calendarOptions.date).endOf('month').toDate();
      break;
    case 'week' || 'work_week':
      start = moment(calendarOptions.date).startOf('week').toDate();
      end = moment(calendarOptions.date).endOf('week').toDate();
      break;
    case 'day':
      start = moment(calendarOptions.date).startOf('day').toDate();
      end = moment(calendarOptions.date).endOf('day').toDate();
      break;
    case 'agenda':
      start = moment(calendarOptions.date).startOf('day').toDate();
      end = moment(calendarOptions.date).add(1, 'months').toDate();
  }
  return { start, end };
}

const eventStyle: EventPropGetter<CalendarEvent> = (event) => {
  const style = {
    ...event.extraBg,
    opacity: 1,
    backgroundColor: event.color || "white",
    color: event.color || event.cancelled ? "white" : "black",
    outlineRadius: '6px',
    outlineWidth: event.cancelled || event.color ? '0px' : '2px',
    outlineColor: event.color || "grey",
  };
  return {
    style,
    className: event.cancelled ? 'cancelled relative' : '',
  };
};
