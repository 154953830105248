import { FieldValues } from 'react-hook-form';
import { CheckboxInput, Props } from '../input/CheckboxInput';
import { FormField, FormFieldProps } from '../FormField';

export interface ShortTextFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
    Props<TFieldValues> {}

export function CheckboxField<TFieldValues extends FieldValues>({
  name,
  disabled,
  hint,
  label,
}: ShortTextFieldProps<TFieldValues>) {
  return (
    <FormField hint={hint} name={name}>
      <div className="flex gap-2">
        <div className="w-min">
          <CheckboxInput name={name} disabled={disabled} />
        </div>
        <label htmlFor={name}>{label}</label>
      </div>
    </FormField>
  );
}
