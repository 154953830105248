import { Form } from 'frontend/src/layers/form/components/Form';
import { FileField } from 'frontend/src/layers/form/components/fields/FileField';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { collectionNames } from 'shared/src/collectionNames';
import { Branding, brandingSchema } from 'shared/src/schemas/branding';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { ColorField } from 'frontend/src/layers/form/components/fields/ColorField';
import toast from 'react-hot-toast';
import { generalTranslations } from '@/generalTranslations';

export interface BrandingFormProps {
  defaultValues?: Branding;
  onSubmit: (data: Branding) => void;
}

export default function BrandingForm({
  onSubmit,
  defaultValues,
}: BrandingFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();
  const formReturn = useForm<Branding>({
    resolver: zodResolver(brandingSchema),
    defaultValues,
  });

  return (
    <div className="md:w-9/12">
      <Form
        onErrors={(errors) => {
          console.log(errors)
          toast.error(formatMessage(generalTranslations.somethingWentWrong))
        }}
        OnSubmit={onSubmit}
        formReturn={formReturn}
        readOnly={!isAllowedTo('settings.write')}
      >
        <ColorField
          name="primaryColor"
          label={formatMessage(t.primaryColor)}
          hint={formatMessage(t.primaryColorDescription)}
        />
        <ColorField
          name="secondaryColor"
          label={formatMessage(t.secondaryColor)}
        />
        <div className="grid grid-cols-2 gap-4">
          <FileField
            cacheIt
            name="logo"
            label={formatMessage(t.logo)}
            hint={formatMessage(t.logoDescription)}
            filePath={collectionNames.branding}
          />
          <FileField
            cacheIt
            name="logoLoginScreen"
            label={formatMessage(t.logoLoginScreen)}
            hint={formatMessage(t.logoLoginScreenDescription)}
            filePath={collectionNames.branding}
          />
        </div>
        <FileField
          cacheIt
          name="loginImage"
          label={formatMessage(t.loginImage)}
          hint={formatMessage(t.loginImageDescription)}
          filePath={collectionNames.branding}
        />
      </Form>
    </div>
  );
}

const t = defineMessages({
  primaryColor: {
    id: 'BrandingForm_primaryColor',
    defaultMessage: 'Primary Color',
  },
  primaryColorDescription: {
    id: 'BrandingForm_primaryColorDescription',
    defaultMessage: 'Choose the primary color of your club.',
  },
  secondaryColor: {
    id: 'BrandingForm_secondaryColor',
    defaultMessage: 'Secondary Color',
  },
  logo: {
    id: 'BrandingForm_logo',
    defaultMessage: 'Logo',
  },
  logoDescription: {
    id: 'BrandingForm_logoDescription',
    defaultMessage: 'Upload your logo',
  },
  logoLoginScreen: {
    id: 'BrandingForm_logoLoginScreen',
    defaultMessage: 'Logo for login screen',
  },
  logoLoginScreenDescription: {
    id: 'BrandingForm_logoLoginScreenDescription',
    defaultMessage: 'Optional: Choose a different logo for the login screen.',
  },
  loginImage: {
    id: 'BrandingForm_loginImage',
    defaultMessage: 'Login Image',
  },
  loginImageDescription: {
    id: 'BrandingForm_loginImageDescription',
    defaultMessage: 'Choose the image you see on the login screen.',
  },
});
