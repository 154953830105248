import { createBrowserRouter } from 'react-router-dom';
import { LocaleRoute } from './components/LocaleRoute';
import DefaultLayout from './layouts';
import LessonsPage from './pages/lessons/list';
import TempDashboard from './pages/tempDashboard';
import CategoriesPage from './pages/categories/list';
import UsersPage from './pages/users/list';
import UsersDetailsPage from './pages/users/detail';
import AvailabilitiesPage from './pages/availabilities';
import LocationsPage from './pages/locations/list';
import ReservationsList from './pages/reservations/list';
import OrdersPage from './pages/orders/list';
import InvoicesPage from './pages/invoices/list';
import BusinessSettingsPage from './pages/settings/businessSettings';
import BrandingSettingsPage from './pages/settings/branding';
import { CanSeePage } from 'frontend/src/layers/canSeePage/components';
import ProductsPage from './pages/products/list';
import { ErrorComponent } from './pages/error/ErrorComponent';
import { CheckLogin } from 'frontend/src/components/CheckLogin';

const SUPPORTED_LOCALES = ['en', 'nl', 'fr'] as const;
export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];

export const browserRouter = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorComponent />,
    element: (
      <CheckLogin>
        <CanSeePage permission={'admin.dashboard'} module={'dashboard'}>
          <DefaultLayout>
            <LocaleRoute />
          </DefaultLayout>
        </CanSeePage>
      </CheckLogin>
    ),

    children: [
      {
        index: true,
        element: <TempDashboard />,
        errorElement: <ErrorComponent />,
      },
      {
        path: 'lessons',
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'lessons.read'} module={'lessons'}>
                <LessonsPage />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
        ],
      },
      {
        path: 'products',
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'products.read'} module={'products'}>
                <ProductsPage />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
        ],
      },
      {
        path: 'categories',
        errorElement: <ErrorComponent />,
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'categories.read'} module='categories'>
                <CategoriesPage />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
        ],
      },
      {
        path: 'users',
        errorElement: <ErrorComponent />,
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'users.read'} module='users'>
                <UsersPage />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
          {
            path: ':userId',
            errorElement: <ErrorComponent />,
            element: (
              <CanSeePage permission={'users.write'} module='users'>
                <UsersDetailsPage />
              </CanSeePage>
            ),
          },
        ],
      },
      {
        path: 'locations',
        errorElement: <ErrorComponent />,
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'locations.read'} module='locations'>
                <LocationsPage />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
        ],
      },
      {
        path: 'availabilities',
        errorElement: <ErrorComponent />,
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'availabilities.read'} module='availabilities'>
                <AvailabilitiesPage />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
        ],
      },
      {
        path: 'reservations',
        errorElement: <ErrorComponent />,
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'reservations.read'} module='reservations'>
                <ReservationsList />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
        ],
      },
      {
        path: 'orders',
        errorElement: <ErrorComponent />,
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'orders.read'} module='orders'>
                <OrdersPage />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
        ],
      },
      {
        path: 'invoices',
        errorElement: <ErrorComponent />,
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'invoices.read'} module='invoices'>
                <InvoicesPage />
              </CanSeePage>
            ),
            errorElement: <ErrorComponent />,
          },
        ],
      },
      {
        path: 'settings',
        errorElement: <ErrorComponent />,
        children: [
          {
            index: true,
            element: (
              <CanSeePage permission={'settings.read'}>
                <BusinessSettingsPage />
              </CanSeePage>
            ),
          },
          {
            path: 'branding',
            errorElement: <ErrorComponent />,
            element: (
              <CanSeePage permission={'settings.read'} module="branding">
                <BrandingSettingsPage />
              </CanSeePage>
            ),
          },
        ],
      },
    ],
  },
]);
