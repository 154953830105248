import { IsAuthorized } from '../../authorization/components/isAuthorized';
import { Permission } from '../../authorization/permissions';
import { IsModuleIsActivated } from '../../moduleActivated/component/isModuleIsActivated';
import { ReactNode } from 'react';
import { ModuleName } from 'shared/src/schemas/modules';

export function CanSeePage({
  children,
  module,
  permission,
  isSuperAdmin,
}: {
  children: ReactNode;
  module?: ModuleName;
  permission: Permission;
  isSuperAdmin?: boolean;
}) {
  return (
    <IsModuleIsActivated module={module}>
      <IsAuthorized permission={permission} isSuperAdmin={isSuperAdmin}>
        {children}
      </IsAuthorized>
    </IsModuleIsActivated>
  );
}
