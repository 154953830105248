import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { BaseList } from "./base";
import { useQuery } from "@tanstack/react-query";
import { RESERVATIONS_QUERY_KEY } from "@/pages/reservations/api/reservationsApi";
import { fetchReservations } from "frontend/src/services/reservationService";
import { DateTime } from "luxon";
import { mapCalendarEvents } from "@/pages/reservations/api/mapper";
import { CalendarEvent } from "shared/src/schemas/calendarEvent";

export default function UpcomingReservationsWidget() {
  const { formatMessage } = useIntl();
  const { data: reservations, isLoading } = useQuery({
    queryKey: [...RESERVATIONS_QUERY_KEY, "upcoming"],
    queryFn: async () => {
      const results = fetchReservations({
        start: new Date(),
        n: 50,
        order: "start"
      })
      return mapCalendarEvents(
        await results,
        true
      )
    }
  })
  return (
    <BaseList
      isLoading={isLoading}
      items={(reservations || []).map(r => ({
        id: r.id,
        display: SingleReservation(r),
      }))}
      title={formatMessage(t.upcomingReservations)}
    />
  );
}

const SingleReservation = (r: CalendarEvent) => {
  const { formatMessage } = useIntl()
  const start = DateTime.fromJSDate(r.start)
  const startTime: string = (r.start).toLocaleTimeString()
  const startTimeWithoutSeconds = startTime.substring(0, startTime.length - 3)
  const firstReservation = r.reservationsWithAllData![0]
  return (
    <div className="flex gap-4 px-2">
      <div className="flex flex-col text-center text-xs pt-1">
        <div>{start.monthLong}</div>
        <div className="text-lg text-white bg-primary-300 rounded-full grid place-content-center h-8 w-8">{start.day}</div>
        <div>{startTimeWithoutSeconds}</div>
      </div>
      <div className="flex flex-col ">
        <div className="text-lg  relative flex gap-2 whitespace-nowrap">
          {firstReservation.lesson.name}
          <div className="text-gray-700 font-thin overflow-hidden whitespace-nowrap text-ellipsis">
            (
            {r.reservationsWithAllData?.map(x => x.person?.firstName || x.user.firstName).join(', ')}
            )
          </div>
        </div>
        <div className="text-xs text-gray-500">
          {firstReservation.location.name}
        </div>
        <div className="text-xs text-gray-500 mt-1">
          {firstReservation.trainers.length ? firstReservation.trainers.map(t => t.firstName).join(', ') : formatMessage(t.noTrainer)}
        </div>
      </div>
    </div>
  )
}

const t = defineMessages({
  upcomingReservations: {
    id: "upcoming_reservations_widget_upcoming_reservations",
    defaultMessage: "Upcoming reservations"
  },
  noTrainer: {
    id: "no_trainer",
    defaultMessage: "No trainer linked"
  }
});