import { Product, productSchema } from 'shared/src/schemas/product';
import { collectionNames } from 'shared/src/collectionNames';
import { appendBusiness, createBusinessQuery } from './businessService';
import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import { getFirestore } from '../lib/firebase';
import { any } from 'zod';
import { categorySchema } from 'shared/src/schemas/category';
import { uploadFile } from '../lib/storage';

export const pageSize = 10;
let pages: {
  page: number;
  lastVisible: QueryDocumentSnapshot<DocumentData> | undefined;
}[] = [];

export const fetchProductSchema = productSchema.extend({
  category: any().transform(async (ref: DocumentReference) => {
    const snap = await getDoc(ref);
    return categorySchema.parse(snap.data());
  }),
});

/**
 * Get all products from firebase
 * @param {boolean?} showHidden
 * @param {boolean?} includeArchived
 * @returns {Promise<Product[]>}
 */
export const fetchProducts = async ({
  includeArchived = false,
  page = 0,
  ids = [],
}: {
  ids?: string[];
  page?: number;
  includeArchived?: boolean;
}): Promise<Product[]> => {
  const lastVisible = pages.find((p) => p.page === page - 1)?.lastVisible;

  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.products),
    ...(ids.length ? [where('id', 'in', ids)] : []),
    ...(page && lastVisible ? [startAfter(lastVisible)] : []),
    ...(includeArchived ? [] : [where('isArchived', '==', false)]),
    limit(pageSize)
  );

  const documentSnapShot = await getDocs(q);

  pages = [
    ...pages,
    {
      page,
      lastVisible: documentSnapShot.docs[documentSnapShot.docs.length - 1],
    },
  ];

  return Promise.all(
    documentSnapShot.docs.map(async (doc) => {
      const data = doc.data();
      return fetchProductSchema.parseAsync({
        ...data,
        id: doc.id,
      });
    })
  );
};

export const saveProduct = async (prod: Product) => {
  const product = {
    ...(await appendBusiness(prod)),
    category: doc(getFirestore(), collectionNames.categories, prod.category.id),
  };

  if (!product.image) {
    const imageUrl = `https://api.dicebear.com/6.x/shapes/png?seed=${product.id}`;
    const image = new File(
      [await (await fetch(imageUrl)).blob()],
      `${product.name}.svg`
    );
    const uploadedFile = await uploadFile({
      path: collectionNames.lesson,
      file: image,
    });

    product.image = uploadedFile.path;
  }

  console.log('Saving product', product);

  const productDoc = doc(getFirestore(), collectionNames.products, product.id);

  await setDoc(productDoc, product);
};
