import { useCancelFlow } from '@/lib/flow';
import Modal from 'frontend/src/components/Modal';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

export default function ChooseLessonTypeDialog({
  onCreditClick,
  onCycleClick,
}: {
  onCreditClick: () => void;
  onCycleClick: () => void;
}) {
  const close = useCancelFlow();
  const { formatMessage } = useIntl();

  return (
    <Modal onClose={close}>
      <h1 className="text-2xl mb-4">{formatMessage(t.chooseLessonType)}</h1>
      <div className="flex gap-4 ">
        <button
          className="w-full flex flex-col items-center text-xl rounded-md px-3 py-2 align-top font-semibold text-primary-500 border-primary-500 border shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 outline-primary-200 hover:bg-primary-900"
          onClick={onCreditClick}
        >
          {formatMessage(t.creditLesson)} <br />
          <span className="text-sm mt-2">
            {formatMessage(t.creditLessonDescription)}
          </span>
        </button>
        <button
          className="w-full flex flex-col items-center text-xl rounded-md px-3 py-2 align-top font-semibold text-primary-500 border-primary-500 border shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 outline-primary-200 hover:bg-primary-900"
          onClick={onCycleClick}
        >
          {formatMessage(t.cycleLesson)} <br />
          <span className="text-sm mt-2">
            {formatMessage(t.cycleLessonDescription)}
          </span>
        </button>
      </div>
    </Modal>
  );
}

const t = defineMessages({
  chooseLessonType: {
    defaultMessage: 'Choose lesson type',
    id: 'ChooseLessonTypeDialog_chooseLessonType',
  },
  chooseLessonTypeDescription: {
    defaultMessage: 'What type of lesson do you want to create?',
    id: 'ChooseLessonTypeDialog_chooseLessonTypeDescription',
  },
  creditLesson: {
    defaultMessage: 'Credit Lesson',
    id: 'ChooseLessonTypeDialog_creditLesson',
  },
  creditLessonDescription: {
    defaultMessage: 'One time lesson',
    id: 'ChooseLessonTypeDialog_creditLessonDescription',
  },
  cycleLesson: {
    defaultMessage: 'Cycle Lesson',
    id: 'ChooseLessonTypeDialog_cycleLesson',
  },
  cycleLessonDescription: {
    defaultMessage: 'Recurring lesson',
    id: 'ChooseLessonTypeDialog_cycleLessonDescription',
  },
});
