import { z, optional } from 'zod';
import { categorySchema } from './category';

export const productSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  price: z.number(),
  image: optional(z.string()),
  createdAt: optional(z.date()),
  updatedAt: optional(z.date()),
  stripeProductId: optional(z.string()),
  stripePriceId: optional(z.string()),
  category: categorySchema,
  isPublic: z.boolean().default(false),
  isArchived: z.boolean().default(false),
  vat: z.number().default(6),
});

export type Product = z.infer<typeof productSchema>;
