import { collectionNames } from 'shared/src/collectionNames';
import { TimeSlot, timeSlotSchema } from 'shared/src/schemas/timeSlot';
import {
  createBusinessQuery,
  currentBusinessId,
} from 'frontend/src/services/businessService';
import { getFirestore, getFunctions } from 'frontend/src/lib/firebase';
import {
  DocumentReference,
  collection,
  doc,
  deleteDoc,
  getDoc,
  getDocs,
  where,
} from 'firebase/firestore';
import { any } from 'zod';
import { locationSchema } from 'shared/src/schemas/location';
import { fetchCurrentUser } from 'frontend/src/services/currentUserService';
import { httpsCallable } from 'firebase/functions';
import timeSlotMerger from 'shared/src/utils/timeSlotMerger';
import { fetchUserSchema } from 'frontend/src/services/usersService';

const fetchTimeSlotSchema = timeSlotSchema.extend({
  location: any().transform(async (ref: DocumentReference) => {
    // console.log(`Fetching location ${ref.id}`, ref)
    const snap = await getDoc(ref);
    return locationSchema.parse({
      ...snap.data(),
      id: ref.id,
    });
  }),
  trainer: any().transform(async (ref: DocumentReference) => {
    if (!ref) return undefined;
    // console.log(`Fetching trainer ${ref.id}`, ref)
    const snap = await getDoc(ref);
    return fetchUserSchema.parseAsync(snap.data());
  }),
});

export const fetchTimeSlots = async ({
  locationIds,
  trainerIds,
  start,
  end,
}: {
  locationIds?: string[];
  trainerIds?: string[];
  start: Date;
  end: Date;
}): Promise<TimeSlot[]> => {
  let locationsToFilterOn: string[] = locationIds ? locationIds : [];
  let trainerFilterOn: string[] = trainerIds ? trainerIds : [];
  const currentUser = await fetchCurrentUser({});
  const businessId = await currentBusinessId();

  const isAdmin = currentUser?.roles[businessId].includes('admin');
  const isManager = currentUser?.roles[businessId].includes('manager');
  const isTrainer = currentUser?.roles[businessId].includes('trainer');

  // check if user is manager and get locationIds
  if (!currentUser) throw new Error('no user');

  if (isManager && !isAdmin) {
    locationsToFilterOn =
      currentUser.managingLocations?.[businessId]?.map((loc) => loc.id) || [];
  }

  if (isTrainer && !isManager && !isAdmin) {
    trainerFilterOn = [currentUser.id];
  }

  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.timeSlot),
    where('start', '>=', start),
    where('start', '<=', end),
    ...(locationsToFilterOn.length
      ? [
        where(
          'location',
          'in',
          locationsToFilterOn.map((id) =>
            doc(getFirestore(), collectionNames.addresses, id)
          )
        ),
      ]
      : []),
    ...(trainerFilterOn?.length
      ? [
        where(
          'trainer',
          'in',
          trainerFilterOn.map((id) =>
            doc(getFirestore(), collectionNames.users, id)
          )
        ),
      ]
      : [])
  );

  const documentSnapShot = await getDocs(q);

  const timeslots = documentSnapShot.docs.map(async (doc) => {
    return fetchTimeSlotSchema.parseAsync({
      id: doc.id,
      ...doc.data(),
      start: doc.data().start.toDate(),
      end: doc.data().end.toDate(),
    });
  });

  return timeSlotMerger(await Promise.all(timeslots));
};

export const saveSlot = async (availability: TimeSlot) => {
  return httpsCallable(
    getFunctions(),
    'availabilities-save'
  )({
    start: availability.start,
    end: availability.end,
    locationId: availability.location.id,
    trainerId: availability.trainer?.id,
  });
};

export const deleteAvailability = async (ids: string[]) => {
  const firestore = getFirestore();

  // Use Promise.all to delete multiple documents concurrently
  await Promise.all(
    ids.map(async (id) => {
      const locDoc = doc(firestore, collectionNames.timeSlot, id);
      await deleteDoc(locDoc);
    })
  );
};

export async function getAvailabilitiesForLesson({
  lessonId,
  locationId,
  start,
  end,
}: {
  lessonId: string;
  locationId: string;
  start: Date;
  end: Date;
}): Promise<
  {
    trainer: string;
    available: boolean;
  }[]
> {
  console.log({ lessonId, locationId, start, end });

  const slots = await httpsCallable(
    getFunctions(),
    'availabilities-fetchTrainerAvailability'
  )({
    lessonId,
    locationId,
    start: start.toISOString(),
    end: end.toISOString(),
  });

  if (!slots.data) return [];
  return slots.data as {
    trainer: string;
    available: boolean;
  }[];
}
