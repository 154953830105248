import { FieldValues } from 'react-hook-form';
import { Props, ColorInput } from '../input/ColorInput';
import { FormField, FormFieldProps } from '../FormField';

export interface ColorFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
    Props<TFieldValues> {}

export function ColorField<TFieldValues extends FieldValues>({
  name,
  prefix,
  postfix,
  disabled,
  hint,
  label,
}: ColorFieldProps<TFieldValues>) {
  return (
    <FormField label={label} hint={hint} name={name}>
      <ColorInput
        name={name}
        prefix={prefix}
        postfix={postfix}
        disabled={disabled}
      />
    </FormField>
  );
}
