import { useCurrentUser } from '../../../services/authService';
import { currentBusinessId } from '../../../services/businessService';
import { hasPermission } from '../hasPermission';
import { Permission } from '../permissions';

export function useIsAuthorized(isSuperAdmin?: boolean) {
  const { user: currentUser } = useCurrentUser();
  let businessId = currentBusinessId();
  if (isSuperAdmin) {
    businessId = 'superAdmin';
  }

  return function isAllowedTo(permission: Permission) {
    return hasPermission(permission, businessId, currentUser)
  }
}
