import { defineMessages } from 'react-intl';

export const generalTranslations = defineMessages({
  failedToFetch: {
    id: 'general-failedToFetch',
    defaultMessage: 'Failed to fetch {resource}',
    values: {
      resource: 'resource',
    },
  },
  somethingWentWrong: {
    id: 'general-somethingWentWrong',
    defaultMessage: 'Something went wrong',
  },
  delete: {
    id: 'general-delete',
    defaultMessage: 'Delete {resource}',
    values: {
      resource: 'resource',
    },
  },
  saving: {
    id: 'general-saving',
    defaultMessage: 'Saving {resource}',
    values: {
      resource: 'resource',
    },
  },
  failedToSave: {
    id: 'general-failedToSave',
    defaultMessage: 'Failed to save {resource}',
    values: {
      resource: 'resource',
    },
  },
  failedToDelete: {
    id: 'general-failedToSave',
    defaultMessage: 'Failed to save {resource}',
    values: {
      resource: 'resource',
    },
  },
  saved: {
    id: 'general-saved',
    defaultMessage: 'Saved {resource}',
    values: {
      resource: 'resource',
    },
  },
  days: {
    id: 'general-days',
    defaultMessage: 'Days',
  },
  hours: {
    id: 'general-hours',
    defaultMessage: 'Hours',
  },
  loading: {
    id: 'general-loading',
    defaultMessage: 'Loading',
  },
});
