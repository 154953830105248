import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { ReactNode } from 'react';
import { atom, createStore, Provider, useAtom } from 'jotai';
import { IntlProvider } from 'react-intl';
import en from '../../../../translations/en.json';
import nl from '../../../../translations/nl.json';
import fr from '../../../../translations/fr.json';
import { defaultLocale } from '../utils/defaults';
import { FlowProvider } from '@/lib/flow';
import { AlgoliaProvider } from '@/components/AlgoliaUsersProvider';
import { queryClient } from '@/lib/queryClient';
import { AuthStateProvider } from '@/components/AuthStateProvider';
import { BrandingLoader } from 'frontend/src/components/BrandingLoader';
import { BusinessProvider } from '@/components/BusinessProvider';
import { initFirebase } from 'frontend/src/lib/firebase';
import { initSentry } from 'frontend/src/lib/sentry';
import { sentryConfig } from '../../../../config';

const store = createStore();
export const localeAtom = atom(defaultLocale);
store.set(localeAtom, defaultLocale);

const IntlProviderWrapper = ({ children }: { children: ReactNode }) => {
  const [locale] = useAtom(localeAtom);

  const localeMessages = { en, nl, fr }[locale] || en;
  initSentry(sentryConfig.adminDsn);
  return (
    <IntlProvider locale={locale} messages={localeMessages} key={locale}>
      {children}
    </IntlProvider>
  );
};

export const AppProviders = ({ children }: { children: ReactNode }) => {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);
  const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('@tanstack/react-query-devtools/build/modern/production.js').then(
      (d) => ({
        default: d.ReactQueryDevtools,
      })
    )
  );

  initFirebase('admin');
  return (
    <QueryClientProvider client={queryClient}>
      <BusinessProvider>
        <AuthStateProvider>
          <AlgoliaProvider>
            <Provider store={store}>
              <FlowProvider>
                <IntlProviderWrapper>
                  <>
                    <BrandingLoader />
                    {children}
                  </>
                </IntlProviderWrapper>
              </FlowProvider>
            </Provider>
          </AlgoliaProvider>
        </AuthStateProvider>
      </BusinessProvider>
      <ReactQueryDevtools initialIsOpen />
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
    </QueryClientProvider>
  );
};
