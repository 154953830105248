import { Form } from './components/Form';
import { HiddenField } from './components/fields/HiddenField';
import { ShortTextField } from './components/fields/ShortTextField';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Location, locationSchema } from 'shared/src/schemas/location';
import { v4 as uuid } from 'uuid';

export interface AddressInfoFormProps {
  defaultValues?: Location;
  onSubmit: (data: Location) => void;
  onErrors: (errors: unknown) => void;
}

export default function AddressInfoForm({
  onSubmit,
  defaultValues,
  onErrors
}: AddressInfoFormProps) {
  const { formatMessage } = useIntl();
  const formReturn = useForm<Location>({
    resolver: zodResolver(locationSchema),
    defaultValues: {
      id: uuid(),
      name: 'home',
      mailBox: '',
      ...defaultValues,
    },
  });

  return (
    <Form
      onErrors={onErrors}
      OnSubmit={onSubmit}
      formReturn={formReturn}>
      <HiddenField name="id" />
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-3">
          <ShortTextField name="street" label={formatMessage(t.street)} />
        </div>
        <ShortTextField name="number" label={formatMessage(t.number)} />
        <ShortTextField name="mailBox" label={formatMessage(t.mailbox)} />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <ShortTextField name="zipCode" label={formatMessage(t.zipCode)} />
        <ShortTextField name="city" label={formatMessage(t.city)} />
        <ShortTextField name="country" label={formatMessage(t.country)} />
      </div>
      <button
        className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary-600 focus-visible:outline-primary-600 hover:bg-primary-500`}
        type="submit"
      >
        Save
      </button>
    </Form>
  );
}

const t = defineMessages({
  street: {
    defaultMessage: 'Street',
    id: 'personalinfoform_street',
  },
  number: {
    defaultMessage: 'Number',
    id: 'personalinfoform_number',
  },
  mailbox: {
    defaultMessage: 'Mailbox',
    id: 'personalinfoform_mailbox',
  },
  zipCode: {
    defaultMessage: 'Zip code',
    id: 'personalinfoform_zipcoode',
  },
  city: {
    defaultMessage: 'City',
    id: 'personalinfoform_city',
  },
  country: {
    defaultMessage: 'Country',
    id: 'personalinfoform_country',
  },
});
