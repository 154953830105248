import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageHeading from 'frontend/src/components/PageHeading';
import {
  getCurrentBusiness,
  getStripeConnectUrl,
  getStripeConnectedStatus,
  updateCurrentBusiness,
} from 'frontend/src/services/businessService';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery } from '@tanstack/react-query';
import { BUSINESS_QUERY_KEY } from '../api/settingsApi';
import { generalTranslations } from '@/generalTranslations';
import { queryClient } from '@/lib/queryClient';
import { BusinessForm } from '@/form/BusinessForm';
import { submitForm } from 'frontend/src/layers/form/utils/submitForm';
import { LoadingBar } from 'frontend/src/components/LoadingBar';
import { useModuleIsActivatedForBusiness } from 'frontend/src/layers/moduleActivated/hooks';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { syncUsers } from 'frontend/src/services/usersService';

export default function BusinessSettingsPage() {
  const { formatMessage } = useIntl();
  const formRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [toastId, setToastId] = useState<string>('')
  const moduleChecker = useModuleIsActivatedForBusiness();

  const { data: business, error } = useQuery({
    queryKey: [...BUSINESS_QUERY_KEY],
    queryFn: () => getCurrentBusiness(),
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.business).toLowerCase(),
      })
    );
  }

  const { mutate: updateBusiness } = useMutation({
    mutationFn: updateCurrentBusiness,
    onSuccess: () => {
      toast.success(formatMessage(t.saved));
      queryClient.invalidateQueries({ queryKey: BUSINESS_QUERY_KEY });
    },
    onError: () => {
      toast.error(formatMessage(t.errorSaving));
    },
  });

  const { mutate: connectStripe, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return getStripeConnectUrl(
        `${window.location.href}?stripeAccountConnected=true`
      );
    },
    onSuccess: (data) => {
      toast.loading(formatMessage(t.redirectingToStripe));
      window.location.replace(data.url);
    },
    onError: () => {
      toast.error(formatMessage(generalTranslations.somethingWentWrong));
    },
  });

  const [searchParams] = useSearchParams();
  const stripeAccountConnected = searchParams.get('stripeAccountConnected');

  useQuery({
    queryKey: [
      ...BUSINESS_QUERY_KEY,
      'stripeAccountConnectedStatus',
      stripeAccountConnected,
      business?.stripeAccountId,
      business?.stripeAccountFinished,
    ],

    queryFn: async () => {
      const { connected } = await getStripeConnectedStatus();
      if (connected) {
        toast.success(formatMessage(t.stripeAccountConnected));
      } else {
        toast.error(formatMessage(t.stripeAccountNotConnected));
      }
      queryClient.invalidateQueries({ queryKey: BUSINESS_QUERY_KEY });
      navigate('/settings');
    },
    enabled: !!stripeAccountConnected,
  });

  const { mutate: syncUsersToAlgolia, error: syncingError, isPending: isSyncingUsers } = useMutation({
    mutationFn: async () => {
      await syncUsers();
      toast.success(formatMessage(t.usersSynced))
      return true
    }
  });
  if (syncingError) {
    console.log(syncingError)
    toast.error(formatMessage(generalTranslations.somethingWentWrong));
  }

  useEffect(() => {
    if (isSyncingUsers) {
      setToastId(toast.loading(formatMessage(t.syncingUsers)))
    } else {
      toast.dismiss(toastId)
    }
  }, [isSyncingUsers])

  if (!business) {
    return null;
  }

  return (
    <>
      {isLoading && <LoadingBar />}
      <PageHeading
        title={formatMessage(t.setting)}
        actions={[
          {
            label: formatMessage(t.save),
            action: () => submitForm(formRef.current),
            requiredPermission: 'settings.write',
          },
        ]}
      />
      <div className="flex justify-between">
        {moduleChecker('branding') && (
          <a
            className="font-bold hover:underline cursor-pointer text-primary-700"
            onClick={() => navigate('/settings/branding')}
          >
            {formatMessage(t.updateBranding)}
          </a>
        )}

        {(!business.stripeAccountId || !business.stripeAccountFinished) && (
          <button
            disabled={isLoading}
            onClick={() => {
              connectStripe();
            }}
            className="relative flex items-center gap-2 bg-[#635bff] p-2 pr-4 rounded-lg text-white font-bold disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <object className="h-8 w-8 border-r" data="/stripelogo.svg"></object>
            {formatMessage(t.connectStripe)}
          </button>
        )}
        <button onClick={() => syncUsersToAlgolia()} disabled={isSyncingUsers} className='flex gap-4 items-center'>
          <ArrowPathIcon className={`h-4 w-4 ${isSyncingUsers ? 'animate-spin' : ''}`} />
          {formatMessage(t.syncUsers)}
        </button>
      </div>

      <div ref={formRef} className="w-9/12">
        <BusinessForm defaultValues={business} onSubmit={updateBusiness} />
      </div>
    </>
  );
}

const t = defineMessages({
  business: {
    id: 'settingsPage_business',
    defaultMessage: 'Business',
  },
  setting: {
    id: 'settingsPage_setting',
    defaultMessage: 'Setting',
  },
  save: {
    id: 'settingsPage_save',
    defaultMessage: 'Save',
  },
  updateBranding: {
    id: 'settingsPage_updateBranding',
    defaultMessage: 'Update Branding',
  },
  connectStripe: {
    id: 'settingsPage_connectStripe',
    defaultMessage: 'Create or connect a Stripe account',
  },
  stripeAccountConnected: {
    id: 'settingsPage_stripeAccountConnected',
    defaultMessage: 'Stripe account connected',
  },
  stripeAccountNotConnected: {
    id: 'settingsPage_stripeAccountNotConnected',
    defaultMessage: 'Stripe account not connected',
  },
  saved: {
    id: 'settingsPage_saved',
    defaultMessage: 'Saved',
  },
  errorSaving: {
    id: 'settingsPage_errorSaving',
    defaultMessage: 'Error saving',
  },
  redirectingToStripe: {
    id: 'settingsPage_redirectingToStripe',
    defaultMessage: 'Redirecting to Stripe',
  },
  syncUsers: {
    id: 'settingsPage_syncUsers',
    defaultMessage: 'Sync users',
  },
  syncingUsers: {
    id: 'settingsPage_syncingUsers',
    defaultMessage: 'Syncing users',
  },
  usersSynced: {
    id: 'settingsPage_usersSynced',
    defaultMessage: 'Users synced',
  },
});
