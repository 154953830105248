import BasicCombobox from "frontend/src/layers/form/components/nonFormInput/BasicComboBox";
import { UserComboBox } from "frontend/src/layers/form/components/nonFormInput/UserComboBox";
import React from "react";
import toast from "react-hot-toast";
import { defineMessages, useIntl } from "react-intl";
import { statuses as orderStatuses } from "shared/src/schemas/orders";
interface Filter {
  userIds: string[],
  itemIds: string[],
  statuses: string[]
}

export default function OrderFilter({
  filters,
  setFilters
}: {
  filters: Filter,
  setFilters: (filter: Filter) => void
}) {
  const { formatMessage } = useIntl();

  return (
    <div className="flex gap-4 my-4">
      <div className="w-full">
        <UserComboBox
          onError={(error) => toast.error(error)}
          label={formatMessage(t.selectCustomer)}
          selectedUserIds={filters.userIds}
          selectUserIds={(selectUserIds) => setFilters({ ...filters, userIds: selectUserIds })}
        />
      </div>
      <div className="w-full ">
        <BasicCombobox
          onError={(error) => toast.error(error)}
          label={formatMessage(t.selectStatus)}
          options={orderStatuses.map((status) => ({
            label: status,
            key: status,
            value: status
          }))}

          value={filters.statuses.map((status) => ({
            label: status,
            key: status,
            value: status
          }))}
          onChange={(e) => {
            setFilters({ ...filters, statuses: e.map((s) => s.value) });
          }}
          onClear={() => setFilters({ ...filters, statuses: [] })}
        />
      </div>
    </div>
  );
}
const t = defineMessages({
  selectCustomer: {
    id: 'order-filters-select-customer',
    defaultMessage: 'Customer',
  },
  selectStatus: {
    id: 'order-filters-select-status',
    defaultMessage: 'Status',
  }
});