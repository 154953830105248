import { FieldValues } from 'react-hook-form';

import React from 'react';
import { BaseInput, BaseInputProps } from './BaseInput';

interface WrappedBaseInputParams<TFieldValues extends FieldValues>
  extends BaseInputProps<TFieldValues> {
  prefix: string | undefined;
  fixstyle: string;
  className: string;
  disabled: boolean | undefined;
  postfix: string | undefined;
}

export function WrappedBaseInput<TFieldValues extends FieldValues>(
  props: WrappedBaseInputParams<TFieldValues>
) {
  return (
    <div className="flex">
      {props.prefix && (
        <span className={`rounded-l-md border-r-0 ${props.fixstyle}`}>
          {props.prefix}
        </span>
      )}
      <BaseInput onWheel={props.onWheel} type={props.type} {...props} />
      {props.postfix && (
        <span className={`rounded-r-md border-l-0 ${props.fixstyle}`}>
          {props.postfix}
        </span>
      )}
    </div>
  );
}
