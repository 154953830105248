import React, { useState } from 'react';
import { WIP } from './WIP';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface Option<T extends string> {
  name: T;
  label: string;
  wip?: boolean;
}

export default function Tabs<T extends string>({
  options,
  selectedOption,
  selectOption,
  children,
}: {
  selectedOption?: Option<T>;
  selectOption?: (option: Option<T> | undefined) => void;
  options: Readonly<Option<T>[]>;
  children?: React.ReactNode;
}) {
  const [tabs, setTabs] = useState(
    options.map((option, index) => ({
      ...option,
      current: option.name === selectedOption?.name || index === 0,
    }))
  );
  return (
    <div className="my-4 mt-6">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500"
          defaultValue={tabs.find((tab) => tab.current)?.name}
          onChange={(e) => {
            selectOption &&
              selectOption(options.find((o) => o.name === e.target.value));
            setTabs(
              tabs.map((t) => ({
                ...t,
                current: t.name == e.target.value,
              }))
            );
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>
              {tab.label} {tab.wip && <WIP />}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="isolate flex divide-x divide-gray-200 rounded-t-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              onClick={(e) => {
                e.preventDefault();
                selectOption &&
                  selectOption(options.find((o) => o.name === tab.name));
                setTabs(
                  tabs.map((t) => ({
                    ...t,
                    current: t.name == tab.name,
                  }))
                );
              }}
              className={classNames(
                tab.current
                  ? 'text-gray-900'
                  : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-tl-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-tr-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>
                {tab.label} {tab.wip && <WIP />}
              </span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? 'bg-primary-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </button>
          ))}
        </nav>
      </div>
      <div className="bg-gray-50 p-4 shadow rounded-b-lg">{children}</div>
    </div>
  );
}
