import { collectionNames } from 'shared/src/collectionNames';
import { locationSchema, Location } from 'shared/src/schemas/location';
import { collection, doc, getDocs, setDoc, where } from 'firebase/firestore';
import { fetchCurrentUser } from './currentUserService';
import {
  appendBusiness,
  createBusinessQuery,
  currentBusinessId,
} from './businessService';
import { getFirestore } from '../lib/firebase';

export const fetchLocations = async ({
  ids,
  onlyAllowed = false,
}: {
  ids?: string[];
  onlyAllowed?: boolean;
}) => {
  let locationsToFilterOn: string[] = [];
  const currentUser = await fetchCurrentUser({});
  const businessId = await currentBusinessId();
  // check if user is manager and get locationIds
  if (!currentUser) throw new Error('no user');
  console.log({ currentUser });
  const needsLocationFilter =
    onlyAllowed &&
    currentUser.roles[businessId] &&
    currentUser.roles[businessId].includes('manager');

  if (needsLocationFilter) {
    locationsToFilterOn =
      currentUser.managingLocations?.[businessId]?.map((loc) => loc.id) || [];
  }

  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.addresses),
    ...(ids ? [where('id', 'in', ids)] : []),
    ...(needsLocationFilter ? [where('id', 'in', locationsToFilterOn)] : []),
    where('isArchived', '==', false)
  );

  const documentSnapShot = getDocs(q);

  return locationSchema.array().parse(
    (await documentSnapShot).docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    })
  );
};

export const fetchLocation = async (id: string) => {
  const location = await fetchLocations({ ids: [id] });
  return location[0];
};

export const saveLocation = async (location: Location) => {
  const locationDoc = doc(
    getFirestore(),
    collectionNames.addresses,
    location.id
  );

  const data = await appendBusiness(location);
  await setDoc(locationDoc, { ...data, isArchived: false });
};

export const deleteLocation = async (id: string) => {
  const locationDoc = doc(getFirestore(), collectionNames.addresses, id);
  // mark as archived
  await setDoc(locationDoc, { isArchived: true }, { merge: true });
};
