import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { AppProviders, localeAtom } from './components/AppProviders';
import { browserRouter, SupportedLocale } from './router';
import { defaultLocale } from './utils/defaults';
import { RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

export default function App() {
  const [locale, setLocale] = useAtom(localeAtom);
  useEffect(() => {
    setLocale((locale as SupportedLocale) || defaultLocale);
  }, [locale]);
  return (
    <AppProviders>
      <Toaster position="bottom-right" />
      <RouterProvider router={browserRouter} />
      <CommitHash />
    </AppProviders>
  );
}

function CommitHash() {
  // @ts-ignore
  const commithash = __COMMIT_HASH__;
  return (
    <p className="fixed bottom-0 right-0 p-4 pointer-events-none text-gray-200">
      Version - {commithash}
    </p>
  );
}
