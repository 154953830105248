import { LOCATIONS_QUERY_KEY } from '@/pages/locations/api/locationsApi';
import { useQuery } from '@tanstack/react-query';
import BasicCombobox from 'frontend/src/layers/form/components/nonFormInput/BasicComboBox';
import { fetchLocations } from 'frontend/src/services/locationsService';
import React from 'react';
import toast from 'react-hot-toast';
import { defineMessages, useIntl } from 'react-intl';
import { TRAINERS_QUERY_KEY } from '../api/availabilitiesApi';
import { fetchTrainers } from 'frontend/src/services/usersService';

export default function availabilitiesFilters({
  selectedUserIds,
  selectUserIds,
  selectedLocationIds,
  selectLocationIds,
}: {
  selectedUserIds: string[];
  selectUserIds: (userIds: string[]) => void;
  selectedLocationIds: string[];
  selectLocationIds: (locationIds: string[]) => void;
}) {
  const { formatMessage } = useIntl();
  const { data: locations } = useQuery({
    queryKey: [...LOCATIONS_QUERY_KEY],
    queryFn: () => fetchLocations({ onlyAllowed: true }),
    initialData: [],
  });

  const { data: trainers } = useQuery({
    queryKey: [...TRAINERS_QUERY_KEY],
    queryFn: () => fetchTrainers(),
    initialData: [],
  });
  return (
    <div className="flex gap-4 my-4">
      <div className="w-full">
        <BasicCombobox
          onError={(error) => toast.error(error)}
          label={formatMessage(t.selectTrainer)}
          options={trainers.map((trainer) => ({
            label: `${trainer.firstName} ${trainer.lastName}`,
            key: trainer.id,
            value: trainer.id,
          }))}
          value={selectedUserIds.map((id) => {
            const trainer = trainers.find((t) => t.id === id);
            return {
              label: `${trainer?.firstName} ${trainer?.lastName}` || 'location',
              key: id,
              value: id,
            };
          })}
          onChange={(e) => {
            selectUserIds(e.map((s) => s.value));
          }}
          onClear={() => selectUserIds([])}
        />
      </div>
      <div className="w-full ">
        <BasicCombobox
          onError={(error) => toast.error(error)}
          label={formatMessage(t.selectLocation)}
          options={locations.map((location) => ({
            label: location.name!,
            key: location.id,
            value: location.id,
          }))}
          value={selectedLocationIds.map((id) => {
            const location = locations.find((location) => location.id === id);
            return {
              label: location?.name || 'location',
              key: id,
              value: id,
            };
          })}
          onChange={(e) => {
            selectLocationIds(e.map((s) => s.value));
          }}
          onClear={() => selectLocationIds([])}
        />
      </div>
    </div>
  );
}

const t = defineMessages({
  selectTrainer: {
    id: 'availabilities-filters-select-customer',
    defaultMessage: 'Trainer',
  },
  selectLocation: {
    id: 'availabilities-filters-select-location',
    defaultMessage: 'Location',
  },
});
