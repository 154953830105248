import { PermissionTreeInput } from '../permissions';

export const anonymousPermissionTree = {
  profile: {
    read: true,
    write: true,
  },
  registration: {
    doRegistration: true,
  },
} as const satisfies PermissionTreeInput;
