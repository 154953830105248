import { PermissionTreeInput } from '../permissions';
import { managerPermissionTree } from './manager';

export const adminPermissionTree = {
  ...managerPermissionTree,
  locations: {
    read: true,
    write: true,
  },
  orders: {
    read: true,
  },
  invoices: {
    read: true,
  },
  settings: {
    read: true,
    write: true,
  },
  lessons: {
    read: true,
    write: true,
    delete: true,
    create: true,
  },
  products: {
    read: true,
    write: true,
  },
  reservations: {
    read: true,
    write: true,
    giveFeedback: true,
    updateLocation: true,
  },
} as const satisfies PermissionTreeInput;
