import React from 'react';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks-web';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

export function SearchBox(props: UseSearchBoxProps) {
  const { refine } = useSearchBox(props);

  return (
    <div>
      <div className="relative mt-1 ">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="email"
          name="email"
          id="email"
          className="block w-full border-gray-300 pl-10 focus:border-[color:var(--pool-blue)] focus:ring-[color:var(--pool-blue)] sm:text-sm"
          onChange={(e) => refine(e.target.value)}
        />
      </div>
    </div>
  );
}
