import { z } from 'zod';
import { miniReservationSchema, reservationSchema } from './reservation';

export const calendarEventSchema = z.object({
  id: z.string(),
  start: z.date(),
  end: z.date(),
  title: z.string(),
  color: z.string().optional(),
  allDay: z.boolean().optional(),
  cancelled: z.boolean().optional().default(false),
  extraBg: z
    .object({
      backgroundColor: z.string().optional(),
      opacity: z.number().optional(),
      backgroundImage: z.string().optional(),
      backgroundPosition: z.string().optional(),
      backgroundSize: z.string().optional(),
      backgroundRepeat: z.string().optional(),
    })
    .optional(),
  reservations: z.array(miniReservationSchema).optional(),
  reservationsWithAllData: z.array(reservationSchema).optional(),
});

export type CalendarEvent = z.infer<typeof calendarEventSchema>;
