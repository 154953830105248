import { useNavigate } from 'react-router-dom';
import { MapPinIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Location } from 'shared/src/schemas/location';

export default function SingleLocationView({
  location,
}: {
  location: Location;
}) {
  return (
    <button className="block hover:bg-gray-50  bg-white shadow sm:rounded-md w-full">
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className="truncate text-sm font-medium text-primary-600">
            {location.name}
          </p>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="flex items-center text-sm text-gray-500">
              <MapPinIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {location.street.trim()}, {location.city}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
}
