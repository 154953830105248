import { useIsAuthorized } from '../layers/authorization/hooks/useIsAuthorized';
import { Permission } from '../layers/authorization/permissions';
import React, { ReactNode } from 'react';
export type PageHeadingActions = {
  label: string;
  action: CallableFunction;
  requiredPermission: Permission;
}[];
export default function PageHeading({
  title,
  actions,
  superAdmin,
}: {
  title: ReactNode | string;
  actions?: PageHeadingActions;
  superAdmin?: boolean;
}) {
  const isAllowedTo = useIsAuthorized(superAdmin);
  return (
    <div className="border-b border-gray-200 pb-5 flex items-center justify-between pt-8 mb-4">
      <h1 className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </h1>
      <div className="mt-3 sm:mt-0 sm:ml-4 gap-4 flex">
        {actions
          ?.filter((a) => isAllowedTo(a.requiredPermission))
          .map((action, index) => (
            <button
              key={index}
              onClick={() => action.action()}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                index === 0
                  ? 'bg-primary-600 focus-visible:outline-primary-600 hover:bg-primary-500'
                  : 'bg-red-600 focus-visible:outline-red-600 hover:bg-red-500'
              }}`}
            >
              {action.label}
            </button>
          ))}
      </div>
    </div>
  );
}
