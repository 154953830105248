import React, { ReactNode } from 'react';
import { useHits, useSearchBox } from 'react-instantsearch-hooks-web';
import BasicCombobox from './BasicComboBox';
import { fetchUser } from '../../../../services/usersService';
import { useQuery } from '@tanstack/react-query';

export interface UserComboBoxProps {
  disabled?: boolean;
  label?: string;
  hint?: string | ReactNode;
  selectedUserIds: string[];
  selectUserIds: (userIds: string[]) => void;
  onError?: (error: string) => void;
}
type AlgoliaUser = {
  objectID: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  family: {
    objectID: string;
    firstName: string;
    lastName: string;
  }[];
};
export function UserComboBox({
  label,
  disabled,
  selectedUserIds,
  selectUserIds,
  onError,
}: UserComboBoxProps) {
  const { refine } = useSearchBox();
  const { hits } = useHits<AlgoliaUser>();

  const { data: initialSelectedUserIds } = useQuery({
    queryKey: ['users', 'filter', selectedUserIds.map((x) => x)],

    queryFn: () => {
      return Promise.all(
        selectedUserIds.map(async (id) => {
          const user = await fetchUser(id);
          return {
            label: `${user.firstName} ${user.lastName}`,
            key: user.id,
            value: user.id,
          };
        })
      );
    },

    initialData: [],
  });

  const formatAlgoliaHits = () => {
    if (!hits) {
      return [];
    }
    const formattedHits = hits.map((x) => ({
      label: `${x.firstName} ${x.lastName}`,
      key: x.objectID,
      value: x.objectID,
    }));

    // find unique values in [...initialSelectedUserIds, ...formattedHits]
    const uniqueIds = new Set([
      ...initialSelectedUserIds.map((x) => x.value),
      ...formattedHits.map((x) => x.value),
    ]);

    return Array.from(uniqueIds).map((id) => {
      const selected = initialSelectedUserIds.find((x) => x.value === id);
      const hit = formattedHits.find((x) => x.value === id);
      return (
        selected ||
        hit || { label: 'unknown', key: 'unknown', value: 'unknown' }
      );
    });
  };

  return (
    <BasicCombobox
      onError={onError}
      options={formatAlgoliaHits()}
      disabled={disabled}
      searchFn={(query) => {
        refine(query);
      }}
      value={initialSelectedUserIds}
      onChange={(e) => {
        selectUserIds(e.map((x) => x.value));
      }}
      label={label}
      onClear={() => {
        selectUserIds([]);
      }}
    />
  );
}
