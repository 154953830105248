import { ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  businessIdContext,
  currentBusinessId,
} from 'frontend/src/services/businessService';
import { BUSINESS_QUERY_KEY } from '@/pages/settings/api/settingsApi';

export function BusinessProvider({ children }: { children: ReactNode }) {
  const { data: businessId, isLoading } = useQuery({
    queryKey: [...BUSINESS_QUERY_KEY, 'id'],
    queryFn: () => currentBusinessId(),
  });
  if (isLoading) {
    return null;
  }

  if (!businessId) {
    return (
      <>
        <div>Business ID not found</div>
      </>
    );
  }

  return (
    <>
      <businessIdContext.Provider value={businessId}>
        {children}
      </businessIdContext.Provider>
    </>
  );
}
