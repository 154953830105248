import React from 'react';
import { BaseInput } from './BaseInput';
import { FieldValues, Path } from 'react-hook-form';

export interface FileInputProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  id?: string;
  className?: string;
}

export default function FileInput<TFieldValues extends FieldValues>({
  name,
  id,
  className,
}: FileInputProps<TFieldValues>) {
  return <BaseInput id={id} name={name} type="file" className={className} />;
}
