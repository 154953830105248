import { FieldValues, useFormContext } from 'react-hook-form';
import FileInput, { FileInputProps } from '../input/FileInput';
import { FormField, FormFieldProps } from '../FormField';
import { DocumentArrowUpIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { defineMessages, useIntl } from 'react-intl';
import { downloadFile, uploadFile } from '../../../../lib/storage';
import { useEffect, useState } from 'react';

export interface FileFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
    FileInputProps<TFieldValues> {
  filePath: string;
  cacheIt?: boolean;
}

export function FileField<TFieldValues extends FieldValues>({
  name,
  label,
  hint,
  filePath,
  cacheIt,
}: FileFieldProps<TFieldValues>) {
  const { formatMessage } = useIntl();
  const { setValue, getValues } = useFormContext();
  const [imageUrl, setImageUrl] = useState<string | undefined>(getValues(name));
  const id = `file-upload-${name}`;

  const setFileUrl = async () => {
    if (!getValues(name)) return;
    setImageUrl(await downloadFile(getValues(name)));
  };

  const updateFile = async (file: File | null) => {
    if (!file) {
      setValue(name, '' as never);
      setImageUrl(undefined);
      return;
    }
    const fullPath = await uploadFile({
      path: filePath,
      file,
      cacheIt,
    });
    console.log('fullPath', fullPath);
    setValue(name, fullPath.path as never);
    setFileUrl();
  };

  useEffect(() => {
    setFileUrl();
  }, [getValues(name)]);

  return (
    <FormField label={label} name={name}>
      <div className="h-full mt-2 relative flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
        <div className="space-y-1 text-center">
          {!imageUrl && (
            <>
              <DocumentArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-600 items-center">
                <span className="relative cursor-pointer rounded-md p-2 bg-white font-medium text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:text-primary-500">
                  {formatMessage(t.uploadFile)}
                </span>
                <input
                  type="file"
                  id={id}
                  className="sr-only"
                  onChange={(e) => {
                    e.preventDefault();
                    if (!e.target.files) return;
                    updateFile(e.target.files[0]);
                  }}
                />
                <FileInput name={name} className="sr-only" />
                <p className="pl-1">{formatMessage(t.dragAndDropFile)}</p>
              </div>
              {hint && <p className="text-xs">{hint}</p>}
            </>
          )}
          {imageUrl && (
            <>
              <button
                className="absolute top-4 right-4"
                onClick={(e) => {
                  e.preventDefault();
                  updateFile(null);
                }}
              >
                <XCircleIcon className="h-8 w-8 text-primary-500 hover:text-primary-800" />
              </button>
              <img
                src={imageUrl}
                className="mx-auto h-36 w-auto text-gray-400"
              />
            </>
          )}
        </div>
      </div>
    </FormField>
  );
}
const t = defineMessages({
  uploadFile: {
    defaultMessage: 'Upload a file',
    description: 'message shown when file can be uploaded',
    id: `filenputfield-fileInputuploadFile`,
  },
  dragAndDropFile: {
    defaultMessage: 'or drag and drop',
    description: 'message shown when file can be dragged and dropped',
    id: `filenputfield-fileInputdragAndDropFile`,
  },
});
