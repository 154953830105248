import React from "react";
import BaseWidget from "./base";
import { defineMessages, useIntl } from "react-intl";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import { unlinkedReservations } from "frontend/src/services/reservationService";
import { DateTime } from "luxon";
import { nFormatter } from "shared/src/utils/numberFormatter";


export default function unlinkedReservationsWidget() {
  const { formatMessage } = useIntl();
  const { data: theNumberOfReservations, isLoading } = useQuery({
    queryKey: ["reservations", "unlinked", "count"],
    queryFn: () => {
      const beginOfMonth = DateTime.now().startOf('month').toJSDate();
      const endOfMonth = DateTime.now().endOf('month').toJSDate();
      return unlinkedReservations({
        from: beginOfMonth,
        to: endOfMonth
      })
    },
    initialData: 0
  });
  return (
    <BaseWidget
      icon={(<CalendarIcon />)}
      isLoading={isLoading}
      number={theNumberOfReservations || "0"}
      title={formatMessage(t.unlinkedReservationsThisMonth)}
      formatter={nFormatter}
      extraText={formatMessage(t.thisMonth)}
    />
  );
}
const t = defineMessages({
  unlinkedReservationsThisMonth: {
    id: "reservations_widget_unlinked_reservations_this_month",
    defaultMessage: "Unlinked Reservations"
  },
  thisMonth: {
    id: "reservations_widget_this_month",
    defaultMessage: "This month"
  }
})