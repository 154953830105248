import { TagIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Product } from 'shared/src/schemas/product';

export default function SingleProductView({
  product,
  onClick,
}: {
  product: Product;
  onClick: (product: Product) => void;
}) {
  return (
    <button
      onClick={() => onClick(product)}
      className="block hover:bg-gray-50  bg-white shadow sm:rounded-md w-full"
    >
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className="truncate text-sm font-medium text-primary-600">
            {product.name}
            <span className="ml-2 font-extralight">{product.price} (incl)</span>
          </p>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <TagIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {product.category.name}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
}
