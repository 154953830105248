import { z } from 'zod';
import { locationSchema } from './location';
import { categorySchema } from './category';
import { firebaseDate } from './date';

export const personSchema = z.object(
  {
    id: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    birthDate: z.optional(firebaseDate),
    notes: z.string().optional().default(''),
    deleted: z.boolean().default(false),
  },
  {
    invalid_type_error: 'Invalid person data',
  }
);
export type Person = z.infer<typeof personSchema>;

export const trainerSchema = z.object({
  userId: z.string(),
  categories: z.array(z.string()),
});
export type Trainer = z.infer<typeof trainerSchema>;

export const roles = [
  'anonymous',
  'user',
  'trainer',
  'manager',
  'admin',
  'superAdmin',
] as const;
export const roleSchema = z.enum(roles);

export type Role = z.infer<typeof roleSchema>;

export const userSchema = z.object(
  {
    id: z.string(),
    firstName: z.string().default(''),
    lastName: z.string().default(''),
    email: z.string().email(),
    phone: z.string().optional().default(''),
    address: locationSchema.optional(),
    family: personSchema.array().optional(),
    roles: z.record(z.string(), z.array(roleSchema)).default({}),
    trainerCategories: z.record(z.string(), z.array(categorySchema), { invalid_type_error: 'Category schema does not complaint' }).default({}).optional(),
    managingLocations: z.record(z.string(), z.array(locationSchema, { invalid_type_error: 'Location schema does not complaint' })).default({}).optional(),
    stripeCustomerId: z.string().optional(),
  },
  {
    invalid_type_error: 'Invalid user data',
  }
);

export type User = z.infer<typeof userSchema>;
