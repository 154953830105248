import { Form } from 'frontend/src/layers/form/components/Form';
import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { ShortTextField } from 'frontend/src/layers/form/components/fields/ShortTextField';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Location, locationSchema } from 'shared/src/schemas/location';
import { v4 as uuid } from 'uuid';
import { ButtonPortal } from 'frontend/src/layers/slide-over/ButtonPortal';
import { TrashIcon } from '@heroicons/react/20/solid';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { ColorField } from 'frontend/src/layers/form/components/fields/ColorField';
import { generalTranslations } from '@/generalTranslations';
import toast from 'react-hot-toast';

export interface LocationFormProps {
  defaultValues?: Location;
  onSubmit: (data: Location) => void;
  onDelete?: (id: string) => void;
}

export default function LocationForm({
  onSubmit,
  defaultValues,
  onDelete,
}: LocationFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();
  const formReturn = useForm<Location>({
    resolver: zodResolver(locationSchema),
    defaultValues: {
      id: uuid(),
      ...defaultValues,
    },
  });
  return (
    <>
      <Form
        onErrors={(errors) => {
          console.log(errors)
          toast.error(formatMessage(generalTranslations.somethingWentWrong))
        }}
        OnSubmit={onSubmit}
        formReturn={formReturn}
        readOnly={!isAllowedTo('locations.write')}
      >
        <HiddenField name="id" />
        <ShortTextField name="name" label={formatMessage(t.name)} />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-3">
            <ShortTextField name="street" label={formatMessage(t.street)} />
          </div>
          <ShortTextField name="number" label={formatMessage(t.streetNumber)} />
          <ShortTextField name="mailBox" label={formatMessage(t.mailBox)} />
        </div>
        <div className="grid grid-cols-4 gap-4">
          <ShortTextField name="zipCode" label={formatMessage(t.zipCode)} />
          <ShortTextField name="city" label={formatMessage(t.city)} />
          <ShortTextField name="country" label={formatMessage(t.country)} />
          <ColorField name="color" label={formatMessage(t.color)} />
        </div>
      </Form>
      {(defaultValues as Location)?.id && onDelete && (
        <ButtonPortal>
          <button
            className="ml-4 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
            onClick={() => {
              if (!window.confirm('Do you really want to delete?')) {
                return;
              }
              const id = (defaultValues as Location).id!;
              onDelete(id);
            }}
          >
            <TrashIcon className="h-5 w-5" />
          </button>
        </ButtonPortal>
      )}
    </>
  );
}

const t = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: '',
    id: 'LocationForm_name',
  },
  street: {
    defaultMessage: 'Street',
    description: '',
    id: 'LocationForm_street',
  },
  streetNumber: {
    defaultMessage: 'Number',
    description: '',
    id: 'LocationForm_streetNumber',
  },
  mailBox: {
    defaultMessage: 'Mailbox',
    description: '',
    id: 'LocationForm_mailBox',
  },
  zipCode: {
    defaultMessage: 'Zip code',
    description: '',
    id: 'LocationForm_zipCode',
  },
  city: {
    defaultMessage: 'City',
    description: '',
    id: 'LocationForm_city',
  },
  country: {
    defaultMessage: 'Country',
    description: '',
    id: 'LocationForm_country',
  },
  color: {
    defaultMessage: 'color',
    description: '',
    id: 'LocationForm_color',
  },
});
