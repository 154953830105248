import { z } from 'zod';

export const firebaseDate = z.any().transform((val) => {
  if (!val) return;
  if (val && val instanceof Date) {
    return val;
  }
  if (val && val.seconds && val.nanoseconds) {
    return new Date(val.seconds * 1000 + val.nanoseconds / 1000000);
  }
  if (val && val.toDate && typeof val.toDate === 'function') {
    return val.toDate();
  }
  if (val && typeof val === 'string' && !isNaN(Date.parse(val))) {
    return new Date(val);
  }
  try {
    return z
      .date({
        coerce: true,
      })
      .parse(val);
  } catch (error) {
    throw new Error('Invalid date', val);
  }
});
