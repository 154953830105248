import { generalTranslations } from "@/generalTranslations";
import React from "react";
import { useIntl } from "react-intl";
import { Graph } from "./graph";
import { BUSINESS_BRANDING_QUERY_KEY } from "frontend/src/api/keys";
import { getBusinessBranding } from "frontend/src/services/businessService";
import { useQuery } from "@tanstack/react-query";
import { Branding } from "shared/src/schemas/branding";

export default function BaseWidget({
  icon,
  number,
  title,
  isLoading,
  extraText,
  formatter = (value: string | number) => value.toString()
}: {
  icon: JSX.Element,
  number: string | number
  title: string,
  isLoading: boolean,
  extraText?: string,
  formatter?(value: string | number): string
}) {
  return (
    <Widget>
      <div className="flex gap-2 flex-col w-full">
        <div className="font-bold text-gray-500 uppercase">{title}</div>
        <div className="text-4xl font-bold">
          {isLoading ? "..." : formatter(number)}
        </div>
        <div className="text-xs font-bold text-gray-500">{extraText || ' '}</div>
      </div>
      <div className="grid place-items-center">
        <div className="bg-secondary-600 text-white rounded-full overflow-hidden p-4 h-16 w-16">
          {icon}
        </div>
      </div>
    </Widget>
  );
}

interface listItem {
  display: React.ReactNode
  id: string
}
export function BaseList({
  items,
  title,
  isLoading
}: {
  items: listItem[],
  title: string,
  isLoading?: boolean
}) {
  const { formatMessage } = useIntl();
  return (
    <Widget>
      <div className="flex flex-col w-full">
        <div className="font-bold text-gray-500 uppercase">
          {title}
        </div>
        <div className="grid h-full w-full relative">
          {isLoading ? (
            <div className="absolute h-full w-full bg-white bg-opacity-50 flex justify-center items-center">
              {formatMessage(generalTranslations.loading)}...
            </div>
          ) : (
            <div className="h-full w-full absolute overflow-y-auto pr-4 flex flex-col gap-2 divide-y">
              {items.map(item => (
                <div key={item.id} className="flex justify-between items-center py-2 max-w-full">
                  <div className="overflow-x-hidden max-w-full">{item.display}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Widget>
  )
}


export function BaseGraph({
  categories,
  values,
  title,
  isLoading,
  type = 'number'
}: {
  categories: string[],
  values: number[],
  title: string,
  isLoading: boolean,
  type?: 'valuta' | 'number'
}) {
  const { formatMessage } = useIntl();
  const { data: branding, isLoading: loadingBranding } = useQuery({
    queryKey: [...BUSINESS_BRANDING_QUERY_KEY],
    queryFn: () => {
      return getBusinessBranding()
    }
  })
  return (
    <Widget>
      <div className="flex flex-col  w-full">
        <div className="font-bold text-gray-500 uppercase">
          {title}
        </div>
        {(loadingBranding || isLoading || !values || !values.length) ? (
          <div className="grid w-full h-full place-content-center">
            {formatMessage(generalTranslations.loading)}...
          </div>
        ) : (
          <>
            <Graph
              categories={categories}
              dataArray={values}
              dataArrayName={title}
              branding={branding as Branding}
              type={type}
            />
          </>
        )}
      </div >
    </Widget>
  );
}

export function Widget({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div className="flex bg-white shadow-md rounded-lg p-4 justify-between h-full w-full">
      {children}
    </div>
  );
}