import React from "react";
import BaseWidget from "./base";
import { defineMessages, useIntl } from "react-intl";
import { UserIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import { usersCount } from "frontend/src/services/usersService";
import { nFormatter } from "shared/src/utils/numberFormatter";


export default function UsersWidget() {
  const { formatMessage } = useIntl();
  const { data: theNumberOfUsers, isLoading } = useQuery({
    queryKey: ["users", "count"],
    queryFn: () => {
      return usersCount()
    },
    initialData: 0
  });
  return (
    <BaseWidget
      icon={(<UserIcon />)}
      isLoading={isLoading}
      number={theNumberOfUsers || "0"}
      title={formatMessage(t.users)}
      formatter={nFormatter}
      extraText={formatMessage(t.inTotal)}
    />
  );
}
const t = defineMessages({
  users: {
    id: "users_widget_users",
    defaultMessage: "users"
  },
  inTotal: {
    id: "users_widget_in_total",
    defaultMessage: "in total"
  }
})