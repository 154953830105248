import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Branding } from 'shared/src/schemas/branding';
import { nFormatter } from 'shared/src/utils/numberFormatter';


const getOptions = (branding: Branding, categories: string[], maxY: number, type: 'number' | 'valuta') => {
  const options: ApexOptions = {
    colors: [branding.secondaryColor],
    chart: {
      height: 335,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [2, 2],
      curve: 'straight',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      colors: '#fff',
      strokeColors: [branding.primaryColor],
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      hover: {
        size: undefined,
        sizeOffset: 5,
      },
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: '8px',
        },
      },
      min: 0,
      max: maxY,
      labels: {
        formatter: (value) => {
          return `${type === 'valuta' ? '€' : ''} ${nFormatter(value, 0, false)}`;
        },
      },
    },
  };
  return options;
}

interface ChartOneState {
  series: {
    name: string;
    data: number[];
  }[];
}

interface ChartOneProps {
  dataArray: number[];
  categories: string[];
  dataArrayName: string;
  branding: Branding,
  type?: 'valuta' | 'number';
}

export const Graph: React.FC<ChartOneProps> = ({ dataArray, dataArrayName, categories, branding, type = 'number' }): JSX.Element => {
  const [state] = useState<ChartOneState>({
    series: [
      {
        name: dataArrayName,
        data: dataArray,
      },
    ],
  });
  const maxY = dataArray && dataArray.length ? Math.max(...dataArray) : 100;
  return (
    <ReactApexChart
      className="w-full h-full"
      options={getOptions((branding), categories, maxY, type)} // Call the getOptions function instead of passing it as a function reference
      series={state.series}
      type="area"
      height={'100%'}
    />
  );
};
