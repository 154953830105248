import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { WrappedBaseInput } from './WrappedBaseInput';

export interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  prefix?: string;
  postfix?: string;
  disabled?: boolean;
}

export function PhoneInput<TFieldValues extends FieldValues>({
  name,
  prefix,
  postfix,
  disabled,
}: Props<TFieldValues>) {
  const inputClass = `disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block w-full min-w-0 flex-1 border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 
    ${prefix ? 'rounded-l-none' : 'rounded-l-md'} 
    ${postfix ? 'rounded-r-none' : 'rounded-r-md'} `;

  const fixstyle =
    'inline-flex items-center border border-gray-300 px-3 text-gray-500 sm:text-sm bg-gray-100';
  return (
    <WrappedBaseInput
      prefix={prefix}
      fixstyle={fixstyle}
      name={name}
      onWheel={() => (document.activeElement as HTMLElement).blur()}
      className={inputClass}
      disabled={disabled}
      postfix={postfix}
      type={'tel'}
    />
  );
}
