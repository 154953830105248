import { Combobox } from '@headlessui/react';
import {
  ChevronUpDownIcon,
  CheckIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import React, { useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export interface Option {
  value: string;
  key: string;
  label: string;
}

export default function BasicCombobox({
  value,
  onChange,
  disabled,
  label,
  options,
  optionRender,
  searchFn,
  onClear,
  maxSelections = 5,
  onError,
}: {
  value: Option[];
  onChange: (value: Option[]) => void;
  disabled?: boolean;
  label?: string;
  options: Option[];
  optionRender?: (option: { label: string; value: string }) => React.ReactNode;
  searchFn?: (query: string) => void;
  onClear?: () => void;
  maxSelections?: number;
  onError?: (error: string) => void;
}) {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { formatMessage } = useIntl();
  const [query, setQuery] = useState<string | null>(null);
  const [openOptions, setOpenOptions] = useState(false);
  function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(' ');
  }
  const optionIsSelected = (option: Option) => {
    return value.some((v) => v.value === option.value);
  };

  document.addEventListener('mousedown', (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
      setOpenOptions(false)
    }
  })

  const filteredOptions =
    query === null || searchFn
      ? options
      : options.filter((option: Option) => {
        return option.label.toLowerCase().includes(query.toLowerCase());
      });

  const inputValue =
    query !== null ? query : value.map((x) => x.label).join(', ') || formatMessage(t.select);

  const orderOptions = (a: Option, b: Option) => {
    if (optionIsSelected(a)) {
      return -1;
    }
    if (optionIsSelected(b)) {
      return 1;
    }
    return a.label.localeCompare(b.label);
  };

  return (
    <Combobox multiple disabled={disabled} value={value}>
      <Combobox.Label className="text-sm font-medium leading-6 text-gray-900 flex justify-between">
        {label || formatMessage(t.select)}
        {onClear && value.length ? (
          <XCircleIcon
            onClick={onClear}
            className="h-5 w-5 text-gray-400 cursor-pointer hover:text-primary-500"
            aria-hidden="true"
          />
        ) : null}
      </Combobox.Label>
      <div className="relative mt-2" ref={menuRef}>
        <Combobox.Input
          value={inputValue}
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
          onChange={(event) => {
            setQuery(event.target.value);
            if (searchFn) {
              searchFn(event.target.value);
            }
          }}
          onClick={(e) => {
            e.currentTarget.select()
            setOpenOptions(true)
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        {filteredOptions.length > 0 && (
          <Combobox.Options static={openOptions} className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions
              .sort(orderOptions)
              .map((option, index) => {
                return (
                  <Combobox.Option
                    key={index}
                    value={option}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      console.log({ option, value, options });
                      // Check if option is already selected
                      if (optionIsSelected(option)) {
                        console.log('Removing');
                        // Remove option from value
                        onChange(value.filter((v) => v.value !== option.value));
                      } else {
                        if (value.length >= maxSelections && onError) {
                          onError(formatMessage(t.maxSelectionsReached));
                          return;
                        }
                        onChange([...value, option]);
                      }
                      setQuery(null);
                      searchFn && searchFn('');
                    }}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-secondary-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ active }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            optionIsSelected(option) && 'font-semibold'
                          )}
                        >
                          {optionRender && optionRender(option)}
                          {!optionRender && option.label}
                        </span>

                        {optionIsSelected(option) && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-secondary-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                );
              })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

const t = defineMessages({
  select: {
    id: 'BasicComboBox-select',
    defaultMessage: 'Make a selection',
  },
  maxSelectionsReached: {
    id: 'BasicComboBox-max-selections-reached',
    defaultMessage: 'Max selections reached',
  },
});
