import { logEvent } from 'firebase/analytics';
import { getAnalytics } from './firebase';

function logAnEvent(event: string, params?: Record<string, unknown>) {
  const analytics = getAnalytics();
  return logEvent(analytics, event, params);
}
export function logPageView(businessId: string) {
  return logAnEvent('page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: document.title,
    client_id: businessId,
  });
}
export function logItemViewed(businessId: string, itemId: string) {
  return logAnEvent('VIEW_ITEM', {
    item_id: itemId,
    client_id: businessId,
  });
}

export function logItemAdded(businessId: string, params: {
  value: number;
  items: {
    id: string;
    name: string;
    quantity: number;
  }[];
}) {
  return logAnEvent('ADD_TO_CART', {
    client_id: businessId,
    params
  });
}

export function logBeginCheckout(businessId: string, params: {
  value: number;
  items: {
    id: string;
    name: string;
    quantity: number;
  }[];
}) {
  return logAnEvent('BEGIN_CHECKOUT', {
    client_id: businessId,
    params
  });
}