import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { OrderStatus } from 'shared/src/schemas/orders';

export default function StatusBadge({ status }: { status: OrderStatus }) {
  const { formatMessage } = useIntl();

  let color = 'black';
  switch (status) {
    case 'created':
    case 'pending':
      color = 'blue';
      break;
    case 'unpaid':
    case 'expired':
    case 'cancelled':
      color = 'red';
      break;
    case 'paid':
      color = 'green';
      break;
    case 'free':
    case 'paid-with-credits':
      color = 'yellow';
      break;
    case 'error':
      color = 'orange';
      break;
    default:
      break;
  }
  return (
    <span
      className={`inline-flex items-center gap-x-1.5 rounded-md bg-${color}-100 px-1.5 py-0.5 text-xs font-medium text-${color}-700`}
    >
      <svg
        className={`h-1.5 w-1.5 fill-${color}-500`}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      {formatMessage(t[status])}
      {status === 'pending' && '...'}
    </span>
  );
}

const t = defineMessages({
  created: {
    id: 'orderstatusbadge-created',
    defaultMessage: 'Created',
  },
  pending: {
    id: 'orderstatusbadge-pending',
    defaultMessage: 'Pending',
  },
  unpaid: {
    id: 'orderstatusbadge-unpaid',
    defaultMessage: 'Unpaid',
  },
  paid: {
    id: 'orderstatusbadge-paid',
    defaultMessage: 'Paid',
  },
  cancelled: {
    id: 'orderstatusbadge-cancelled',
    defaultMessage: 'Cancelled',
  },
  expired: {
    id: 'orderstatusbadge-expired',
    defaultMessage: 'Expired',
  },
  free: {
    id: 'orderstatusbadge-free',
    defaultMessage: 'Free',
  },
  error: {
    id: 'orderstatusbadge-error',
    defaultMessage: 'Error',
  },
  'paid-with-credits': {
    id: 'orderstatusbadge-paid-with-credits',
    defaultMessage: 'Paid with Credits',
  },
});
