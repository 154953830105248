import { FieldValues, Path } from 'react-hook-form';
import React, { ReactNode } from 'react';
import { User } from 'shared/src/schemas/users';
import { FormField } from '../FormField';
import { GenericComboBox } from '../input/GenericComboBox';
import { useHits, useSearchBox } from 'react-instantsearch-hooks-web';
export interface UserComboBoxProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  users: User[];
  disabled?: boolean;
  label?: string | ReactNode;
  hint?: string | ReactNode;
}
type AlgoliaUser = {
  objectID: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  family: {
    objectID: string;
    firstName: string;
    lastName: string;
  }[];
};
export function UserComboBox<TFieldValues extends FieldValues>({
  name,
  hint,
  label,
  disabled,
  users,
}: UserComboBoxProps<TFieldValues>) {
  const [query, setQuery] = React.useState('');
  const { refine } = useSearchBox();
  const { hits } = useHits<AlgoliaUser>();
  // Merge hits array with users array
  const options = (
    [
      ...users.map((x) => {
        return {
          ...x,
          objectID: x.id,
        };
      }),
      ...hits.filter((x) => !users.map((u) => u.id).includes(x.objectID)),
    ].map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      key: user.objectID,
      value: user.objectID,
    })) || []
  ).filter((x) => x.label.toLowerCase().includes(query.toLowerCase()));

  return (
    <FormField hint={hint} name={name}>
      <GenericComboBox
        name={name}
        options={options}
        label={label ?? 'Select user'}
        disabled={disabled}
        searchFn={(query) => {
          refine(query);
          setQuery(query);
        }}
      />
    </FormField>
  );
}
