import { z } from 'zod';
import { locationSchema } from './location';
import { userSchema } from './users';
import { firebaseDate } from './date';

export const timeSlotStatuses = ['booked', 'reserved', 'available'] as const;

export const timeEntrySchema = z.object({
  start: firebaseDate,
  end: firebaseDate,
});

export const timeSlotSchema = timeEntrySchema.extend({
  id: z.string(),
  orderId: z.string().optional().default(''),
  status: z.enum(timeSlotStatuses).optional().default('available'),
  location: locationSchema,
  trainer: userSchema.optional(),
  ids: z.array(z.string()).optional(),
});

export type TimeEntry = z.infer<typeof timeEntrySchema>;
export type TimeSlot = z.infer<typeof timeSlotSchema>;
