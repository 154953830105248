import React from 'react';

export function LoadingBar() {
  return (
    <div
      className="fixed top-0 left-0 z-50  h-1 w-full animate overflow-hidden"
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 rounded-full ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
      <div className="absolute top-0 w-14 h-full bg-primary-600 animate-[loading_2s_ease-in-out_infinite] rounded-full "></div>
    </div>
  );
}
