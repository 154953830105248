import {
  getDownloadURL,
  ref,
  updateMetadata,
  uploadBytes,
} from 'firebase/storage';
import { getStorage } from './firebase';
import { currentBusinessId } from '../services/businessService';

// Upload image to firebase and return object with path
export async function uploadFile({
  path,
  file,
  newName,
  cacheIt,
}: {
  path: string;
  file: File;
  newName?: string;
  cacheIt?: boolean;
}) {
  const fileName = newName
    ? `${newName}${file.name.slice(
        file.name.lastIndexOf('.'),
        file.name.length
      )}`
    : file.name;
  const storage = getStorage();
  const filePath = ref(
    storage,
    `${await currentBusinessId()}/${path}/${fileName}`
  );
  try {
    await uploadBytes(filePath, file);
    if (cacheIt) {
      const newMeta = {
        cacheControl: `public,max-age=${60 * 60 * 24 * 30}`,
        metadata: {
          cacheControlChanged: new Date().toISOString(),
        },
      };
      await updateMetadata(filePath, newMeta);
    }
    const url = await getDownloadURL(filePath);
    return { url: url, path: filePath.fullPath };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function downloadFile(path: string) {
  const storage = getStorage();
  console.log(`Fetching file from ${path}`);

  return await getDownloadURL(ref(storage, path));
}
