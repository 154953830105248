import { useBusinessId } from 'frontend/src/services/businessService';
import { algoliaConfig } from '../../../../config';
import algoliasearch from 'algoliasearch/lite';
import { ReactNode } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks-web';

const searchClient = algoliasearch(
  algoliaConfig.appId,
  algoliaConfig.searchKey
);

export function AlgoliaProvider({ children }: { children: ReactNode }) {
  // todo: when no users are found, maybe we should sync the users?
  const businessId = algoliaConfig.businessId || useBusinessId();

  if (!businessId) {
    return <>{children}</>;
  }

  console.log('businessId', businessId);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${businessId}-users`}
    >
      {children}
    </InstantSearch>
  );
}
