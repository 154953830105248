import { FieldValues, Path } from 'react-hook-form';
import { ReactNode } from 'react';
import { GenericSelectInput, Option } from '../input/GenericSelectInput';
import { FormField } from '../FormField';

export interface Props<T, TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  options: Option<T>[];
  label?: string | ReactNode;
  hint?: string | ReactNode;
  disabled?: boolean;
  multiple?: boolean;
}

export function SelectField<T, TFieldValues extends FieldValues>({
  name,
  options,
  label,
  hint,
  disabled,
  multiple
}: Props<T, TFieldValues>) {
  return (
    <FormField hint={hint} name={name}>
      <GenericSelectInput
        multiple={multiple}
        name={name}
        options={options}
        label={label ?? 'Select'}
        disabled={disabled}
      />
    </FormField>
  );
}
