import { PermissionTreeInput } from '../permissions';
import { anonymousPermissionTree } from './anonymous';

export const userPermissionTree = {
  ...anonymousPermissionTree,
  registration: {
    doRegistration: false,
  },
  lessons: {
    shop: true,
  },
  products: {
    shop: true,
  },
  myReservations: {
    read: true,
    write: true,
  },
  myOrders: {
    read: true,
  },
} as const satisfies PermissionTreeInput;
