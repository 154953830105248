import toast from 'react-hot-toast';
import { defineMessages, useIntl } from 'react-intl';
import { useCancelFlow } from '@/lib/flow';
import { useMutation } from '@tanstack/react-query';
import { deleteAvailability, saveSlot } from '@/services/timeSlotService';
import { TimeEntry, TimeSlot } from 'shared/src/schemas/timeSlot';
import { AvailabilityForm } from '@/form/AvailabilityForm';
import { queryClient } from '@/lib/queryClient';
import { AVAILABILITIES_QUERY_KEY } from '../api/availabilitiesApi';

export default function AvailabilityDialog({
  availability,
}: {
  availability?: TimeEntry | TimeSlot;
}) {
  const { formatMessage } = useIntl();
  const closeFlow = useCancelFlow();

  const { mutate: saveAvailability } = useMutation({
    mutationFn: saveSlot,
    onSuccess: () => {
      toast.success(formatMessage(isEditing ? t.saved : t.created));
      queryClient.invalidateQueries({ queryKey: AVAILABILITIES_QUERY_KEY });
      closeFlow();
    },
    onError: () => {
      toast.error(formatMessage(t.errorSaving));
    },
  });

  const cancelFlow = useCancelFlow();

  const { mutate: onDelete } = useMutation({
    mutationFn: deleteAvailability,
    onSuccess: () => {
      toast.success(formatMessage(t.deleted));
      queryClient.invalidateQueries({ queryKey: AVAILABILITIES_QUERY_KEY });
      cancelFlow();
    },
  });

  const isEditing = !!(availability && (availability as TimeSlot)?.id);

  return (
    <>
      <AvailabilityForm
        readOnly={isEditing}
        onSubmit={saveAvailability}
        defaultValues={availability}
        onDelete={onDelete}
      />
    </>
  );
}

const t = defineMessages({
  saved: {
    defaultMessage: 'Availability updated',
    id: 'admin-availabilities-detail-AvailabilityDialog-saved',
  },
  created: {
    defaultMessage: 'Availability created',
    id: 'admin-availabilities-detail-AvailabilityDialog-created',
  },
  errorSaving: {
    defaultMessage: 'Error saving availability',
    id: 'admin-availabilities-detail-AvailabilityDialog-errorSaving',
  },
  deleted: {
    defaultMessage: 'Availability deleted',
    id: 'admin-availabilities-detail-AvailabilityDialog-deleted',
  },
});
