import { stringToPattern } from '../../../utils/cssPatterns';
import { stringToColor } from '../../../utils/color';
import { calendarEventSchema } from 'shared/src/schemas/calendarEvent';
import {
  Reservation,
  miniReservationSchema,
} from 'shared/src/schemas/reservation';
import { fetchReservation } from 'frontend/src/services/reservationService';

export const mapCalendarEvents = (
  reservations: fetchReservation[],
  showAllTheData?: boolean
) => {
  if (!reservations) {
    return [];
  }
  // Group reservations by cycle
  const groupedReservations = reservations.reduce(
    (acc, reservation) => {
      const cycleId = reservation.cycle
        ? `cycle-${reservation.cycle}-${reservation.start.toISOString()}-${reservation.location.id}`
        : reservation.id;
      if (!acc[cycleId]) {
        acc[cycleId] = [];
      }
      acc[cycleId].push(reservation as Reservation);
      return acc;
    },
    {} as Record<string, Reservation[]>
  );

  const calendarEventsWithReservations = Object.keys(groupedReservations).map(
    (cycleId) => {
      const reservations = groupedReservations[cycleId];
      const firstReservation = reservations[0];
      return calendarEventSchema.parse({
        id: cycleId,
        title: `${firstReservation.lesson.name} @ ${firstReservation.location.name
          } ${firstReservation?.cycle
            ? `(${groupedReservations[cycleId].map((x) => x.person?.firstName || x.user.firstName).join(', ')})`
            : '(' +
            (firstReservation.person?.firstName ||
              firstReservation.user.firstName) +
            ')'
          } ${firstReservation.trainers.map(t => t.firstName).join((', '))}`,
        color: firstReservation.trainers.length ? stringToColor(firstReservation.location.name || '') : undefined,
        border: stringToColor(firstReservation.location.name || ''),
        start: firstReservation.start,
        end: firstReservation.end,
        cancelled: reservations.every((reservation) => reservation.cancelled),
        extraBg: firstReservation.trainers
          ? stringToPattern(firstReservation.trainers.map((x) => x.id).join(''))
          : undefined,
        reservations: reservations.map((r) => {
          const minis = miniReservationSchema.parse({
            id: r.id,
            user: r.user,
            personJoining: r.person,
            cancelled: r.cancelled,
            personalFeedback: r.personalFeedback,
          });
          return minis;
        }),
        reservationsWithAllData: showAllTheData ? reservations : undefined,
      });
    }
  );
  return calendarEventsWithReservations;
};
