import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { collectionNames } from 'shared/src/collectionNames';
import { getCurrentUser } from './authService';
import { appendBusiness, currentBusinessId } from './businessService';
import {
  Person,
  User,
  personSchema,
} from 'shared/src/schemas/users';
import { Location, locationSchema } from 'shared/src/schemas/location';
import { getFirestore } from '../../../../packages/frontend/src/lib/firebase';
import { fetchUserSchema } from './usersService';

const businessId = currentBusinessId();

export async function fetchCurrentUser({
  inclAddress = false,
  inclFamily = false,
}: {
  inclAddress?: boolean;
  inclFamily?: boolean;
} = {}) {
  const currentUser = getCurrentUser();
  if (!currentUser) throw new Error('No current user found');

  const q = doc(getFirestore(), collectionNames.users, currentUser.uid);
  const userDoc = await getDoc(q);

  if (!userDoc.exists()) {
    return fetchUserSchema.parseAsync({
      id: currentUser.uid,
      email: currentUser.email || '',
    });
  }

  let user = await fetchUserSchema.parseAsync({
    ...userDoc.data(),
    id: currentUser.uid,
  });
  if (inclAddress) {
    const addressQuery = query(
      collection(
        getFirestore(),
        collectionNames.users,
        user.id,
        collectionNames.addresses
      ),
      limit(1)
    );
    const addressDoc = await getDocs(addressQuery);
    if (!addressDoc.empty) {
      user = {
        ...user,
        address: locationSchema.parse(addressDoc.docs[0].data()),
      };
    }
  }
  if (inclFamily) {
    const familyQuery = query(
      collection(
        getFirestore(),
        collectionNames.users,
        user.id,
        collectionNames.family
      ),
      where('deleted', '==', false)
    );
    const familyDoc = await getDocs(familyQuery);
    if (!familyDoc.empty) {
      const family = personSchema.array().parse(
        familyDoc.docs.map((doc) => {
          return {
            ...doc.data(),
            birthDate:
              doc.data().birthDate && doc.data().birthDate?.toDate
                ? doc.data().birthDate?.toDate()
                : null,
            id: doc.id,
          };
        })
      );
      user = {
        ...(await user),
        family: family,
      };
    }
  }

  if (!user.family?.some((p) => p.id === user.id)) {
    user.family?.push(
      personSchema.parse({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      })
    );
  } else if (!user.family || !user.family.length) {
    user.family = [
      personSchema.parse({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      }),
    ];
  }

  if (!user.roles[businessId] || !user.roles[businessId].includes('user')) {
    const currentRoles = user.roles;
    currentRoles[businessId] = [...(currentRoles[businessId] || []), 'user'];
    await updateUserRole(currentRoles);
  }

  return user;
}

export async function updateUser(_user: User) {
  console.log({ _user });

  const currentUserId = getCurrentUser()!.uid;
  const bId = currentBusinessId();
  const user = await appendBusiness(_user);
  const address = user.address;
  const family = user.family;
  user.address && delete user.address;
  user.address && delete user.family;

  const trainerCategories = {
    [bId]: user.trainerCategories?.[bId]?.map((c) =>
      doc(getFirestore(), collectionNames.categories, c.id)
    ) || [],
  };
  const managingLocations = {
    [bId]: user.managingLocations?.[bId]?.map((l) =>
      doc(getFirestore(), collectionNames.addresses, l.id)
    ) || [],
  };

  if (
    !user.roles ||
    !user.roles[businessId] ||
    !user.roles[businessId].includes('user')
  ) {
    const currentRoles = user.roles;
    currentRoles[businessId] = [...(currentRoles[businessId] || []), 'user'];
    await updateUserRole(currentRoles);
  }

  const userDoc = doc(getFirestore(), collectionNames.users, currentUserId);
  const userSet = setDoc(userDoc, { ...user, managingLocations, trainerCategories }, { merge: true });
  let addressSet;
  if (address && address.street) {
    const addressDoc = doc(
      getFirestore(),
      collectionNames.users,
      currentUserId,
      collectionNames.addresses,
      address.id
    );
    addressSet = setDoc(addressDoc, address);
  }
  let familySet;
  if (family && family.length) {
    const familySetPromises = family.map((familyMember) => {
      const familyMemberDoc = doc(
        getFirestore(),
        collectionNames.users,
        currentUserId,
        collectionNames.family,
        familyMember.id
      );
      return setDoc(familyMemberDoc, {
        ...familyMember,
        firstName: familyMember.firstName || '',
        lastName: familyMember.lastName || '',
        birthDate: familyMember.birthDate || '',
      });
    });
    familySet = Promise.all(familySetPromises);
  }
  return Promise.all([userSet, addressSet, familySet]);
}

export async function updateUserAddress(userAddress: Location, user?: User) {
  const currentUserId = getCurrentUser()!.uid;
  const addressDoc = doc(
    getFirestore(),
    collectionNames.users,
    user?.id || currentUserId,
    collectionNames.addresses,
    userAddress.id
  );
  return setDoc(addressDoc, userAddress);
}

export async function updateFamilyMember(familyMember: Person) {
  const currentUserId = getCurrentUser()!.uid;
  const familyMemberDoc = doc(
    getFirestore(),
    collectionNames.users,
    currentUserId,
    collectionNames.family,
    familyMember.id
  );
  return setDoc(familyMemberDoc, {
    ...familyMember,
    firstName: familyMember.firstName || '',
    lastName: familyMember.lastName || '',
    birthDate: familyMember.birthDate || '',
  });
}

export async function deleteFamilyMember(familyMemberId: string) {
  const currentUserId = getCurrentUser()!.uid;
  const familyMemberDoc = doc(
    getFirestore(),
    collectionNames.users,
    currentUserId,
    collectionNames.family,
    familyMemberId
  );
  return setDoc(familyMemberDoc, { deleted: true }, { merge: true });
}

export async function updateUserRole(roles: unknown) {
  const currentUserId = getCurrentUser()!.uid;
  const userDoc = doc(getFirestore(), collectionNames.users, currentUserId);
  await setDoc(userDoc, { roles }, { merge: true });
}

export async function addUserRoleToEveryBusinessId() {
  const currentUserId = getCurrentUser()!.uid;
  const userDoc = doc(getFirestore(), collectionNames.users, currentUserId);
  const userDocData = await getDoc(userDoc);
  const user = await fetchUserSchema.parseAsync(userDocData.data());
  const roles = user.roles;
  const businessIds = Object.keys(roles);
  const businessIdsWithUserRole = businessIds.map((id) => {
    if (!roles[id] || !roles[id].includes('user')) {
      roles[id] = [...(roles[id] || []), 'user'];
    }
    return roles;
  });
  return Promise.all(
    businessIdsWithUserRole.map((roles) => {
      return setDoc(userDoc, { roles }, { merge: true });
    })
  );
}
