import { z } from 'zod';
import { firebaseDate } from './date';
import { locationSchema } from './location';
import { personSchema, userSchema } from './users';
import { lessonSchema } from './lesson';
import { productSchema } from './product';
import { businessSchema } from './business';

export const statuses = [
  'created',
  'pending',
  'unpaid',
  'paid',
  'cancelled',
  'expired',
  'free',
  'paid-with-credits',
  'error'
] as const;
export const OrderStatusSchema = z.enum(statuses).default('pending');

export const orderLineTypes = ['lesson', 'product'] as const;
export const OrderLineTypeSchema = z.enum(orderLineTypes);

export const OrderLineSchema = z.object(
  {
    id: z.string(),
    item: z.union([lessonSchema, productSchema]),
    location: locationSchema.optional(),
    amount: z.number().default(1),
    price: z.number().default(0),
    person: z.union([z.literal(''), personSchema]).optional(),
    start: firebaseDate.optional(),
    cycleId: z.string().optional(),
    type: OrderLineTypeSchema.default('lesson'),
    description: z.string().optional().default(''),
  },
  {
    invalid_type_error: 'Invalid order line type',
  }
);

export const orderSchema = z.object(
  {
    id: z.string(),
    createdAt: firebaseDate,
    lines: OrderLineSchema.array().default([]),
    paymentLink: z.string().optional(),
    stripeSessionId: z.string().optional(),
    status: OrderStatusSchema,
    user: userSchema,
    business: businessSchema,
  },
  {
    invalid_type_error: 'Invalid order type',
  }
);

export type Order = z.infer<typeof orderSchema>;
export type OrderStatus = z.infer<typeof OrderStatusSchema>;
export type OrderLineType = z.infer<typeof OrderLineTypeSchema>;
export type OrderLine = z.infer<typeof OrderLineSchema>;
