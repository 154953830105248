import { useForm } from 'react-hook-form';
import { Category, categorySchema } from 'shared/src/schemas/category';
import { zodResolver } from '@hookform/resolvers/zod';
import { v4 as uuid } from 'uuid';
import { Form } from 'frontend/src/layers/form/components/Form';
import { ShortTextField } from 'frontend/src/layers/form/components/fields/ShortTextField';
import { defineMessages, useIntl } from 'react-intl';
import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { ButtonPortal } from 'frontend/src/layers/slide-over/ButtonPortal';
import { TrashIcon } from '@heroicons/react/20/solid';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { generalTranslations } from '@/generalTranslations';
import toast from 'react-hot-toast';

export interface CategoryFormProps {
  defaultValues?: Category;
  onSubmit: (data: Category) => void;
  onDelete: (id: string) => void;
}

export function CategoryForm({
  onSubmit,
  defaultValues,
  onDelete,
}: CategoryFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();
  const formReturn = useForm<Category>({
    resolver: zodResolver(categorySchema),
    defaultValues: {
      id: uuid(),
      ...defaultValues,
    },
  });

  return (
    <div>
      <Form
        onErrors={(errors) => {
          console.log(errors)
          toast.error(formatMessage(generalTranslations.somethingWentWrong))
        }}
        OnSubmit={onSubmit}
        formReturn={formReturn}
        readOnly={!isAllowedTo('categories.write')}
      >
        <HiddenField name="id" />
        <ShortTextField
          name="name"
          label={formatMessage(t.categoryName)}
          hint={formatMessage(t.categoryNameHint)}
        />
      </Form>
      {(defaultValues as Category)?.id && (
        <ButtonPortal>
          <button
            className="ml-4 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
            onClick={() => {
              if (!window.confirm('Do you really want to delete?')) {
                return;
              }
              const id = (defaultValues as Category).id!;
              onDelete(id);
            }}
          >
            <TrashIcon className="h-5 w-5" />
          </button>
        </ButtonPortal>
      )}
    </div>
  );
}
const t = defineMessages({
  categoryName: {
    defaultMessage: 'Category name',
    id: 'categoryform_categoryName',
  },
  categoryNameHint: {
    defaultMessage: 'Name of the category',
    id: 'categoryform_categoryNameHint',
  },
});
