import { Listbox } from '@headlessui/react';
import { FieldValues, Path } from 'react-hook-form';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { BaseListBox } from './BaseListBox';

export interface Option<T> {
  value: T;
  key: string;
  label: string | ReactNode;
}

export interface Props<T, TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  options: Option<T>[];
  label: ReactNode | string;
  buttonRender?: (option: Option<T> | undefined) => ReactNode;
  optionRender?: (option: Option<T>) => ReactNode;
  disabled?: boolean;
  multiple?: boolean;
}

export function GenericSelectInput<T, TFieldValues extends FieldValues>({
  name,
  options,
  label,
  optionRender,
  disabled,
  multiple,
}: Props<T, TFieldValues>) {
  const { formatMessage } = useIntl();

  return (
    <>
      <BaseListBox name={name} disabled={disabled} multiple={multiple}>
        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </Listbox.Label>
        <div className="relative">
          <Listbox.Button className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-secondary-600 sm:text-sm sm:leading-6">
            {({ value }) => {
              const selectedOptions = !multiple
                ? options.filter(
                    (option) => option.key === (value.key || value)
                  )
                : options.filter((option) => value.includes(option.key));

              return (
                <>
                  <div className="block">
                    {value && selectedOptions.length ? (
                      <>
                        <span className="block truncate">
                          {selectedOptions.map((x) => x.label).join(', ')}
                        </span>
                      </>
                    ) : (
                      <span className="block truncate">
                        {formatMessage(t.select)}
                      </span>
                    )}
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                </>
              );
            }}
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option) => {
              if (optionRender) {
                return <>{optionRender(option)}</>;
              }

              return (
                <Listbox.Option
                  key={option.key}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-secondary-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={option.value}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate'
                        )}
                      >
                        {option.label}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-secondary-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </div>
      </BaseListBox>
    </>
  );
}

const t = defineMessages({
  select: {
    id: 'GenericSelectInput-select',
    defaultMessage: 'Make a selection',
  },
});

function classNames(...classes: unknown[]) {
  return classes.filter(Boolean).join(' ');
}
