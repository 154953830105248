import { FieldValues, Path } from 'react-hook-form';
import { ReactNode } from 'react';
import { GenericSelectInput } from '../input/GenericSelectInput';
import { FormField } from '../FormField';
import { defineMessages, useIntl } from 'react-intl';

export interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label?: string | ReactNode;
  hint?: string | ReactNode;
  disabled?: boolean;
  multiple?: boolean;
}

export function DaySelectField<TFieldValues extends FieldValues>({
  name,
  label,
  hint,
  disabled,
  multiple,
}: Props<TFieldValues>) {
  const { formatMessage } = useIntl();
  return (
    <FormField hint={hint} name={name}>
      <GenericSelectInput
        multiple={multiple}
        name={name}
        options={[
          {
            label: formatMessage(t.Monday),
            value: 'Monday',
            key: 'Monday',
          },
          {
            label: formatMessage(t.Tuesday),
            value: 'Tuesday',
            key: 'Tuesday',
          },
          {
            label: formatMessage(t.Wednesday),
            value: 'Wednesday',
            key: 'Wednesday',
          },
          {
            label: formatMessage(t.Thursday),
            value: 'Thursday',
            key: 'Thursday',
          },
          {
            label: formatMessage(t.Friday),
            value: 'Friday',
            key: 'Friday',
          },
          {
            label: formatMessage(t.Saturday),
            value: 'Saturday',
            key: 'Saturday',
          },
          {
            label: formatMessage(t.Sunday),
            value: 'Sunday',
            key: 'Sunday',
          },
        ]}
        label={label ?? 'Select'}
        disabled={disabled}
      />
    </FormField>
  );
}
export const t = defineMessages({
  Monday: {
    defaultMessage: 'Monday',
    id: 'dayselectfield_monday',
  },
  Tuesday: {
    defaultMessage: 'Tuesday',
    id: 'dayselectfield_tuesday',
  },
  Wednesday: {
    defaultMessage: 'Wednesday',
    id: 'dayselectfield_wednesday',
  },
  Thursday: {
    defaultMessage: 'Thursday',
    id: 'dayselectfield_thursday',
  },
  Friday: {
    defaultMessage: 'Friday',
    id: 'dayselectfield_friday',
  },
  Saturday: {
    defaultMessage: 'Saturday',
    id: 'dayselectfield_saturday',
  },
  Sunday: {
    defaultMessage: 'Sunday',
    id: 'dayselectfield_sunday',
  },
});
