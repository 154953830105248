import { FormField, FormFieldProps } from '../FormField';
import { FieldValues } from 'react-hook-form';
import { PhoneInput, Props as PhoneInputProps } from '../input/PhoneInput';

export interface PhoneFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
    PhoneInputProps<TFieldValues> {}

export function PhoneField<TFieldValues extends FieldValues>({
  name,
  disabled,
  hint,
  label,
}: PhoneFieldProps<TFieldValues>) {
  return (
    <FormField label={label} hint={hint} name={name}>
      <PhoneInput name={name} disabled={disabled} />
    </FormField>
  );
}
