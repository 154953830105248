import React from "react";
import BaseWidget from "./base";
import { defineMessages, useIntl } from "react-intl";
import { CurrencyDollarIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { fetchRevenueForTimePeriod } from "@/services/invoicesService";
import { nFormatter } from "shared/src/utils/numberFormatter";


export default function revenueWidget() {
  const { formatMessage } = useIntl();
  const { data: theRevenueOfThisMonth, isLoading } = useQuery({
    queryKey: ["invoices", "sum"],
    queryFn: () => {
      const beginOfMonth = DateTime.now().startOf('month').toJSDate();
      const endOfMonth = DateTime.now().endOf('month').toJSDate();
      return fetchRevenueForTimePeriod({
        from: beginOfMonth,
        to: endOfMonth
      })
    },
    initialData: 0
  });
  const formatter = (value: number) => `€ ${nFormatter(value, 1, true)}`;
  return (
    <BaseWidget
      icon={(<CurrencyDollarIcon />)}
      isLoading={isLoading}
      number={theRevenueOfThisMonth || "0"}
      title={formatMessage(t.thisMonthsRevenue)}
      extraText={formatMessage(t.vatIncluded)}
      formatter={formatter}
    />
  );
}
const t = defineMessages({
  thisMonthsRevenue: {
    id: "revenue_widget_this_months_revenue",
    defaultMessage: "Revenue"
  },
  vatIncluded: {
    id: "revenue_widget_vat_included",
    defaultMessage: "VAT included"
  }
})