export default function Logo({
  showLoginLogo = false,
}: {
  showLoginLogo?: boolean;
}) {
  return (
    <div
      className="h-16 w-full bg-contain bg-no-repeat"
      style={{
        backgroundImage: `var(--logo${showLoginLogo ? '-login-screen' : ''})`,
        backgroundPosition: 'center left',
      }}
    ></div>
  );
}
