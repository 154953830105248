import PageHeading from 'frontend/src/components/PageHeading';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ORDERS_COUNT_QUERY_KEY, ORDERS_QUERY_KEY } from '../api/ordersApi';
import { fetchAmountOfOrders, fetchOrders } from 'frontend/src/services/orderService';
import { generalTranslations } from '@/generalTranslations';
import toast from 'react-hot-toast';
import StatusBadge from 'frontend/src/components/statusBadge';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from 'frontend/src/components/pagination';
import OrderFilter from './components/filter';
import { OrderStatus } from 'shared/src/schemas/orders';

export default function OrdersPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    userIds: searchParams.get('userIds')
      ? searchParams.get('userIds')!.split(',')
      : [],
    itemIds: searchParams.get('itemIds')
      ? searchParams.get('itemIds')!.split(',')
      : [],
    statuses: searchParams.get('statuses')
      ? searchParams.get('statuses')!.split(',')
      : [],
  });
  useEffect(() => {
    setSearchParams({
      userIds: filters.userIds.join(','),
      itemIds: filters.itemIds.join(','),
      statuses: filters.statuses.join(','),
    });
  }, [filters]);

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const { data: orders, error } = useQuery({
    queryKey: [...ORDERS_QUERY_KEY, page, JSON.stringify(filters)],
    queryFn: () => {
      return fetchOrders({
        page,
        inclLines: true,
        userIds: filters.userIds,
        statuses: filters.statuses as OrderStatus[]
      });
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.orders).toLowerCase(),
      })
    );
  }
  const { data: amountOfOrders } = useQuery({
    queryKey: [...ORDERS_COUNT_QUERY_KEY, JSON.stringify(filters)],

    queryFn: () => {
      return fetchAmountOfOrders({
        userIds: filters.userIds,
        statuses: filters.statuses as OrderStatus[],
      });
    },

    initialData: 0,
  });
  return (
    <>
      <PageHeading title={formatMessage(t.orders)} />
      <OrderFilter filters={filters} setFilters={setFilters} />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-6 text-left text-sm font-semibold text-gray-900"
                    >
                      {formatMessage(t.creationDate)}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {formatMessage(t.user)}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {formatMessage(t.products)}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
                    >
                      {formatMessage(t.status)}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {orders?.map((order) => (
                    <tr key={order.id} onClick={() => console.log(order)}>
                      <td className="px-3 pl-6 py-5 text-sm text-gray-500">
                        <div className="font-medium text-gray-900">
                          {order.createdAt.toLocaleDateString()}
                        </div>
                        <div className="mt-1 text-gray-500">
                          {order.createdAt.toLocaleTimeString()}
                        </div>
                      </td>
                      <td className="px-3 py-5 text-sm text-gray-500">
                        <a
                          className="cursor-pointer underline hover:text-blue-900 text-blue-500"
                          onClick={() => {
                            navigate(`/users/${order.user.id}`);
                          }}
                        >
                          {order.user.firstName} {order.user.lastName}
                        </a>
                      </td>
                      <td className="px-3 py-5 text-sm text-gray-500">
                        {order.lines.map((l) => (
                          <span key={`${order.id}-${l.item.name}`}>
                            <div className="font-medium text-gray-900">
                              {l.item.name}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {l.location?.name}
                            </div>
                          </span>
                        ))}
                      </td>
                      <td className="px-3 py-5 text-sm text-gray-500 text-center">
                        <StatusBadge status={order.status} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalItems={amountOfOrders}
                page={page}
                nextPage={() => setPage(page + 1)}
                previousPage={() => setPage(page - 1)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const t = defineMessages({
  orders: {
    defaultMessage: 'Orders',
    description: '',
    id: 'OrdersPage-title',
  },
  creationDate: {
    defaultMessage: 'Creation date',
    description: '',
    id: 'OrdersPage-creationDate',
  },
  user: {
    defaultMessage: 'User',
    description: '',
    id: 'OrdersPage-user',
  },
  products: {
    defaultMessage: 'Products',
    description: '',
    id: 'OrdersPage-products',
  },
  status: {
    defaultMessage: 'Status',
    description: '',
    id: 'OrdersPage-status',
  },
});
