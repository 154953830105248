import { LoadingSpinner } from 'frontend/src/components/LoadingSpinner';
import { generalTranslations } from '../generalTranslations';
import { RESERVATIONS_QUERY_KEY } from '../pages/reservations/api/reservationsApi';
import { useQuery } from '@tanstack/react-query';
import CollapsibleField from 'frontend/src/layers/form/components/fields/CollapsibleField';
import { fetchReservations } from 'frontend/src/services/reservationService';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { defineMessages, useIntl } from 'react-intl';
import { queryClient } from '../lib/queryClient';
import { mapCalendarEvents } from '../pages/reservations/api/mapper';

export default function PreviousReservations({ cycleId }: { cycleId: string }) {
  const { formatMessage } = useIntl();
  const [contentIsShown, setContentIsShown] = useState(false);
  const {
    data: reservations,
    error,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: [...RESERVATIONS_QUERY_KEY, cycleId],
    enabled: contentIsShown,
    queryFn: async () => {
      const today = new Date();
      const oneYearAgo = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );
      const unMappedReservations = fetchReservations({
        cycleId,
        start: oneYearAgo,
        end: today,
      });
      return mapCalendarEvents(await unMappedReservations, true).sort(
        (a, b) => a.start.getTime() - b.start.getTime()
      );
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.previousReservations).toLowerCase(),
      })
    );
  }
  return (
    <CollapsibleField
      onToggle={(status) => {
        if (!status)
          queryClient.invalidateQueries({
            queryKey: [...RESERVATIONS_QUERY_KEY, cycleId],
          });
        setContentIsShown(status);
      }}
      label={formatMessage(t.previousReservations)}
    >
      <table className="divide-y divide-gray-300 w-full">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              {formatMessage(t.date)}
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              {formatMessage(t.trainer)}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {formatMessage(t.feedback)}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {isLoading && (
            <tr>
              <td colSpan={5} className="py-4 text-center text-gray-500">
                <LoadingSpinner />
              </td>
            </tr>
          )}
          {!isLoading &&
            isFetched &&
            (!reservations || !reservations.length) && (
              <tr>
                <td colSpan={5} className="py-4 text-center text-gray-500">
                  {formatMessage(t.noReservations)}
                </td>
              </tr>
            )}
          {reservations?.map((reservation) => {
            const allTrainers =
              reservation.reservationsWithAllData
                ?.map((x) => x.trainers)
                .flat() || [];
            const uniqueTrainersBasedOnId = Array.from(
              new Set(allTrainers.map((x) => x.id))
            ).map((id) => allTrainers.find((x) => x.id === id));

            return (
              <tr key={reservation.id}>
                <td className="align-top w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                  {reservation.start.toLocaleDateString()}
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">{formatMessage(t.trainer)}</dt>
                    <dd className="mt-1 truncate text-gray-700">
                      {uniqueTrainersBasedOnId
                        .map((x) => x?.firstName)
                        .join(', ')}
                    </dd>
                  </dl>
                </td>
                <td className="align-top hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {uniqueTrainersBasedOnId.map((x) => x?.firstName).join(', ')}
                </td>
                <td className="align-top px-3 py-4 text-sm text-gray-900">
                  {reservation.reservationsWithAllData?.[0].feedback?.comment ||
                    '-'}
                  {reservation.reservationsWithAllData?.map((x) =>
                    x.feedback?.comment ? (
                      <p key={x.id}>
                        <span className="text-gray-500">
                          {x.person?.firstName || x.user.firstName}
                        </span>
                        : {x.personalFeedback?.comment || '-'}
                      </p>
                    ) : null
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </CollapsibleField>
  );
}

const t = defineMessages({
  previousReservations: {
    id: 'admin_PreviousReservations_previousReservations',
    defaultMessage: 'Previous Reservations',
  },
  date: {
    id: 'admin_PreviousReservations_date',
    defaultMessage: 'Date',
  },
  location: {
    id: 'admin_PreviousReservations_location',
    defaultMessage: 'Location',
  },
  trainer: {
    id: 'admin_PreviousReservations_trainer',
    defaultMessage: 'Trainer',
  },
  feedback: {
    id: 'admin_PreviousReservations_feedback',
    defaultMessage: 'Feedback',
  },
  noReservations: {
    id: 'admin_PreviousReservations_noReservations',
    defaultMessage: 'No reservations',
  },
  person: {
    id: 'admin_PreviousReservations_person',
    defaultMessage: 'Person',
  },
});
