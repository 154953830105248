import { UseFormReturn, useForm } from 'react-hook-form';
import { Product, productSchema } from 'shared/src/schemas/product';
import { zodResolver } from '@hookform/resolvers/zod';
import { v4 as uuid } from 'uuid';
import { Form } from 'frontend/src/layers/form/components/Form';
import { ShortTextField } from 'frontend/src/layers/form/components/fields/ShortTextField';
import { defineMessages, useIntl } from 'react-intl';
import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { ButtonPortal } from 'frontend/src/layers/slide-over/ButtonPortal';
import { TrashIcon } from '@heroicons/react/20/solid';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { generalTranslations } from '@/generalTranslations';
import { CATEGORY_QUERY_KEY } from '@/pages/categories/lib/categoriesApi';
import { fetchCategories } from '@/services/categoryService';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { NumberField } from 'frontend/src/layers/form/components/fields/NumberField';
import { SelectField } from 'frontend/src/layers/form/components/fields/SelectField';
import { FileField } from 'frontend/src/layers/form/components/fields/FileField';
import { collectionNames } from 'shared';
import { string } from 'zod';
import { categorySchema } from 'shared/src/schemas/category';

export interface ProductFormProps {
  defaultValues?: Product;
  onSubmit: (data: Product) => void;
  onDelete?: (id: string) => void;
}

export function ProductForm({
  onSubmit,
  defaultValues,
  onDelete,
}: ProductFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();

  const productFormSchema = productSchema.extend({
    category: string().transform((cat) => {
      return categorySchema.parse({
        id: cat,
        name: categories?.find((c) => c.id === cat)?.name ?? '',
      });
    }),
  });

  const formReturn = useForm({
    resolver: zodResolver(productFormSchema),
    defaultValues: {
      id: uuid(),
      vat: 21,
      ...defaultValues,
      category: defaultValues?.category?.id ?? undefined,
    },
  });

  const { data: categories, error } = useQuery({
    queryKey: CATEGORY_QUERY_KEY,

    queryFn: () => {
      return fetchCategories();
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.categories),
      })
    );
  }

  const categoryOptions = categories?.map((category) => ({
    label: category.name,
    value: category.id,
    key: category.id,
  }));

  return (
    <div>
      <Form
        onErrors={(errors) => {
          console.log(errors)
          toast.error(formatMessage(generalTranslations.somethingWentWrong))
        }}
        OnSubmit={onSubmit}
        formReturn={formReturn as unknown as UseFormReturn<Product>}
        readOnly={!isAllowedTo('products.write')}
      >
        <HiddenField name="id" />
        <ShortTextField name={'name'} label={formatMessage(t.name)} />
        <div className={'md:grid grid-cols-2 gap-4'}>
          <NumberField
            name={'price'}
            step={0.01}
            label={formatMessage(t.price)}
            hint={formatMessage(t.priceHint)}
            prefix={'€'}
          />
          <NumberField
            name={'vat'}
            label={formatMessage(t.vat)}
            postfix={'%'}
          />
        </div>
        <SelectField
          name={'category'}
          label={formatMessage(t.category)}
          hint={formatMessage(t.categoryHint)}
          options={categoryOptions || []}
        />
        <ShortTextField
          name={'description'}
          label={formatMessage(t.description)}
        />
        <FileField
          filePath={collectionNames.products}
          name="image"
          label={formatMessage(t.image)}
        />
      </Form>
      {(defaultValues as Product)?.id && onDelete && (
        <ButtonPortal>
          <button
            className="ml-4 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
            onClick={() => {
              if (!window.confirm('Do you really want to delete?')) {
                return;
              }
              const id = (defaultValues as Product).id!;
              onDelete(id);
            }}
          >
            <TrashIcon className="h-5 w-5" />
          </button>
        </ButtonPortal>
      )}
    </div>
  );
}
const t = defineMessages({
  productName: {
    defaultMessage: 'Product name',
    id: 'productform_productName',
  },
  categories: {
    defaultMessage: 'Categories',
    id: 'productform_categories',
  },
  category: {
    defaultMessage: 'Category',
    id: 'productform_category',
  },
  categoryHint: {
    defaultMessage: 'Select a category',
    id: 'productform_categoryHint',
  },
  name: {
    defaultMessage: 'Product name',
    id: 'productform_name',
  },
  price: {
    defaultMessage: 'Price',
    id: 'productform_price',
  },
  priceHint: {
    defaultMessage: 'Price per unit incl VAT',
    id: 'productform_priceHint',
  },
  vat: {
    defaultMessage: 'VAT',
    id: 'productform_vat',
  },
  description: {
    defaultMessage: 'Product description',
    id: 'productform_description',
  },
  image: {
    defaultMessage: 'Image',
    id: 'productform_image',
  },
});
