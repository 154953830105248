import { isString } from '../../../../utils/isString';
import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { BaseInput } from './BaseInput';

export interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  disabled?: boolean;
  min?: Date | string;
  max?: Date | string;
}

export function DateTimeInput<TFieldValues extends FieldValues>({
  name,
  disabled,
  min,
  max,
}: Props<TFieldValues>) {
  const inputClass = `disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block w-full min-w-0 flex-1 border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 rounded-md`;

  if (min && !isString(min)) min = min?.toISOString().slice(0, 16);
  if (max && !isString(max)) max = max?.toISOString().slice(0, 16);

  return (
    <BaseInput
      name={name}
      onWheel={() => (document.activeElement as HTMLElement).blur()}
      className={inputClass}
      disabled={disabled}
      type="datetime-local"
      min={min}
      max={max}
      step="300"
    />
  );
}
