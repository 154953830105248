import PageHeading from 'frontend/src/components/PageHeading';
import {
  deleteLocation,
  fetchLocations,
  saveLocation,
} from 'frontend/src/services/locationsService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { defineMessages, useIntl } from 'react-intl';
import { LOCATIONS_QUERY_KEY } from '../api/locationsApi';
import SingleLocationView from './components/SingleLocationView';
import { generalTranslations } from '@/generalTranslations';
import toast from 'react-hot-toast';
import { queryClient } from '@/lib/queryClient';
import LocationForm from '@/form/LocationForm';
import { SlideOverFlowConduit, useCancelFlow, useStartFlow } from '@/lib/flow';
import { locationSchema } from 'shared/src/schemas/location';

export default function LocationsPage() {
  const { formatMessage } = useIntl();
  const CREATE_LOCATION = 'CREATE_LOCATION';
  const EDIT_LOCATION = 'EDIT_LOCATION';
  const startFlow = useStartFlow();
  const closeFlow = useCancelFlow();

  const { data: locations, error } = useQuery({
    queryKey: LOCATIONS_QUERY_KEY,

    queryFn: () => {
      return fetchLocations({});
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.locations).toLowerCase(),
      })
    );
  }

  const { mutate: onDeleteLocation } = useMutation({
    mutationFn: deleteLocation,
    onSuccess: () => {
      toast.success(formatMessage(t.locationDeleted));
      queryClient
        .invalidateQueries({ queryKey: LOCATIONS_QUERY_KEY })
        .catch(console.error);
      closeFlow();
    },
  });

  const { mutate: submitCreateLocation } = useMutation({
    mutationFn: saveLocation,
    onSuccess: () => {
      toast.success(formatMessage(t.updateSaved));
      queryClient
        .invalidateQueries({ queryKey: LOCATIONS_QUERY_KEY })
        .catch(console.error);
      closeFlow();
    },
  });

  return (
    <>
      <PageHeading
        title={formatMessage(t.title)}
        actions={[
          {
            label: formatMessage(t.create),
            action: () => {
              startFlow({ name: CREATE_LOCATION });
            },
            requiredPermission: 'locations.write',
          },
        ]}
      />
      <ul role="list" className="flex flex-col gap-4">
        {locations?.map((location) => (
          <li
            key={location.id}
            onClick={() => {
              startFlow({ name: EDIT_LOCATION, payload: { location } });
            }}
          >
            <SingleLocationView location={location} />
          </li>
        ))}
      </ul>
      <SlideOverFlowConduit
        title={formatMessage(t.title)}
        flowMapping={{
          [CREATE_LOCATION]: () => (
            <LocationForm
              onSubmit={(data) => {
                submitCreateLocation(data);
              }}
            />
          ),
          [EDIT_LOCATION]: (params) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const location = locationSchema.parse(params.location);
            return (
              <LocationForm
                onSubmit={(data) => {
                  submitCreateLocation(data);
                }}
                defaultValues={location}
                onDelete={onDeleteLocation}
              />
            );
          },
        }}
      />
    </>
  );
}

const t = defineMessages({
  title: {
    defaultMessage: 'Locations',
    id: 'LocationsPage-title',
  },
  create: {
    defaultMessage: 'Create location',
    id: 'LocationsPage-create',
  },
  locations: {
    defaultMessage: 'locations',
    id: 'LocationsPage-locations',
  },
  updateSaved: {
    defaultMessage: 'Update saved',
    id: 'LocationsPage-updateSaved',
  },
  locationDeleted: {
    defaultMessage: 'Location deleted',
    id: 'LocationsPage-locationDeleted',
  },
});
