import { FormField, FormFieldProps } from '../FormField';
import { FieldValues } from 'react-hook-form';
import { EmailInput, Props as EmailInputProps } from '../input/EmailInput';

export interface EmailFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
    EmailInputProps<TFieldValues> {}

export function EmailField<TFieldValues extends FieldValues>({
  name,
  disabled,
  hint,
  label,
}: EmailFieldProps<TFieldValues>) {
  return (
    <FormField label={label} hint={hint} name={name}>
      <EmailInput name={name} disabled={disabled} />
    </FormField>
  );
}
