import { FieldValues } from 'react-hook-form';
import { FormField, FormFieldProps } from '../FormField';
import { NumberInput, Props } from '../input/NumberInput';

export interface NumberFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
    Props<TFieldValues> {}

export function NumberField<TFieldValues extends FieldValues>({
  name,
  prefix,
  postfix,
  disabled,
  hint,
  label,
  step,
}: NumberFieldProps<TFieldValues>) {
  return (
    <FormField label={label} hint={hint} name={name}>
      <NumberInput
        name={name}
        prefix={prefix}
        postfix={postfix}
        disabled={disabled}
        step={step}
      />
    </FormField>
  );
}
