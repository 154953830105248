import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormReturn, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { TrashIcon } from '@heroicons/react/24/solid';
import {
  timeSlotSchema,
  TimeSlot,
  TimeEntry,
} from 'shared/src/schemas/timeSlot';
import { Form } from 'frontend/src/layers/form/components/Form';
import { ComboBoxField } from 'frontend/src/layers/form/components/fields/ComboBoxField';
import { useQuery } from '@tanstack/react-query';
import { fetchLocations } from 'frontend/src/services/locationsService';
import { DateTimeField } from 'frontend/src/layers/form/components/fields/DateTimeField';
import { LOCATIONS_QUERY_KEY } from '@/pages/locations/api/locationsApi';
import { string } from 'zod';
import React from 'react';
import { ButtonPortal } from 'frontend/src/layers/slide-over/ButtonPortal';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { USERS_QUERY_KEY } from '@/pages/users/api/usersApi';
import { fetchTrainers } from 'frontend/src/services/usersService';
import { fetchCurrentUser } from 'frontend/src/services/currentUserService';
import { currentBusinessId } from 'frontend/src/services/businessService';
import toast from 'react-hot-toast';
import { generalTranslations } from '@/generalTranslations';

export interface AvailabilityFormProps {
  defaultValues?: TimeSlot | TimeEntry;
  onSubmit: (data: TimeSlot) => void;
  onDelete: (id: string[]) => void;
  readOnly?: boolean;
}

export function AvailabilityForm({
  onSubmit,
  onDelete,
  defaultValues,
  readOnly,
}: AvailabilityFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();
  const { data: currentUser } = useQuery({
    queryKey: ['currentUser'],
    enabled: !readOnly,
    queryFn: () => fetchCurrentUser(),
  });
  const businessId = currentBusinessId();

  const isTrainer = currentUser?.roles?.[businessId].includes('trainer');

  const { data: locations } = useQuery({
    queryKey: [...LOCATIONS_QUERY_KEY],
    queryFn: () =>
      fetchLocations({
        onlyAllowed: true,
      }),
    enabled: !readOnly,
    initialData: [],
  });

  const { data: trainers } = useQuery({
    queryKey: [...USERS_QUERY_KEY, 'trainers'],
    queryFn: () => fetchTrainers(),
    initialData: [],
    enabled: !readOnly,
  });

  const availabilityFormSchema = timeSlotSchema.extend({
    location: string().transform((value) =>
      locations.find((location) => location.id === value)
    ),
    trainer:
      currentUser && isTrainer
        ? string()
          .optional()
          .transform(() =>
            trainers.find((trainer) => trainer.id === currentUser.id)
          )
        : string()
          .optional()
          .transform((value) =>
            trainers.find((trainer) => trainer.id === value)
          ),
  });

  const formReturn = useForm({
    resolver: zodResolver(availabilityFormSchema),
    defaultValues: {
      id: uuid(),
      ...defaultValues,
      // set location to location id if defaultValues is TimeSlot
      location: (defaultValues as TimeSlot)?.location?.id,
      trainer: (defaultValues as TimeSlot)?.trainer?.id,
    },
  });

  return (
    <>
      <div>
        <Form
          onErrors={(errors) => {
            console.log(errors)
            toast.error(formatMessage(generalTranslations.somethingWentWrong))
          }}
          OnSubmit={onSubmit}
          formReturn={formReturn as unknown as UseFormReturn<TimeSlot>}
          readOnly={readOnly || !isAllowedTo('availabilities.write')}
        >
          <HiddenField name="id" />
          <DateTimeField name="start" label={formatMessage(t.start)} />
          <DateTimeField name="end" label={formatMessage(t.end)} />
          <ComboBoxField
            name="location"
            options={locations.map((location) => ({
              label: location.name || '',
              value: location.id,
              key: location.id,
            }))}
            label={formatMessage(t.location)}
          />
          {!isTrainer && (
            <ComboBoxField
              name="trainer"
              options={trainers.map((trainer) => ({
                label: `${trainer.firstName} ${trainer.lastName}`,
                value: trainer.id,
                key: trainer.id,
              }))}
              label={formatMessage(t.trainer)}
            />
          )}
        </Form>
      </div>
      {(defaultValues as TimeSlot)?.id && onDelete && (
        <ButtonPortal>
          <button
            className="ml-4 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
            onClick={() => {
              if (!window.confirm(formatMessage(t.confirmDelete))) {
                return;
              }
              const ids = (defaultValues as TimeSlot).ids!;
              onDelete(ids);
            }}
          >
            <TrashIcon className="h-5 w-5" />
          </button>
        </ButtonPortal>
      )}
    </>
  );
}

const t = defineMessages({
  start: {
    defaultMessage: 'Start',
    id: 'availabilityform_start',
  },
  end: {
    defaultMessage: 'End',
    id: 'availabilityform_end',
  },
  location: {
    defaultMessage: 'Location',
    id: 'availabilityform_location',
  },
  trainer: {
    defaultMessage: 'Trainer',
    id: 'availabilityform_trainer',
  },
  deleted: {
    defaultMessage: 'Deleted',
    id: 'availabilityform_deleted',
  },
  confirmDelete: {
    defaultMessage: 'Do you really want to delete?',
    id: 'availabilityform_confirmDelete',
  },
});
