import React from 'react';

export default function SectionHeading({
  title,
  message,
}: {
  title: string;
  message: string;
}) {
  return (
    <div className="mb-4 mt-8">
      <h1 className="text-lg font-medium leading-6 text-gray-900">{title}</h1>
      <p className="mt-1 text-sm text-gray-500">{message}</p>
    </div>
  );
}
