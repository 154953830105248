import { TRAINERS_QUERY_KEY } from '@/pages/availabilities/api/availabilitiesApi';
import { LESSONS_QUERY_KEY } from '@/pages/lessons/api/lessonsApi';
import { LOCATIONS_QUERY_KEY } from '@/pages/locations/api/locationsApi';
import { useQuery } from '@tanstack/react-query';
import BasicCombobox from 'frontend/src/layers/form/components/nonFormInput/BasicComboBox';
import { UserComboBox } from 'frontend/src/layers/form/components/nonFormInput/UserComboBox';
import { fetchLessons } from 'frontend/src/services/lessonService';
import { fetchLocations } from 'frontend/src/services/locationsService';
import { fetchTrainers } from 'frontend/src/services/usersService';
import React from 'react';
import toast from 'react-hot-toast';
import { defineMessages, useIntl } from 'react-intl';

interface Filter {
  userIds: string[];
  trainerIds: string[];
  locationIds: string[];
  lessonIds: string[];
  startDate: Date;
  endDate: Date;
  view: string;
};

export default function ReservationFilters({
  setFilters,
  filters
}: {
  filters: Filter;
  setFilters: (filters: Filter) => void;
}) {
  const { formatMessage } = useIntl();
  const { data: locations } = useQuery({
    queryKey: [...LOCATIONS_QUERY_KEY],
    queryFn: () => fetchLocations({ onlyAllowed: true }),
    initialData: [],
  });
  const { data: lessons } = useQuery({
    queryKey: [...LESSONS_QUERY_KEY],
    queryFn: () => fetchLessons({ showHidden: true, all: true }),
    initialData: [],
  });
  const { data: trainers } = useQuery({
    queryKey: [...TRAINERS_QUERY_KEY],
    queryFn: () => fetchTrainers(),
    initialData: [],
  });
  return (
    <div className="flex gap-4 my-4">
      <div className="w-full">
        <UserComboBox
          onError={(error) => toast.error(error)}
          label={formatMessage(t.selectCustomer)}
          selectedUserIds={filters.userIds}
          selectUserIds={(selectUserIds) => setFilters({ ...filters, userIds: selectUserIds })}
        />
      </div>
      <div className="w-full ">
        <BasicCombobox
          onError={(error) => toast.error(error)}
          label={formatMessage(t.selectLocation)}
          options={locations.map((location) => ({
            label: location.name!,
            key: location.id,
            value: location.id,
          }))}
          value={filters.locationIds.map((id) => {
            const location = locations.find((location) => location.id === id);
            return {
              label: location?.name || 'location',
              key: id,
              value: id,
            };
          })}
          onChange={(e) => {
            setFilters({ ...filters, locationIds: e.map((s) => s.value) });
          }}
          onClear={() => setFilters({ ...filters, locationIds: [] })}
        />
      </div>
      <div className="w-full ">
        <BasicCombobox
          onError={(error) => toast.error(error)}
          label={formatMessage(t.selectLesson)}
          options={lessons.map((lesson) => ({
            label: lesson.name!,
            key: lesson.id,
            value: lesson.id,
          }))}
          value={filters.lessonIds.map((id) => {
            const lesson = lessons.find((l) => l.id === id);
            return {
              label: lesson?.name || '',
              key: id,
              value: id,
            };
          })}
          onChange={(e) => {
            setFilters({ ...filters, lessonIds: e.map((s) => s.value) });
          }}
          onClear={() => setFilters({ ...filters, lessonIds: [] })}
        />
      </div>
      <div className="w-full ">
        <BasicCombobox
          onError={(error) => toast.error(error)}
          label={formatMessage(t.selectTrainer)}
          options={trainers.map((trainer) => ({
            label: `${trainer.firstName} ${trainer.lastName}`,
            key: trainer.id,
            value: trainer.id,
          }))}
          value={filters.trainerIds.map((id) => {
            const trainer = trainers.find((l) => l.id === id);
            return {
              label: trainer ? `${trainer.firstName} ${trainer.lastName}` : '',
              key: id,
              value: id,
            };
          })}
          onChange={(e) => {
            setFilters({ ...filters, trainerIds: e.map((s) => s.value) });
          }}
          onClear={() => setFilters({ ...filters, trainerIds: [] })}
        />
      </div>
    </div>
  );
}

const t = defineMessages({
  selectCustomer: {
    id: 'reservation-filters-select-customer',
    defaultMessage: 'Customer',
  },
  selectLocation: {
    id: 'reservation-filters-select-location',
    defaultMessage: 'Location',
  },
  selectLesson: {
    id: 'reservation-filters-select-lesson',
    defaultMessage: 'Lesson',
  },
  selectTrainer: {
    id: 'reservation-filters-select-trainer',
    defaultMessage: 'Trainer',
  },
});
