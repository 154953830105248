import Logo from 'frontend/src/components/Logo';
import getAuthUrlForThisApp from 'frontend/src/utils/getAuthUrlForApp';
import { defineMessages, useIntl } from 'react-intl';

export function ErrorComponent() {
  const { formatMessage } = useIntl();

  return (
    <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
      <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
        <a href={getAuthUrlForThisApp()}>
          <Logo showLoginLogo />
        </a>
      </header>

      <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
        <div className="max-w-lg">
          <p className="text-primary-500 font-semibold leading-8 ">
            {' '}
            {formatMessage(t.ErrorOccured)}
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-secondary-500 sm:text-5xl">
            {formatMessage(t.ErrorHeader)}
          </h1>
          <p className="mt-6 text-base leading-7 text-secondary-600">
            {formatMessage(t.Errordescription)}
          </p>
          <div className="mt-10">
            <a
              href="/"
              className="text-sm font-semibold leading-7 text-secondary-500"
            >
              <span aria-hidden="true">&larr;</span>{' '}
              {formatMessage(t.ErrorGoBack)}
            </a>
          </div>
        </div>
      </main>

      <div
        className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `var(--login-image)`,
        }}
      ></div>
    </div>
  );
}

const t = defineMessages({
  ErrorOccured: {
    id: 'error-occurred',
    defaultMessage: 'Oops! Something went wrong.',
  },
  Errordescription: {
    id: 'error-description',
    defaultMessage:
      'We apologize for the inconvenience. Our team has been notified, and we are working to fix this issue as soon as possible.',
  },
  ErrorGoBack: { id: 'error-goBack', defaultMessage: 'Go back' },
  ErrorHeader: { id: 'error-header', defaultMessage: 'An error occurred' },
});
