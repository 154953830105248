import PageHeading from 'frontend/src/components/PageHeading';
import { fetchCategories } from '@/services/categoryService';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CATEGORY_QUERY_KEY } from '../lib/categoriesApi';
import { SlideOverFlowConduit, useStartFlow } from '@/lib/flow';
import { generalTranslations } from '@/generalTranslations';
import toast from 'react-hot-toast';
import CategoryDialog from '@/components/CategoryDialog';

export default function CategoriesPage() {
  const { formatMessage } = useIntl();
  const ADD_CATEGORY_DIALOG = 'ADD_CATEGORY_DIALOG';
  const EDIT_CATEGORY_DIALOG = 'EDIT_CATEGORY_DIALOG';
  const startFlow = useStartFlow();

  const { data: categories, error } = useQuery({
    queryKey: CATEGORY_QUERY_KEY,

    queryFn: () => {
      return fetchCategories();
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.categories).toLowerCase(),
      })
    );
  }

  return (
    <>
      <PageHeading
        title={formatMessage(t.categories)}
        actions={[
          {
            label: formatMessage(t.addCategory),
            action: () => {
              startFlow({ name: ADD_CATEGORY_DIALOG });
            },
            requiredPermission: 'categories.write',
          },
        ]}
      />
      <table role="list" className="min-w-full divide-y divide-gray-300">
        <tbody className="divide-y divide-gray-200">
          {categories?.map((category) => (
            <tr
              className="hover:bg-gray-100 cursor-pointer"
              key={category.id}
              onClick={() => {
                startFlow({
                  name: EDIT_CATEGORY_DIALOG,
                  payload: {
                    category,
                  },
                });
              }}
            >
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                {category.name}
              </td>
              {/* TODO maybe add amount of trainers with this category */}
              {/* TODO maybe add amount of lessons with this category */}
            </tr>
          ))}
        </tbody>
      </table>
      <SlideOverFlowConduit
        title={formatMessage(t.category)}
        flowMapping={{
          [ADD_CATEGORY_DIALOG]: () => <CategoryDialog />,
          [EDIT_CATEGORY_DIALOG]: (params) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const category = params.category;
            return <CategoryDialog category={category} />;
          },
        }}
      />
    </>
  );
}
const t = defineMessages({
  categories: {
    defaultMessage: 'Categories',
    description: '',
    id: `categoriesPage_categories`,
  },
  category: {
    defaultMessage: 'Category',
    description: '',
    id: `categoriesPage_category`,
  },
  addCategory: {
    defaultMessage: 'Add category',
    description: '',
    id: `categoriesPage_addCategory`,
  },
});
