import { FieldValues, Path } from 'react-hook-form';
import { ReactNode } from 'react';
import { FormField } from '../FormField';
import { GenericComboBox, Option } from '../input/GenericComboBox';

export interface Props<T, TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  options: Option<T>[];
  label?: string | ReactNode;
  hint?: string | ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  optionRender?: (option: Option<T>) => ReactNode;
  customSorting?: (a: Option<T>, b: Option<T>) => number;
}

export function ComboBoxField<T, TFieldValues extends FieldValues>({
  name,
  options,
  label,
  hint,
  disabled,
  multiple,
  optionRender,
  customSorting,
}: Props<T, TFieldValues>) {
  return (
    <FormField hint={hint} name={name}>
      <GenericComboBox
        multiple={multiple}
        name={name}
        options={options}
        label={label ?? 'Select'}
        disabled={disabled}
        optionRender={optionRender}
        customSorting={customSorting}
      />
    </FormField>
  );
}
