import { User } from 'shared/src/schemas/users';
import { Permission } from './permissions';
import { getPermissionsByRoles } from './getPermissionsByRoles';

export function hasPermission(
  permission: Permission,
  businessId: string,
  user: User | null
) {
  const permissions = getPermissionsByRoles(
    user?.roles[businessId] ?? ['anonymous']
  );

  return permissions.includes(permission);
}
