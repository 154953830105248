import { createContext, ReactNode } from 'react';
import {
  FieldValues,
  FormProvider,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form';
import { DevTool } from '@hookform/devtools';

interface FormProps<TFieldValues extends FieldValues, TContext> {
  children: ReactNode;
  OnSubmit: (data: TFieldValues) => void;
  onErrors: (errors: unknown) => void;
  formReturn: UseFormReturn<TFieldValues, TContext>;
  NoSubmit?: boolean;
  readOnly?: boolean;
}

export const registerContext = createContext<UseFormRegister<FieldValues>>(
  {} as UseFormRegister<FieldValues>
);
export const formInitialValuesContext = createContext<FieldValues>(
  {} as FieldValues
);

export function Form<TFieldValues extends FieldValues, TContext>({
  children,
  OnSubmit,
  onErrors,
  formReturn,
  NoSubmit,
  readOnly,
}: FormProps<TFieldValues, TContext>) {
  const { handleSubmit, formState: { errors }, } = formReturn;

  const submitMiddleware = (data: TFieldValues) => {
    if (readOnly) return;
    console.log('submitMiddleware', data);
    OnSubmit(data);
  };

  const onErrorMiddleware = () => {
    console.log('onErrorMiddleware', errors);
    onErrors(errors);
  };

  return (
    <>
      <FormProvider {...formReturn}>
        <form
          onSubmit={handleSubmit(submitMiddleware, onErrorMiddleware)}
          data-no-submit={NoSubmit}
        >
          <fieldset disabled={readOnly}>{children}</fieldset>
        </form>
        {window.location.host === 'localhost' && (
          <DevTool control={formReturn.control} />
        )}
      </FormProvider>
    </>
  );
}
