import { defineMessages, useIntl } from 'react-intl';
import {
  CalendarDaysIcon,
  CalendarIcon,
  DocumentTextIcon,
  HomeIcon,
  MapPinIcon,
  TagIcon,
  UsersIcon,
  BanknotesIcon,
  CogIcon,
  CubeIcon,
  ArrowRightStartOnRectangleIcon,
  ArrowPathRoundedSquareIcon,
} from '@heroicons/react/20/solid';
import { useLocation, Link } from 'react-router-dom';
import { signOut, useCurrentUser } from 'frontend/src/services/authService';
import clsx from 'clsx';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { useModuleIsActivatedForBusiness } from 'frontend/src/layers/moduleActivated/hooks';
import { Permission } from 'frontend/src/layers/authorization/permissions';
import { WIP } from 'frontend/src/components/WIP';
import { ModuleName } from 'shared/src/schemas/modules';
import getAuthUrlForThisApp from 'frontend/src/utils/getAuthUrlForApp';
import { getUserAppUrl } from 'frontend/src/utils/getUserAppUrl';

export default function SideBar({ onClose }: { onClose: () => void }) {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const pages = [
    {
      title: formatMessage(t.dashboardPage),
      path: '/',
      icon: HomeIcon,
      wip: true,
      requiredPermission: 'admin.dashboard' as Permission,
      moduleName: 'dashboard' as ModuleName,
    },
    {
      title: formatMessage(t.categoriesPage),
      path: '/categories',
      icon: TagIcon,
      requiredPermission: 'categories.read' as Permission,
      moduleName: 'categories' as ModuleName,
    },
    {
      title: formatMessage(t.usersPage),
      path: '/users',
      icon: UsersIcon,
      requiredPermission: 'users.read' as Permission,
      moduleName: 'users' as ModuleName,
    },
    {
      title: formatMessage(t.lessonsPage),
      path: '/lessons',
      icon: DocumentTextIcon,
      requiredPermission: 'lessons.read' as Permission,
      moduleName: 'lessons' as ModuleName,
    },
    {
      title: formatMessage(t.productsPage),
      path: '/products',
      icon: CubeIcon,
      requiredPermission: 'products.read' as Permission,
      moduleName: 'products' as ModuleName,
    },
    {
      title: formatMessage(t.locationsPage),
      path: '/locations',
      icon: MapPinIcon,
      requiredPermission: 'locations.read' as Permission,
      moduleName: 'locations' as ModuleName,
    },
    {
      title: formatMessage(t.availabilitiesPage),
      path: '/availabilities',
      icon: CalendarIcon,
      requiredPermission: 'availabilities.read' as Permission,
      moduleName: 'availabilities' as ModuleName,
    },
    {
      title: formatMessage(t.reservationsPage),
      path: '/reservations',
      icon: CalendarDaysIcon,
      requiredPermission: 'reservations.read' as Permission,
      moduleName: 'reservations' as ModuleName,
    },
    {
      title: formatMessage(t.ordersPage),
      path: '/orders',
      icon: DocumentTextIcon,
      requiredPermission: 'orders.read' as Permission,
      moduleName: 'orders' as ModuleName,
    },
    {
      title: formatMessage(t.invoicesPage),
      path: '/invoices',
      icon: BanknotesIcon,
      requiredPermission: 'invoices.read' as Permission,
      moduleName: 'invoices' as ModuleName,
    },
    {
      title: formatMessage(t.settingsPage),
      path: '/settings',
      icon: CogIcon,
      requiredPermission: 'settings.read' as Permission,
    },
  ];

  // current path
  const currentPath = location.pathname;
  const isCurrentPath = (path: string) => {
    if (path === '/') {
      return currentPath === path;
    }
    return currentPath.includes(path);
  };
  const { user: currentUser } = useCurrentUser();
  const checkPermission = useIsAuthorized();
  const checkIfModuleIsActivated = useModuleIsActivatedForBusiness();
  const signOutUser = () => {
    signOut();
    window.location.replace(getAuthUrlForThisApp()!);
  };
  return (
    <>
      <div className="flex flex-col">
        <div className="flex bg-white p-2 pl-4 rounded-lg justify-between overflow-ellipsis">
          <p className="w-full overflow-hidden text-ellipsis">
            {currentUser?.email}
          </p>
          <button onClick={signOutUser}>
            <ArrowRightStartOnRectangleIcon className="h-6 w-6 shrink-0" />
          </button>
        </div>
        <a
          href={getUserAppUrl(window.location.host.split('.').slice(-2).join('.'))}
          className="flex justify-center text-gray-300 hover:text-primary-500 gap-4 mt-2 text-xs"
        >
          <ArrowPathRoundedSquareIcon className="h-4 w-4 shrink-0" />
          {formatMessage(t.goToUserApp)}
        </a>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {pages
                .filter((page) => {
                  return (
                    checkPermission(page.requiredPermission) &&
                    checkIfModuleIsActivated(page.moduleName)
                  );
                })
                .map((page) => (
                  <li key={page.path}>
                    <Link
                      to={page.path}
                      onClick={() => {
                        onClose();
                      }}
                      className={clsx(
                        isCurrentPath(page.path)
                          ? 'text-primary-500'
                          : 'text-white hover:text-primary-500 hover:underline',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      <page.icon
                        className={clsx(
                          isCurrentPath(page.path)
                            ? 'text-primary-500'
                            : 'text-white group-hover:text-primary-500',
                          'h-6 w-6 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {page.title}
                      {page.wip && <WIP />}
                    </Link>
                  </li>
                ))}
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
}

const t = defineMessages({
  productsPage: {
    defaultMessage: 'Products',
    description: '',
    id: `defaultlayout_productsPage`,
  },
  lessonsPage: {
    defaultMessage: 'Lessons',
    description: '',
    id: `defaultlayout_lessonsPage`,
  },
  categoriesPage: {
    defaultMessage: 'Categories',
    description: '',
    id: `defaultlayout__categoriesPage`,
  },
  testDashboard: {
    defaultMessage: 'testDashboard',
    description: '',
    id: `defaultlayout_testDashboard`,
  },
  dashboardPage: {
    defaultMessage: 'Dashboard',
    description: '',
    id: `defaultlayout_dashboardPage`,
  },
  usersPage: {
    defaultMessage: 'Users',
    description: '',
    id: `defaultlayout__usersPage`,
  },
  locationsPage: {
    defaultMessage: 'Locations',
    description: '',
    id: `defaultlayout__locationsPage`,
  },
  availabilitiesPage: {
    defaultMessage: 'Availabilities',
    description: '',
    id: `defaultlayout__availabilitiesPage`,
  },
  reservationsPage: {
    defaultMessage: 'Reservations',
    description: '',
    id: `defaultlayout__reservationsPage`,
  },
  ordersPage: {
    defaultMessage: 'Orders',
    description: '',
    id: `defaultlayout__ordersPage`,
  },
  invoicesPage: {
    defaultMessage: 'Invoices',
    description: '',
    id: `defaultlayout__invoices`,
  },
  settingsPage: {
    defaultMessage: 'Settings',
    description: '',
    id: `defaultlayout__settingsPage`,
  },
  goToUserApp: {
    defaultMessage: 'Switch to public view',
    id: `defaultlayout__goToUserApp`,
  },
});
