import { PermissionTreeInput } from '../permissions';
import { userPermissionTree } from './user';

export const trainerPermissionTree = {
  ...userPermissionTree,
  admin: {
    dashboard: true,
  },
  availabilities: {
    read: true,
    write: true,
  },
  lessons: {
    read: false,
    shop: false,
  },
  products: {
    shop: false,
    read: false,
  },
  reservations: {
    read: true,
    giveFeedback: true,
    write: false,
    updateLocation: false,
  },
  myReservations: {
    read: false,
    write: false,
  },
} as const satisfies PermissionTreeInput;
