import { defineMessages, useIntl } from 'react-intl';
import { Lesson } from 'shared/src/schemas/lesson';
import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { ShortTextField } from 'frontend/src/layers/form/components/fields/ShortTextField';
import { SelectField } from 'frontend/src/layers/form/components/fields/SelectField';
import { CheckboxField } from 'frontend/src/layers/form/components/fields/CheckboxField';
import { useQuery } from '@tanstack/react-query';
import { CATEGORY_QUERY_KEY } from '@/pages/categories/lib/categoriesApi';
import { fetchCategories } from '@/services/categoryService';
import toast from 'react-hot-toast';
import { generalTranslations } from '@/generalTranslations';
import { NumberField } from 'frontend/src/layers/form/components/fields/NumberField';
import { FileField } from 'frontend/src/layers/form/components/fields/FileField';
import { collectionNames } from 'shared';
import { ButtonPortal } from 'frontend/src/layers/slide-over/ButtonPortal';
import { TrashIcon } from '@heroicons/react/20/solid';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
export interface LessonFormProps {
  defaultValues?: Lesson;
  onDelete?: (id: string) => void;
}

export function BaseLessonForm({ defaultValues, onDelete }: LessonFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();

  const { data: categories, error } = useQuery({
    queryKey: CATEGORY_QUERY_KEY,
    initialData: [],
    queryFn: () => {
      return fetchCategories();
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.categories),
      })
    );
  }

  const categoryOptions = categories.map((category) => ({
    label: category.name,
    value: category.id,
    key: category.id,
  }));

  return (
    <>
      <HiddenField name={'id'} />
      <ShortTextField name={'name'} label={formatMessage(t.lessonName)} />
      <NumberField
        step={0.25}
        name={'duration'}
        label={formatMessage(t.lessonDuration)}
        postfix={formatMessage(generalTranslations.hours)}
        hint={formatMessage(t.lessonDurationHint)}
      />
      <div className={'md:grid grid-cols-2 gap-4'}>
        <NumberField
          name={'price'}
          step={0.01}
          label={formatMessage(t.lessonPrice)}
          prefix={'€'}
        />
        <NumberField
          name={'vat'}
          label={formatMessage(t.lessonVat)}
          postfix={'%'}
        />
      </div>
      <SelectField
        name={'category'}
        label={formatMessage(t.lessonCategory)}
        options={categoryOptions}
      />
      <HiddenField name={'type'} />

      <ShortTextField
        name={'info'}
        label={formatMessage(t.lessonInfo)}
        hint={formatMessage(t.lessonInfoHint)}
      />
      <ShortTextField
        name={'description'}
        label={formatMessage(t.lessonDescription)}
        hint={formatMessage(t.lessonDescriptionHint)}
      />
      <CheckboxField
        name={'isHidden'}
        label={formatMessage(t.hidden)}
        hint={formatMessage(t.hiddenHint)}
      />
      <NumberField
        name="daysBeforeBooking"
        label={formatMessage(t.bookBefore)}
        hint={formatMessage(t.bookBeforeHint)}
        postfix={formatMessage(generalTranslations.days)}
      />
      <FileField
        filePath={collectionNames.lesson}
        name="image"
        label={formatMessage(t.image)}
      />
      {(defaultValues as Lesson)?.id &&
        onDelete &&
        isAllowedTo('lessons.delete') && (
          <ButtonPortal>
            <button
              className="ml-4 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
              onClick={() => {
                if (!window.confirm('Do you really want to delete?')) {
                  return;
                }
                const id = (defaultValues as Lesson).id!;
                onDelete(id);
              }}
            >
              <TrashIcon className="h-5 w-5" />
            </button>
          </ButtonPortal>
        )}
    </>
  );
}

const t = defineMessages({
  lessonName: {
    defaultMessage: 'Lesson name',
    id: 'lessonform_lessonName',
  },
  lessonInfo: {
    defaultMessage: 'Lesson info',
    id: 'lessonform_lessonInfo',
  },
  lessonInfoHint: {
    defaultMessage:
      'This is the text that will be shown in the email to the customer',
    id: 'lessonform_lessonInfoHint',
  },
  lessonDescription: {
    defaultMessage: 'Lesson description',
    id: 'lessonform_lessonDescription',
  },
  lessonDescriptionHint: {
    defaultMessage: 'This is the text that will be shown on the website',
    id: 'lessonform_lessonDescriptionHint',
  },
  lessonCategory: {
    defaultMessage: 'Lesson category',
    id: 'lessonform_lessonCategory',
  },
  categories: {
    defaultMessage: 'Categories',
    id: 'lessonform_categories',
  },
  lessonPrice: {
    defaultMessage: 'Lesson price (incl vat)',
    id: 'lessonform_lessonPrice',
  },
  lessonDuration: {
    defaultMessage: 'Lesson duration',
    id: 'lessonform_lessonDuration',
  },
  lessonVat: {
    defaultMessage: 'Lesson vat',
    id: 'lessonform_lessonVat',
  },
  lessonDurationHint: {
    defaultMessage: 'format: `1.5` (1 hour and 30 minutes)',
    id: 'lessonform_lessonDurationHint',
  },
  image: {
    defaultMessage: 'Image',
    id: 'lessonform_image',
  },
  hidden: {
    defaultMessage: 'Is hidden',
    id: 'lessonform_hidden',
  },
  hiddenHint: {
    defaultMessage: 'If checked, users can only book via link',
    id: 'lessonform_hiddenHint',
  },
  bookBefore: {
    defaultMessage: 'Book before',
    id: 'lessonform_bookBefore',
  },
  bookBeforeHint: {
    defaultMessage:
      'How many days in advance should the customer book this lesson?',
    id: 'lessonform_bookBeforeHint',
  },
});
