import { ReactNode, useEffect, useRef, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { submitForm } from '../form/utils/submitForm';

export default function SlideOver({
  title,
  children,
  onClose,
  hideSubmit = false,
}: {
  title: string;
  children: ReactNode;
  onClose: () => void;
  hideSubmit?: boolean | undefined;
}) {
  const formRef = useRef<HTMLDivElement>(null);
  const buttonPortalRef = useRef<HTMLDivElement>(null);

  const [fieldsAreDisabled, setFieldsAreDisabled] = useState(false);
  useEffect(() => {
    setFieldsAreDisabled(document.querySelector('fieldset')?.disabled ?? false);
  }, [document.querySelector('fieldset')]);

  return (
    <Dialog as="div" className="relative z-10" onClose={onClose} open>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 sm:pl-16">
            <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
              <div className="flex h-full max-h-screen flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="px-4 sm:px-6 pt-4">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="relative mt-6 flex-1 px-4 sm:px-6 pt-4 overflow-auto"
                  ref={formRef}
                >
                  {children}
                </div>
                {!hideSubmit && (
                  <div
                    className="flex flex-shrink-0 justify-end px-4 py-4"
                    data-button-portals={true}
                    ref={buttonPortalRef}
                    id="slide-over-button-portal"
                  >
                    <button
                      type="button"
                      disabled={fieldsAreDisabled}
                      className="disabled:cursor-not-allowed disabled:opacity-25 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={fieldsAreDisabled}
                      type="submit"
                      className="disabled:cursor-not-allowed disabled:opacity-25 ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                      onClick={() => submitForm(formRef.current)}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
