import { logPageView } from '../lib/analytics';
import { currentBusinessId } from '../services/businessService';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    const businessId = currentBusinessId();
    logPageView(businessId);
  }, [location]);
  return null;
};
