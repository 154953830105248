/* eslint-disable @typescript-eslint/no-explicit-any */
import { Highlight, useHits } from 'react-instantsearch-hooks-web';

type AlgoliaUser = {
  objectID: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  family: {
    objectID: string;
    firstName: string;
    lastName: string;
  }[];
};
function removeHtmlTags(str: string) {
  if (str === null || str === '') return '';
  str = str.toString();
  return str.replace(/<[^>]*>/g, '');
}
export function Hits({
  onHitClick,
  selectFamilyMember,
}: {
  selectFamilyMember?: boolean;
  onHitClick: ({
    userId,
    familyId,
    name,
  }: {
    userId: string;
    familyId?: string;
    name?: string;
  }) => void;
}) {
  const { hits } = useHits<AlgoliaUser>();

  return (
    <div className="overflow-hidden bg-white shadow cursor-pointer">
      <ul
        role="list"
        className="divide-y divide-gray-200 list-none not-prose pl-0 my-0"
      >
        {hits.map((hit) => (
          <li key={hit.objectID}>
            <div
              onClick={() =>
                !selectFamilyMember &&
                onHitClick({
                  userId: hit.objectID,
                  name: removeHtmlTags(`${hit.firstName} ${hit.lastName}`),
                })
              }
              className="block hover:bg-gray-50"
            >
              <div className="px-4 py-4 sm:px-6">
                <div className="min-w-0 grid grid-cols-2">
                  <div className="truncate">
                    <div className="flex text-sm">
                      <p
                        className={`truncate font-medium text-[color:var(--pool-blue)] ${
                          selectFamilyMember
                            ? 'cursor-pointer hover:underline'
                            : ''
                        }`}
                        onClick={() =>
                          selectFamilyMember &&
                          onHitClick({
                            userId: hit.objectID,
                            name: removeHtmlTags(
                              `${hit.firstName} ${hit.lastName}`
                            ),
                          })
                        }
                      >
                        <Highlight attribute="firstName" hit={hit} />{' '}
                        <Highlight attribute="lastName" hit={hit} />
                      </p>
                    </div>
                    <div className="mt-2 flex">
                      <div className="text-sm text-gray-500">
                        <p>
                          {' '}
                          <Highlight attribute="email" hit={hit} />
                        </p>

                        <p>
                          {' '}
                          <Highlight attribute="phone" hit={hit} />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    {hit._highlightResult?.family && (
                      <>
                        <span className="font-medium text-sm">
                          Familie leden:
                        </span>
                        <ul>
                          {((hit._highlightResult?.family as any[]) || []).map(
                            (family: any, index: number) => (
                              <li
                                key={index}
                                className={`list-disc text-gray-500 ${
                                  selectFamilyMember
                                    ? 'cursor-pointer hover:underline'
                                    : ''
                                }`}
                                onClick={
                                  selectFamilyMember
                                    ? () =>
                                        onHitClick({
                                          userId: hit.objectID,
                                          familyId: family.objectID.value,
                                          name: removeHtmlTags(
                                            `${family.firstName.value} ${family.lastName.value}`
                                          ),
                                        })
                                    : undefined
                                }
                              >
                                <p
                                  className="text-sm text-gray-500"
                                  dangerouslySetInnerHTML={{
                                    __html: `${family?.firstName?.value} ${family?.lastName?.value}`,
                                  }}
                                />
                              </li>
                            )
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
