import { Outlet, useParams } from 'react-router-dom';
import en from '../../../../translations/en.json';
import nl from '../../../../translations/nl.json';
import fr from '../../../../translations/fr.json';
import { IntlProvider } from 'react-intl';
import React from 'react';
import { useAtom } from 'jotai';
import { localeAtom } from './AppProviders';

export function LocaleRoute() {
  const [locale] = useAtom(localeAtom);

  if (!locale) {
    return null;
  }

  const localeMessages = { en, nl, fr }[locale as string] || en;

  return (
    <IntlProvider
      locale={locale as string}
      messages={localeMessages}
      key={locale}
    >
      <Outlet />
    </IntlProvider>
  );
}
