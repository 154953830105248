import React from 'react';
import UsersWidget from './components/usersWidget';
import ReservationsWidget from './components/reservations';
import UnlinkedReservationsWidget from './components/unlinkedReservationsWidget';
import RevenueWidget from './components/revenueWidget';
import RevenueGraph from './components/revenueGraph';
import UpcomingReservationsWidget from './components/upcomingReservationsWidget';
import { useCurrentUser } from 'frontend/src/services/authService';
import { currentBusinessId } from 'frontend/src/services/businessService';

export default function TempDashboard() {
  const { user: currentUser } = useCurrentUser();
  const businessId = currentBusinessId()
  const isAdmin = currentUser?.roles[businessId]?.includes('admin')
  return !isAdmin ? (
    <div className="grid place-content-center w-full h-screen text-center gap-8">
      <h1 className="text-4xl font-bold">Dashboard</h1>

      <div className="flex flex-col">
        We werken nog aan deze pagina.
        <a
          href="https://swimplannr-internal.canny.io/feature-request"
          className="text-blue-600 no-underline"
          target="_blank"
        >
          Geef je mening over welke data je hier wilt zien.
        </a>
      </div>
    </div>
  ) : (
    <div className="grid w-full gap-4 md:grid-cols-8 grid-cols-2 pt-8 md:grid-rows-5">
      <div className="md:col-span-2">
        <UsersWidget />
      </div>
      <div className="md:col-span-2">
        <ReservationsWidget />
      </div>
      <div className="md:col-span-2">
        <UnlinkedReservationsWidget />
      </div>
      <div className="md:col-span-2">
        <RevenueWidget />
      </div>
      <div className="md:col-span-5 md:row-span-3">
        <RevenueGraph />
      </div>
      <div className="md:col-span-3 md:row-span-5">
        <UpcomingReservationsWidget />
      </div>
    </div>
  )
}
