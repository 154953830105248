import { z } from 'zod';
import { firebaseDate } from './date';
import { userSchema } from './users';
import { businessSchema } from './business';
import { orderSchema } from './orders';

export const itemSchema = z.object({
  id: z.string(),
  name: z.string(),
  amount: z.number(),
  price: z.number(),
  vat: z.number(),
  doc: z.any(),
});

export const invoiceSchema = z.object({
  id: z.string(),
  createdAt: firebaseDate,
  items: z.array(itemSchema).default([]),
  invoiceNumber: z.string(),
  client: userSchema,
  business: businessSchema,
  totals: z.object({
    totalExcl: z.number(),
    totalVat: z.number(),
    total: z.number(),
  }),
  url: z.string().default(''),
  order: z.union([orderSchema, z.string()]).optional(),
});

export type Invoice = z.infer<typeof invoiceSchema>;
export type Item = z.infer<typeof itemSchema>;
