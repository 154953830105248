import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import { ReactNode } from 'react';

import { Listbox } from '@headlessui/react';

export interface BaseSelectProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  children: ReactNode;
  disabled?: boolean;
  multiple?: boolean;
}
export function BaseListBox<TFieldValues extends FieldValues>({
  name,
  children,
  disabled,
  multiple,
}: BaseSelectProps<TFieldValues>) {
  const { control } = useFormContext();

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <>
      {/* @ts-ignore */}
      <Listbox
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
      >
        {children}
      </Listbox>
    </>
  );
}
