import { Fragment, ReactNode, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import SideBar from './components/SideBar';
import { LoadingBar } from 'frontend/src/components/LoadingBar';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import Logo from 'frontend/src/components/Logo';
import { HelmetProvider } from 'frontend/src/components/HelmetProvider';
import { Banner } from 'frontend/src/components/Banner';
import { usePageTracking } from 'frontend/src/hooks/usePageTracking';

export default function DefaultLayout({ children }: { children: ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  usePageTracking();

  return (
    <>
      <HelmetProvider altLgo />
      <div className="min-h-screen">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-full flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-500 px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center mt-4">
                      <Logo />
                    </div>
                    <SideBar onClose={() => setSidebarOpen(false)} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-500 px-6">
            <div className="flex h-16 shrink-0 items-center mt-4">
              <Logo />
            </div>
            <SideBar onClose={() => setSidebarOpen(false)} />
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-secondary-600 py-4 px-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-primary-50 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            Dashboard
          </div>
        </div>

        <main className="lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8 bg-gray-50 min-h-screen pb-12">
            {(!!isFetching || !!isMutating) && <LoadingBar />}
            {children}
          </div>
        </main>
      </div>

      <Banner />
    </>
  );
}
