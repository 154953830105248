import { ReactNode } from 'react';
import { Permission } from '../permissions';
import { useIsAuthorized } from '../hooks/useIsAuthorized';
import { defineMessages, useIntl } from 'react-intl';
import Logo from '../../../components/Logo';
import getAuthUrlForThisApp from '../../../utils/getAuthUrlForApp';
import { signOut, useCurrentUser } from '../../../services/authService';

export function IsAuthorized({
  children,
  permission,
  isSuperAdmin,
}: {
  children: ReactNode;
  permission: Permission;
  isSuperAdmin?: boolean;
}) {
  const permissionChecker = useIsAuthorized(isSuperAdmin);
  const { user: currentUser } = useCurrentUser();
  const { formatMessage } = useIntl();

  if (!permissionChecker(permission)) {
    return (
      <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
        <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
          <a href={getAuthUrlForThisApp()}>
            <Logo showLoginLogo />
          </a>
        </header>

        <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
          <div className="max-w-lg">
            <p
              className="text-primary-500 font-semibold leading-8 "
              onClick={() => {
                console.log(`Needed permission is ${permission}`);
                console.log(
                  `Current user is ${currentUser?.email}, with roles ${JSON.stringify(
                    currentUser?.roles
                  )}`
                );
              }}
            >
              401
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-secondary-500 sm:text-5xl">
              {formatMessage(t.NotAuthorized)}
            </h1>
            <p className="mt-6 text-base leading-7 text-secondary-600">
              {formatMessage(t.NotAuthorizedDescription)}
            </p>
            <div className="mt-10">
              <button
                onClick={async () => {
                  await signOut();
                  window.location.replace(getAuthUrlForThisApp()!);
                }}
                className="text-sm font-semibold leading-7 text-secondary-500"
              >
                <span aria-hidden="true">&larr;</span>{' '}
                {formatMessage(t.NotAuthorizedTryAgain)}
              </button>
            </div>
          </div>
        </main>

        <div
          className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block bg-no-repeat bg-cover bg-center"
          style={{
            backgroundImage: `var(--login-image)`,
          }}
        ></div>
      </div>
    );
  }
  return <>{children}</>;
}

const t = defineMessages({
  NotAuthorized: {
    defaultMessage: 'No Access',
    id: 'user_not_authorized',
  },
  NotAuthorizedDescription: {
    defaultMessage:
      'It appears you do not have the necessary permissions to access this page. We recommend contacting your club administrator.',
    id: 'user_not_authorized_Description',
  },
  NotAuthorizedTryAgain: {
    defaultMessage: 'Try Again',
    id: 'user_not_authorized_tryAgain',
  },
});
