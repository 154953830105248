import { deleteCategory, saveCategory } from '@/services/categoryService';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Category } from 'shared/src/schemas/category';
import toast from 'react-hot-toast';
import { useCancelFlow } from '@/lib/flow';
import { queryClient } from '@/lib/queryClient';
import { CATEGORY_QUERY_KEY } from '@/pages/categories/lib/categoriesApi';
import { CategoryForm } from '@/form/CategoryForm';

export default function CategoryDialog({ category }: { category?: Category }) {
  const closeFlow = useCancelFlow();
  const { mutate: onDeleteCategory } = useMutation({
    mutationFn: deleteCategory,
    onSuccess: () => {
      toast.success(formatMessage(t.categoryDeletedSuccessfully));
      queryClient.invalidateQueries({ queryKey: CATEGORY_QUERY_KEY });
      closeFlow();
    },
  });
  const { mutate: submitCreateCategory } = useMutation({
    mutationFn: saveCategory,
    onSuccess: () => {
      toast.success(
        formatMessage(
          isEditing
            ? t.categoryUpdatedSuccessfully
            : t.categoryCreationSuccessfully
        )
      );
      queryClient.invalidateQueries({ queryKey: CATEGORY_QUERY_KEY });
      closeFlow();
    },
  });
  const { formatMessage } = useIntl();

  const isEditing = !!(category && category.id);

  return (
    <>
      <CategoryForm
        onSubmit={(data) => {
          submitCreateCategory(data);
        }}
        defaultValues={category}
        onDelete={onDeleteCategory}
      />
    </>
  );
}
const t = defineMessages({
  categoryName: {
    defaultMessage: 'Category name',
    id: `admin-lessons-detail-CategoryDialog-categoryName`,
  },
  categoryCreationSuccessfully: {
    defaultMessage: 'Category created',
    id: `admin-lessons-detail-CategoryDialog-categoryCreationSuccessfully`,
  },
  categoryUpdatedSuccessfully: {
    defaultMessage: 'Category updated',
    id: `admin-lessons-detail-CategoryDialog-categoryUpdatedSuccessfully`,
  },
  categoryDeletedSuccessfully: {
    defaultMessage: 'Category deleted',
    id: `admin-lessons-detail-CategoryDialog-categoryDeletedSuccessfully`,
  },
});
