import { FieldValues } from 'react-hook-form';
import { FormField, FormFieldProps } from '../FormField';
import { DateInput, Props } from '../input/DateInput';

export interface ShortTextFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
    Props<TFieldValues> {}

export function DateField<TFieldValues extends FieldValues>({
  name,
  disabled,
  hint,
  label,
  min,
  max,
}: ShortTextFieldProps<TFieldValues>) {
  return (
    <FormField label={label} hint={hint} name={name}>
      <DateInput name={name} disabled={disabled} min={min} max={max} />
    </FormField>
  );
}
