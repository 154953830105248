import React, { ReactNode } from 'react';
import { getCurrentUser, signInWithToken } from '../services/authService';
import getAuthUrlForThisApp from '../utils/getAuthUrlForApp';
export function CheckLogin({ children }: { children: ReactNode }) {
  const token = new URLSearchParams(window.location.search).get('token');
  console.log('checking login');
  const currentUser = getCurrentUser();
  if (currentUser) {
    console.log('already logged in', currentUser.email);
    token && removeTokenFromUrl();
    return <>{children}</>;
  }
  if (!token) {
    console.log('no token found, redirecting to auth url');
    window.location.href = getAuthUrlForThisApp();
    return <></>;
  }
  console.log('signing in with token');
  signInWithToken(token)
    .then(() => {
      console.log('signed in with token');
      removeTokenFromUrl();
      window.location.reload();
    })
    .catch((err) => {
      console.error('error signing in with token', err);
      window.location.href = getAuthUrlForThisApp();
    });
}
const removeTokenFromUrl = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('token');
  window.history.replaceState({}, '', url.toString());
};
