import { Role } from 'shared/src/schemas/users';
import { Permission } from './permissions';
import { adminPermissionTree } from './permissions/admin';
import { anonymousPermissionTree } from './permissions/anonymous';
import { managerPermissionTree } from './permissions/manager';
import { trainerPermissionTree } from './permissions/trainer';
import { userPermissionTree } from './permissions/user';
import { PermissionTree } from './types/permisssions';
import { superAdminPermissionTree } from './permissions/superAdmin';

export function getPermissionsByRoles(roles: Role[]): Permission[] {
  const permissionTrees = roles.map((role) => {
    switch (role) {
      case 'superAdmin':
        return superAdminPermissionTree;
      case 'admin':
        return adminPermissionTree;
      case 'manager':
        return managerPermissionTree;
      case 'trainer':
        return trainerPermissionTree;
      case 'user':
        return userPermissionTree;
      case 'anonymous':
        return anonymousPermissionTree;
      default:
        return anonymousPermissionTree;
    }
  });

  const dupedPermissions = permissionTrees
    .map((permissionTree) => getAllPermissions(permissionTree))
    .flat();

  return Array.from(new Set(dupedPermissions)) as Permission[];
}

export function getAllPermissions<T extends PermissionTree>(
  permissionTree: T,
  prefix = ''
): Permission[] {
  const truePermissions: string[] = [];

  for (const key in permissionTree) {
    const value = permissionTree[key];

    if (typeof value === 'boolean' && value) {
      truePermissions.push(prefix + key);
    }

    if (typeof value === 'object') {
      const subtreePermissions = getAllPermissions(value, prefix + key + '.');
      truePermissions.push(...subtreePermissions);
    }
  }

  return truePermissions as Permission[];
}
