import { Reservation, reservationSchema } from 'shared/src/schemas/reservation';
import { defineMessages, useIntl } from 'react-intl';
import { UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { v4 as uuid } from 'uuid';
import { Form } from 'frontend/src/layers/form/components/Form';
import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { useQuery } from '@tanstack/react-query';
import {
  fetchPerson,
  fetchTrainers,
  fetchUsers,
} from 'frontend/src/services/usersService';
import { ComboBoxField } from 'frontend/src/layers/form/components/fields/ComboBoxField';
import { UserComboBox } from 'frontend/src/layers/form/components/fields/UserComboBox';
import { DateTimeField } from 'frontend/src/layers/form/components/fields/DateTimeField';
import { LOCATIONS_QUERY_KEY } from '@/pages/locations/api/locationsApi';
import { fetchLocations } from 'frontend/src/services/locationsService';
import { USERS_QUERY_KEY } from '@/pages/users/api/usersApi';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { string } from 'zod';
import { LESSONS_QUERY_KEY } from '@/pages/lessons/api/lessonsApi';
import { fetchLessons } from 'frontend/src/services/lessonService';
import { ShortTextField } from 'frontend/src/layers/form/components/fields/ShortTextField';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { TrashIcon } from '@heroicons/react/24/outline';
import { generalTranslations } from '@/generalTranslations';
import PreviousReservations from '@/components/PreviousReservationsUser';
import { getAvailabilitiesForLesson } from '@/services/timeSlotService';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { LoadingSpinner } from 'frontend/src/components/LoadingSpinner';
import { Option } from 'frontend/src/layers/form/components/input/GenericComboBox';
import toast from 'react-hot-toast';

interface ReservationFormProps {
  defaultValues?: Partial<Reservation>;
  onSubmit: (data: Reservation) => void;
  onDelete?: (data: Reservation) => void;
}

export function ReservationForm({
  onSubmit,
  defaultValues,
  onDelete,
}: ReservationFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();

  const users = defaultValues && defaultValues.user ? [defaultValues.user] : [];

  const { data: lessons } = useQuery({
    queryKey: [...LESSONS_QUERY_KEY],
    queryFn: () =>
      fetchLessons({ all: true, showHidden: true, showArchived: true, type: 'credit' }),

    initialData:
      defaultValues && defaultValues.lesson ? [defaultValues.lesson] : [],
  });

  const { data: locations } = useQuery({
    queryKey: LOCATIONS_QUERY_KEY,
    queryFn: () => fetchLocations({ onlyAllowed: true }),
    initialData:
      defaultValues && defaultValues.location ? [defaultValues.location] : [],
  });

  const { data: listOfTrainers } = useQuery({
    queryKey: ['trainers'],
    queryFn: fetchTrainers,
    initialData:
      defaultValues && defaultValues.trainers && defaultValues.trainers.length
        ? defaultValues.trainers
        : [],
  });
  const reservationFormSchema = reservationSchema.extend({
    location: string().transform((locationId) =>
      locations.find((location) => location.id === locationId)
    ),
    user: string().transform((userId) =>
      fetchUsers({ ids: [userId] }).then((users) => users[0])
    ),
    lesson: string().transform((lessonId) =>
      lessons.find((lesson) => lesson.id === lessonId)
    ),
    trainers: string()
      .array()
      .transform((trainerIds) => {
        if (!trainerIds || !trainerIds.length) return '';
        return listOfTrainers.filter((trainer) =>
          trainerIds.includes(trainer.id)
        );
      })
      .optional(),
    person: string()
      .transform(async (personId) => {
        if (!personId) return '';
        return await fetchPerson(personId);
      })
      .optional(),
    order: string()
      .transform(() => defaultValues?.order)
      .optional(),
  });
  const formReturn = useForm({
    resolver: zodResolver(reservationFormSchema),
    defaultValues: {
      id: uuid(),
      ...defaultValues,
      location: defaultValues?.location?.id,
      user: defaultValues?.user?.id,
      lesson: defaultValues?.lesson?.id,
      person: defaultValues?.person?.id || defaultValues?.user?.id || '',
      order: defaultValues?.order?.id,
      trainers: defaultValues?.trainers?.map((x) => x.id) ?? [],
    },
  });
  const user = useWatch({
    name: 'user',
    control: formReturn.control,
  });
  const lesson = useWatch({
    control: formReturn.control,
    name: 'lesson',
  });
  const start = useWatch({
    control: formReturn.control,
    name: 'start',
  });
  const end = useWatch({
    control: formReturn.control,
    name: 'end',
  });
  const location = useWatch({
    control: formReturn.control,
    name: 'location',
  });
  const trainersWatcher = useWatch({
    control: formReturn.control,
    name: 'trainers',
  });
  const { data: family } = useQuery({
    queryKey: [...USERS_QUERY_KEY, user, 'family'],

    queryFn: () =>
      fetchUsers({
        ids: [user!],
        inclFamily: true,
      }),

    enabled: !!user,
  });

  useEffect(() => {
    const selectedLesson = lessons.find((l) => l.id === lesson);
    if (
      !start ||
      !selectedLesson ||
      formReturn.formState.dirtyFields.end ||
      defaultValues?.end
    ) {
      return;
    }

    // End = start + duration (in hours)
    const end = DateTime.fromJSDate(start)
      .plus({ hours: selectedLesson.duration })
      .toJSDate();

    formReturn.setValue('end', end);
  }, [start, lesson, formReturn.formState.dirtyFields]);

  const availableBadge = () => (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
      <CheckIcon className="h-4 w-4" />
      {formatMessage(t.available)}
    </span>
  );
  const unavailableBadge = () => (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
      <XMarkIcon className="h-4 w-4" />
      {formatMessage(t.unavailable)}
    </span>
  );
  const sortTrainers = (a: Option<string>, b: Option<string>) => {
    if (trainersAvailability) {
      const aAvailable = trainersAvailability.find(
        (x) => x.trainer === a.value
      )?.available;
      const bAvailable = trainersAvailability.find(
        (x) => x.trainer === b.value
      )?.available;
      const aSelected = trainersWatcher?.find((x) => x === a.value);
      const bSelected = trainersWatcher?.find((x) => x === b.value);
      if (aSelected && bSelected) return a.label.localeCompare(b.label);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      if (aAvailable && bAvailable) return a.label.localeCompare(b.label);
      if (aAvailable && !bAvailable) return -1;
      if (!aAvailable && bAvailable) return 1;
      if (!aAvailable && !bAvailable) return a.label.localeCompare(b.label);
    }
    return a.label.localeCompare(b.label);
  };

  const {
    data: trainersAvailability,
    isFetching: isLoadingTrainersAvailability,
  } = useQuery({
    queryKey: ['trainerAvailability', start, lesson, location],
    enabled: !!start && !!lesson && !!location && !!listOfTrainers.length,
    initialData: [],
    queryFn: async () => {
      // Wait 1.5 seconds
      if (!start || !end || !lesson || !location) return [];
      return getAvailabilitiesForLesson({
        end: new Date(end),
        lessonId: lesson,
        locationId: location,
        start: new Date(start),
      });
    },
  });

  return (
    <Form
      onErrors={(errors) => {
        console.log(errors)
        toast.error(formatMessage(generalTranslations.somethingWentWrong))
      }}
      OnSubmit={onSubmit}
      formReturn={formReturn as unknown as UseFormReturn<Reservation>}
      readOnly={
        (defaultValues as Reservation)?.cancelled ||
        (!isAllowedTo('reservations.write') &&
          !isAllowedTo('reservations.giveFeedback'))
      }
    >
      <HiddenField name="id" />
      <UserComboBox
        disabled={!isAllowedTo('reservations.write')}
        name="user"
        users={users}
        label={formatMessage(t.reservationUser)}
      />
      <ComboBoxField
        disabled={!isAllowedTo('reservations.write')}
        name="person"
        label={formatMessage(t.person)}
        options={
          family?.map((fam) => ({
            value: fam.id,
            label: `${fam.firstName} ${fam.lastName}`,
            key: fam.id,
          })) || []
        }
      />
      <PreviousReservations userId={user || ''} />
      <ComboBoxField
        disabled={!isAllowedTo('reservations.write')}
        name="lesson"
        label={formatMessage(t.lesson)}
        options={lessons.map((lesson) => ({
          value: lesson.id,
          label: lesson.name,
          key: lesson.id,
        }))}
      />
      <ComboBoxField
        disabled={
          !!(!isAllowedTo('reservations.updateLocation') && defaultValues?.id)
        }
        name="location"
        label={formatMessage(t.location)}
        options={locations.map((location) => ({
          value: location.id,
          label: location.name || '',
          key: location.id,
        }))}
      />
      <DateTimeField
        disabled={!isAllowedTo('reservations.write')}
        name="start"
        label={formatMessage(t.start)}
        hint="Start of the reservation"
      />
      <DateTimeField
        disabled={!isAllowedTo('reservations.write')}
        name="end"
        label={formatMessage(t.end)}
        hint="End of the reservation"
      />
      <ComboBoxField
        multiple
        disabled={!isAllowedTo('reservations.write')}
        name="trainers"
        options={listOfTrainers.map((trainer) => ({
          label: `${trainer.firstName} ${trainer.lastName}`,
          key: trainer.id,
          value: trainer.id,
        }))}
        customSorting={sortTrainers}
        optionRender={(option) => (
          <span className="flex gap-4">
            {option.label}
            {isLoadingTrainersAvailability ? (
              <LoadingSpinner className="!h-4 !w-4" />
            ) : trainersAvailability?.find((x) => x.trainer === option.value)
              ?.available ? (
              availableBadge()
            ) : (
              unavailableBadge()
            )}
          </span>
        )}
        label={formatMessage(t.trainer)}
      />
      <ShortTextField
        disabled={!isAllowedTo('reservations.giveFeedback')}
        name="feedback.comment"
        label={formatMessage(t.comments)}
        hint={formatMessage(t.commentsHint)}
      />
      {(defaultValues as Reservation)?.id &&
        onDelete &&
        !(defaultValues as Reservation)?.cancelled &&
        isAllowedTo('reservations.write') && (
          <button
            type="button"
            className="inline-flex justify-center rounded-md text-red-600 px-3 py-2 text-sm font-semibold hover:shadow-sm hover:bg-red-500 hover:text-white"
            onClick={() => {
              if (!window.confirm(formatMessage(t.cancelReservationRequest))) {
                return;
              }
              onDelete(defaultValues as Reservation);
            }}
          >
            <TrashIcon className="h-5 w-5" />
            {formatMessage(generalTranslations.delete, {
              resource: formatMessage(t.thisReservation),
            })}
          </button>
        )}
    </Form>
  );
}

const t = defineMessages({
  reservationName: {
    defaultMessage: 'Reservation name',
    id: 'reservationform_reservationName',
  },
  reservationNameHint: {
    defaultMessage: 'Name of the reservation',
    id: 'reservationform_reservationNameHint',
  },
  reservationUser: {
    defaultMessage: 'Reservation user',
    id: 'reservationform_reservationUser',
  },
  location: {
    defaultMessage: 'Location',
    id: 'reservationform_location',
  },
  person: {
    defaultMessage: 'Person joining',
    id: 'reservationform_person',
  },
  lesson: {
    defaultMessage: 'Lesson',
    id: 'reservationform_lesson',
  },
  trainer: {
    defaultMessage: 'Trainer',
    id: 'reservationform_trainer',
  },
  start: {
    defaultMessage: 'Start',
    id: 'reservationform_start',
  },
  end: {
    defaultMessage: 'End',
    id: 'reservationform_end',
  },
  comments: {
    defaultMessage: 'Comments',
    id: 'reservationform_comments',
  },
  commentsHint: {
    defaultMessage:
      'Comments about this lesson. Will be shared with the client.',
    id: 'reservationform_commentsHint',
  },
  thisReservation: {
    defaultMessage: 'this reservation',
    id: 'reservationform_thisReservation',
  },
  cancelReservationRequest: {
    defaultMessage: 'Do you really want to cancel this reservation?',
    id: 'reservationform_cancelReservationRequest',
  },
  previousReservations: {
    defaultMessage: 'Previous reservations',
    id: 'reservationform_previousReservations',
  },
  available: {
    defaultMessage: 'Available',
    id: 'reservationform_available',
  },
  unavailable: {
    defaultMessage: 'Unavailable',
    id: 'reservationform_unavailable',
  },
});
