import { Form } from 'frontend/src/layers/form/components/Form';
import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { ShortTextField } from 'frontend/src/layers/form/components/fields/ShortTextField';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { User, userSchema } from 'shared/src/schemas/users';
import { v4 as uuid } from 'uuid';
import { SelectField } from 'frontend/src/layers/form/components/fields/SelectField';
import { roles } from 'shared/src/schemas/users';
import { capitalizeFirstLetter } from 'shared/src/utils/stringHelpers';
import { generalTranslations } from '@/generalTranslations';
import { CATEGORY_QUERY_KEY } from '@/pages/categories/lib/categoriesApi';
import { fetchCategories } from '@/services/categoryService';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { categorySchema } from 'shared/src/schemas/category';
import { string, z } from 'zod';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { Location, locationSchema } from 'shared/src/schemas/location';
import { LOCATIONS_QUERY_KEY } from '@/pages/locations/api/locationsApi';
import { fetchLocations } from 'frontend/src/services/locationsService';

export interface UserDetailFormProps {
  defaultValues?: User;
  onSubmit: (data: User) => void;
  businessId: string;
}

export default function UserDetailForm({
  onSubmit,
  defaultValues,
  businessId,
}: UserDetailFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();

  const { data: categories, error: errorFetchingCategories } = useQuery({
    queryKey: CATEGORY_QUERY_KEY,
    queryFn: () => {
      return fetchCategories();
    },
    initialData: [],
  });
  if (errorFetchingCategories) {
    console.error(errorFetchingCategories);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.categories),
      })
    );
  }

  const { data: locations, error: errorFetchingLocations } = useQuery({
    queryKey: LOCATIONS_QUERY_KEY,
    queryFn: () => {
      return fetchLocations({});
    },
  });
  if (errorFetchingLocations) {
    console.error(errorFetchingLocations);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.locations),
      })
    );
  }

  const UserDetailFormSchema = userSchema.extend({
    trainerCategories: z.record(
      z.string(),
      string()
        .array()
        .transform((cats) =>
          cats.map((cat) =>
            categorySchema.parse(categories.find((c) => c.id === cat))
          )
        )
    ),
    managingLocations: z.record(
      z.string(),
      string()
        .array()
        .transform((locs) =>
          locs.map((loc) =>
            locationSchema.parse(locations?.find((l) => l.id === loc))
          )
        )
    ),
  });

  const getRecordWithCategoryIds = (record: {
    [x: string]: {
      id: string;
      name: string;
    }[];
  }) => {
    const recordWithCategoryIds: {
      [x: string]: string[];
    } = {};
    Object.keys(record).forEach((key) => {
      recordWithCategoryIds[key] = record[key].map((cat) => cat.id);
    });
    return recordWithCategoryIds;
  };

  const getRecordWithLocationIds = (record: { [x: string]: Location[] }) => {
    const recordWithLocationIds: {
      [x: string]: string[];
    } = {};
    Object.keys(record).forEach((key) => {
      recordWithLocationIds[key] = record[key].map((location) => location.id);
    });
    return recordWithLocationIds;
  };

  const formReturn = useForm({
    resolver: zodResolver(UserDetailFormSchema),
    defaultValues: {
      id: uuid(),
      ...defaultValues,
      roles: defaultValues?.roles[businessId]
        ? defaultValues?.roles
        : { [businessId]: ['user'] },
      trainerCategories: getRecordWithCategoryIds(
        defaultValues?.trainerCategories?.[businessId]
          ? defaultValues?.trainerCategories
          : { [businessId]: [] }
      ),
      managingLocations: getRecordWithLocationIds(
        defaultValues?.managingLocations?.[businessId]
          ? defaultValues?.managingLocations
          : { [businessId]: [] }
      ),
    },
  });

  const watchUserRole = useWatch({
    name: `roles`,
    control: formReturn.control,
  });

  return (
    <Form
      onErrors={(errors) => {
        console.log(errors)
        toast.error(formatMessage(generalTranslations.somethingWentWrong))
      }}
      OnSubmit={onSubmit}
      formReturn={formReturn as unknown as UseFormReturn<User>}
      readOnly={!isAllowedTo('users.write')}
    >
      <HiddenField name="id" />
      <div className="grid grid-cols-2 gap-4">
        <ShortTextField name="firstName" label={formatMessage(t.firstName)} />
        <ShortTextField name="lastName" label={formatMessage(t.lastName)} />
      </div>
      <ShortTextField name="email" label={formatMessage(t.email)} />
      <ShortTextField name="phone" label={formatMessage(t.phone)} />
      <SelectField
        multiple
        name={`roles[${businessId}]`}
        label={formatMessage(t.roles)}
        options={roles.filter(x => x !== 'anonymous' && x !== 'superAdmin').map((role) => {
          return {
            label: capitalizeFirstLetter(role),
            value: role,
            key: role,
          };
        })}
      />
      {watchUserRole?.[businessId]?.includes('trainer') && (
        <SelectField
          multiple
          name={`trainerCategories[${businessId}]`}
          label={formatMessage(t.trainerCategory)}
          options={categories.map((category) => {
            return {
              label: capitalizeFirstLetter(category.name),
              value: category.id,
              key: category.id,
            };
          })}
        />
      )}
      {watchUserRole?.[businessId]?.includes('manager') && (
        <SelectField
          multiple
          name={`managingLocations[${businessId}]`}
          label={formatMessage(t.managingLocations)}
          options={(locations || []).map((location) => {
            return {
              label: `${capitalizeFirstLetter(location.name || '')} (${location.street
                } ${location.number}, ${location.city})`,
              value: location.id,
              key: location.id,
            };
          })}
        />
      )}
    </Form>
  );
}

const t = defineMessages({
  firstName: {
    defaultMessage: 'First name',
    description: '',
    id: 'UsersDetailsForm_firstName',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: '',
    id: 'UsersDetailsForm_lastName',
  },
  email: {
    defaultMessage: 'Email',
    description: '',
    id: 'UsersDetailsForm_email',
  },
  phone: {
    defaultMessage: 'Phone',
    description: '',
    id: 'UsersDetailsForm_phone',
  },
  categories: {
    defaultMessage: 'categories',
    description: '',
    id: 'UsersDetailsForm_categories',
  },
  roles: {
    defaultMessage: 'Roles',
    description: '',
    id: 'UsersDetailsForm_roles',
  },
  trainerCategory: {
    defaultMessage: 'Trainer category',
    description: '',
    id: 'UsersDetailsForm_trainerCategory',
  },
  managingLocations: {
    defaultMessage: 'Managing locations',
    description: '',
    id: 'UsersDetailsForm_managingLocations',
  },
  locations: {
    defaultMessage: 'locations',
    description: '',
    id: 'UsersDetailsForm_locations',
  },
});
