import {
  collection,
  where,
  getDocs,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  DocumentReference,
  query,
  doc,
  setDoc,
} from 'firebase/firestore';
import { collectionNames } from 'shared/src/collectionNames';
import { createBusinessQuery } from './businessService';
import {
  OrderLineSchema,
  orderSchema,
  OrderStatus,
} from 'shared/src/schemas/orders';
import { getFirestore } from '../lib/firebase';
import { personSchema } from 'shared/src/schemas/users';
import { any } from 'zod';
import { locationSchema } from 'shared/src/schemas/location';
import { fetchUserSchema } from './usersService';
import { fetchCreditLessonSchema, fetchCycleLessonSchema } from './lessonService';
import { fetchProductSchema } from './productService';

export const pageSize = 10;
let pages: {
  page: number;
  lastVisible: DocumentSnapshot<DocumentData> | undefined;
}[] = [];
const fetchOrderLineSchema = OrderLineSchema.extend({
  item: any().transform(async (ref: DocumentReference) => {
    const snap = await getDoc(ref);
    if (!snap.exists()) {
      console.error('Document does not exist', ref.path)
      return null
    }
    if (ref.path.startsWith(collectionNames.products)) {
      return fetchProductSchema.parseAsync(snap.data())
    }
    if (snap.data()?.type === 'cycle') {
      return fetchCycleLessonSchema.parseAsync(snap.data())
    }
    return fetchCreditLessonSchema.parseAsync(snap.data())

  }),
  location: any().transform(async (ref: DocumentReference) => {
    if (!ref) return undefined
    const snap = await getDoc(ref);
    return locationSchema.parse(snap.data())
  }),
  person: any().transform(async (ref: DocumentReference) => {
    if (!ref) return '';
    const snap = await getDoc(ref);
    return personSchema.parse(snap.data())
  })
});
const fetchOrderSchema = orderSchema
  .extend({
    user: any().transform(async (ref: DocumentReference) => {
      const snap = await getDoc(ref);
      return fetchUserSchema.parseAsync(snap.data());
    }),
  })
  .omit({ business: true });



export const fetchOrders = async ({
  from,
  to,
  userIds,
  statuses,
  page = 0,
  inclLines = false
}: {
  from?: Date;
  to?: Date;
  userIds?: string[];
  statuses?: OrderStatus[];
  page?: number;
  inclLines?: boolean
}) => {
  const lastVisible = pages.find((p) => p.page === page - 1)?.lastVisible;

  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.orders),
    ...(from ? [where('createdAt', '>=', from)] : []),
    ...(to ? [where('createdAt', '<=', to)] : []),
    ...(userIds && userIds.length ? [where('user', 'in', userIds.map((u) =>
      doc(getFirestore(), `${collectionNames.users}/${u}`)
    ))] : []),
    ...(statuses && statuses.length ? [where('status', 'in', statuses)] : []),
    orderBy('createdAt', 'desc'),
    ...(page && lastVisible ? [startAfter(lastVisible)] : []),
    limit(pageSize)
  );

  const documentSnapShot = await getDocs(q);
  pages = [
    ...pages,
    {
      page,
      lastVisible: documentSnapShot.docs[documentSnapShot.docs.length - 1],
    },
  ];

  let unparsedOrders = documentSnapShot.docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    };
  })

  if (inclLines) {
    unparsedOrders = await Promise.all(
      unparsedOrders.map(async (order) => {
        const lines = await fetchOrderLines(order.id)
        return {
          ...order,
          lines
        }
      })
    )
  }

  const parsedOrders = await fetchOrderSchema.array().parseAsync(
    unparsedOrders
  );

  return parsedOrders
};

export const fetchOrderLines = async (orderId: string) => {
  const q = query(
    collection(getFirestore(), `${collectionNames.orders}/${orderId}/${collectionNames.orderLines}`),
  );
  const documentSnapShot = await getDocs(q);

  return fetchOrderLineSchema.array().parseAsync(
    documentSnapShot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    })
  );
}

export const fetchAmountOfOrders = async ({
  from,
  to,
  userIds,
  statuses,
}: {
  from?: Date;
  to?: Date;
  userIds?: string[];
  statuses?: OrderStatus[];
}) => {
  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.orders),
    ...(from ? [where('createdAt', '>=', from)] : []),
    ...(to ? [where('createdAt', '<=', to)] : []),
    ...(userIds && userIds.length ? [where('user', 'in', userIds.map((u) =>
      doc(getFirestore(), `${collectionNames.users}/${u}`)
    ))] : []),
    ...(statuses && statuses.length ? [where('status', 'in', statuses)] : []),

  );

  return (await getCountFromServer(q)).data().count;
};

export const updateOrderStatus = async ({ orderId, status }: { orderId: string, status: OrderStatus }) => {
  const orderRef = doc(getFirestore(), `${collectionNames.orders}/${orderId}`);
  await setDoc(orderRef, { status }, { merge: true })
}