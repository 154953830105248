import PageHeading from 'frontend/src/components/PageHeading';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { AVAILABILITIES_QUERY_KEY } from './api/availabilitiesApi';
import AvailabilityDialog from './components/AvailabilityDialog';
import { EventsCalendar } from '../../components/EventsCalendar';
import { calendarEventSchema } from 'shared/src/schemas/calendarEvent';
import { SlideOverFlowConduit, useStartFlow } from '@/lib/flow';
import { fetchTimeSlots } from '@/services/timeSlotService';
import { stringToColor } from '@/utils/color';
import { toast } from 'react-hot-toast';
import { generalTranslations } from '@/generalTranslations';
import {
  timeEntrySchema,
  TimeSlot,
  timeSlotSchema,
} from 'shared/src/schemas/timeSlot';
import { stringToPattern } from '@/utils/cssPatterns';
import { useSearchParams } from 'react-router-dom';
import Filters from './components/filters';

export default function AvailabilitiesPage() {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    startDate: searchParams.get('startDate')
      ? new Date(searchParams.get('startDate')!)
      : new Date(),
    endDate: searchParams.get('endDate')
      ? new Date(searchParams.get('endDate')!)
      : new Date(),
    view: searchParams.get('view') || 'week',
    userIds: searchParams.get('userIds')
      ? searchParams.get('userIds')!.split(',')
      : [],
    locationIds: searchParams.get('locationIds')
      ? searchParams.get('locationIds')!.split(',')
      : [],
  });

  useEffect(() => {
    setSearchParams({
      ...filters,
      startDate: filters.startDate.toISOString(),
      endDate: filters.endDate.toISOString(),
      userIds: filters.userIds.join(','),
      locationIds: filters.locationIds.join(','),
    });
  }, [filters]);

  const startFlow = useStartFlow();
  const ADD_AVAILABILITY_DIALOG = 'ADD_AVAILABILITY_DIALOG';
  const EDIT_AVAILABILITY_DIALOG = 'EDIT_AVAILABILITY_DIALOG';

  const { data: availabilities, error } = useQuery({
    queryKey: [...AVAILABILITIES_QUERY_KEY, JSON.stringify(filters)],
    queryFn: () => {
      return fetchTimeSlots({
        start: filters.startDate,
        end: filters.endDate,
        trainerIds: filters.userIds,
        locationIds: filters.locationIds,
      });
    },
    enabled: filters.startDate.toISOString() !== filters.endDate.toISOString(),
    initialData: [],
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.availabilities),
      })
    );
  }

  const getEventTitle = (availability: TimeSlot) => {
    let title = availability.location.name;
    if (availability.trainer)
      title = `${availability.trainer.firstName} ${availability.trainer.lastName} @ ${title}`;
    return title;
  };

  return (
    <>
      <PageHeading
        title={formatMessage(t.title)}
        actions={[
          {
            label: formatMessage(t.create),
            action: () => {
              startFlow({ name: ADD_AVAILABILITY_DIALOG });
            },
            requiredPermission: 'availabilities.write',
          },
        ]}
      />
      <Filters
        selectedUserIds={filters.userIds}
        selectUserIds={(userIds) => setFilters({ ...filters, userIds })}
        selectedLocationIds={filters.locationIds}
        selectLocationIds={(locationIds) =>
          setFilters({ ...filters, locationIds })
        }
      />
      <EventsCalendar
        events={calendarEventSchema.array().parse(
          availabilities.map((availability) => {
            console.log(availabilities);
            return {
              id: availability.id,
              title: getEventTitle(availability),
              start: availability.start,
              end: availability.end,
              color: stringToColor(availability.location.name || ''),
              extraBg: availability.trainer
                ? stringToPattern(availability.trainer.id)
                : undefined,
            };
          })
        )}
        onCreation={({ start, end }) => {
          startFlow({ name: ADD_AVAILABILITY_DIALOG, payload: { start, end } });
        }}
        onSelection={({ eventId }) => {
          const availability = availabilities?.find(
            (availability) => availability.id === eventId
          );
          if (!availability) {
            return;
          }
          startFlow({
            name: EDIT_AVAILABILITY_DIALOG,
            payload: {
              availability,
            },
          });
        }}
        onViewChange={({ startDate, endDate }) => {
          setFilters({
            ...filters,
            startDate,
            endDate,
          });
        }}
      />
      <SlideOverFlowConduit
        title={formatMessage(t.title)}
        flowMapping={{
          [ADD_AVAILABILITY_DIALOG]: (params) => {
            const validatedParams = timeEntrySchema.optional().parse(params);
            return (
              <AvailabilityDialog availability={validatedParams as TimeSlot} />
            );
          },
          [EDIT_AVAILABILITY_DIALOG]: (params) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore

            const validatedParams = timeSlotSchema.parse(params.availability);
            console.log('params' + validatedParams);
            return <AvailabilityDialog availability={validatedParams} />;
          },
        }}
      />
    </>
  );
}

const t = defineMessages({
  title: {
    defaultMessage: 'Availabilities',
    description: '',
    id: 'availibilitiespage-title',
  },
  create: {
    defaultMessage: 'Add availability',
    description: '',
    id: 'availibilitiespage-create',
  },
  availabilities: {
    defaultMessage: 'availabilities',
    description: '',
    id: 'availibilitiespage-availabilities',
  },
});
