const patterns = [
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    backgroundImage:
      'linear-gradient(135deg, #ffffff25 25%, transparent 25%), linear-gradient(225deg, #ffffff25 25%, transparent 25%), linear-gradient(45deg, #ffffff25 25%, transparent 25%), linear-gradient(315deg, #ffffff25 25%, rgba(255, 255, 255, 0) 25%)',
    backgroundPosition: '15px 0, 15px 0, 0 0, 0 0',
    backgroundSize: '15px 15px',
    backgroundRepeat: 'repeat',
  },
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    backgroundImage:
      'repeating-radial-gradient(circle at 0 0, transparent 0, rgba(255, 255, 255, 0) 15px), repeating-linear-gradient(#ffffff2555, #ffffff25)',
  },
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    backgroundImage:
      'linear-gradient(135deg, #ffffff25 25%, transparent 25%), linear-gradient(225deg, #ffffff25 25%, transparent 25%), linear-gradient(45deg, #ffffff25 25%, transparent 25%), linear-gradient(315deg, #ffffff25 25%, rgba(255, 255, 255, 0) 25%)',
    backgroundPosition: '15px 0, 15px 0, 0 0, 0 0',
    backgroundSize: '30px 30px',
    backgroundRepeat: 'repeat',
  },
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    background:
      'repeating-linear-gradient(45deg, #ffffff25, #ffffff25 7.5px, rgba(255, 255, 255, 0) 7.5px, rgba(255, 255, 255, 0) 37.5px)',
  },
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    background:
      'repeating-linear-gradient(-45deg, #ffffff25, #ffffff25 7.5px, rgba(255, 255, 255, 0) 7.5px, rgba(255, 255, 255, 0) 37.5px)',
  },
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    backgroundImage:
      'linear-gradient(0deg, rgba(255, 255, 255, 0) 50%, #ffffff25 50%)',
    backgroundSize: '15px 15px',
  },
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    backgroundImage:
      'linear-gradient(to right, #ffffff25, #ffffff25 7.5px, rgba(255, 255, 255, 0) 7.5px, rgba(255, 255, 255, 0))',
    backgroundSize: '15px 100%',
  },
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    backgroundImage:
      'repeating-linear-gradient(45deg, #ffffff25 25%, transparent 25%, transparent 75%, #ffffff25 75%, #ffffff25), repeating-linear-gradient(45deg, #ffffff25 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 75%, #ffffff25 75%, #ffffff25)',
    backgroundPosition: '0 0, 15px 15px',
    backgroundSize: '30px 30px',
  },
  {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 1,
    backgroundImage:
      'radial-gradient(#ffffff25 2px, transparent 2px), radial-gradient(#ffffff25 2px, rgba(255, 255, 255, 0) 2px)',
    backgroundSize: '80px 80px',
    backgroundPosition: '0 0, 40px 40px',
  },
];

/**
 * Transform string to css pattern
 * @param randomString string
 * @returns {object} css pattern
 */
export const stringToPattern = (randomString: string): object => {
  let hash = 0;
  for (let i = 0; i < randomString.length; i++) {
    hash = randomString.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % patterns.length;
  return patterns[index];
};
