import { PermissionTreeInput } from '../permissions';
import { adminPermissionTree } from './admin';

export const superAdminPermissionTree = {
  ...adminPermissionTree,
  superAdmin: {
    dashboard: true,
  },
  businesses: {
    read: true,
    write: true,
  },
  modules: {
    read: true,
    write: true,
  },
} as const satisfies PermissionTreeInput;
