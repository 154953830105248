import { FieldValues } from 'react-hook-form';
import { FormField, FormFieldProps } from '../FormField';
import { TimeInput, Props } from '../input/TimeInput';

export interface ShortTextFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
    Props<TFieldValues> {}

export function TimeField<TFieldValues extends FieldValues>({
  name,
  disabled,
  hint,
  label,
}: ShortTextFieldProps<TFieldValues>) {
  return (
    <FormField label={label} hint={hint} name={name}>
      <TimeInput name={name} disabled={disabled} />
    </FormField>
  );
}
