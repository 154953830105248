import { generalTranslations } from '@/generalTranslations';
import { CATEGORY_QUERY_KEY } from '@/pages/categories/lib/categoriesApi';
import { LOCATIONS_QUERY_KEY } from '@/pages/locations/api/locationsApi';
import { fetchCategories } from '@/services/categoryService';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { fetchLocations } from 'frontend/src/services/locationsService';
import React, { useState } from 'react';
import { UseFormReturn, useForm, useFieldArray } from 'react-hook-form';
import toast from 'react-hot-toast';
import { defineMessages, useIntl } from 'react-intl';
import { categorySchema } from 'shared/src/schemas/category';
import {
  Cycle,
  CycleLesson,
  cycleDateSchema,
  cycleDaySchema,
  cycleLessonSchema,
  cycleSchema,
} from 'shared/src/schemas/lesson';
import { string, z } from 'zod';
import { v4 as uuid } from 'uuid';
import { BaseLessonForm } from './base';
import { Form } from 'frontend/src/layers/form/components/Form';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { SelectField } from 'frontend/src/layers/form/components/fields/SelectField';
import { DaySelectField } from 'frontend/src/layers/form/components/fields/DaySelectField';
import { NumberField } from 'frontend/src/layers/form/components/fields/NumberField';
import { DateField } from 'frontend/src/layers/form/components/fields/DateField';
import { TimeField } from 'frontend/src/layers/form/components/fields/TimeField';
import { SmallCalendar } from 'frontend/src/components/SmallCalendar';
import { ListBulletIcon, TrashIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/solid';
import { locationSchema } from 'shared/src/schemas/location';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { fetchCurrentUser } from 'frontend/src/services/currentUserService';
import { currentBusinessId } from 'frontend/src/services/businessService';
import { stringToColor } from '@/utils/color';
import { cycleName } from 'shared/src/utils/cycleName';

export interface LessonFormProps {
  defaultValues?: CycleLesson;
  onSubmit: (data: CycleLesson) => void;
  onDelete?: (id: string) => void;
}

export function CycleLessonForm({
  onSubmit,
  defaultValues,
  onDelete,
}: LessonFormProps) {
  const { formatMessage } = useIntl();
  const [locationsToFilterOn, setLocationsToFilterOn] = React.useState<
    string[]
  >([]);
  const isAllowedTo = useIsAuthorized();
  const [selectedCycleIndex, selectCycleIndex] = useState(0);
  const [preferredView, setPreferredView] = useState<'list' | 'calendar'>(
    'list'
  );

  const { data: categories, error: errorFetchingCategories } = useQuery({
    queryKey: CATEGORY_QUERY_KEY,
    initialData: [],
    queryFn: () => {
      return fetchCategories();
    },
  });
  if (errorFetchingCategories) {
    console.error(errorFetchingCategories);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.categories),
      })
    );
  }
  const { data: locations, error: errorFetchingLocations } = useQuery({
    queryKey: LOCATIONS_QUERY_KEY,

    queryFn: () => {
      return fetchLocations({});
    },
  });
  if (errorFetchingLocations) {
    console.error(errorFetchingLocations);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.locations),
      })
    );
  }

  const lessonFormSchema = cycleLessonSchema.extend({
    category: string().transform((cat) => {
      return categorySchema.parse(categories?.find((c) => c.id === cat));
    }),
    cycles: z.array(
      cycleSchema.extend({
        location: string().transform((loc) => {
          return locationSchema.parse(locations?.find((l) => l.id === loc));
        }),
      })
    ),
  });

  const formReturn = useForm({
    resolver: zodResolver(lessonFormSchema),
    defaultValues: {
      id: uuid(),
      vat: 21,
      duration: 0.5,
      price: 0,
      type: 'cycle',
      ...defaultValues,
      cycles:
        defaultValues?.cycles && defaultValues?.cycles.length
          ? defaultValues?.cycles.map((cycle) => ({
            ...cycle,
            location: cycle.location?.id,
            color: '#000000',
          }))
          : [
            cycleSchema.parse({
              cycleDays: [
                cycleDaySchema.parse({
                  id: uuid(),
                  day: 'Monday',
                  time: '13:00',
                }),
              ],
              exceptions: [],
              cycleDates: [cycleDateSchema.parse({})],
              id: uuid(),
              startDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                1
              ),
              endDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 2,
                0
              ),
              location: locations?.[0] ?? undefined,
              maxParticipants: 1,
              min: 0,
            }),
          ],
      category: defaultValues?.category.id,
    },
  });

  const watcher = formReturn.watch();
  const valueSetter = formReturn.setValue;
  const watchCycles = watcher.cycles;
  const watchCycleDays = watcher.cycles?.[selectedCycleIndex]?.cycleDays;
  const watchExceptionDays = watcher.cycles?.[selectedCycleIndex]?.exceptions;
  const priceWatcher = watcher.price;

  const {
    fields: cycles,
    append: appendCycle,
    remove: removeCycle,
  } = useFieldArray({
    control: formReturn.control,
    name: 'cycles',
  });

  const deleteCycle = () => {
    if (
      !window.confirm(formatMessage(t.deleteCyclePrompt)) ||
      cycles.length === 1
    ) {
      return;
    }
    removeCycle(selectedCycleIndex);
    selectCycleIndex(0);
  };

  const addCycle = () => {
    const firstCycle = cycles[0];
    appendCycle({
      ...firstCycle,
      id: uuid(),
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      ),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0),
      price: priceWatcher ?? 0,
    });
    selectCycleIndex(cycles.length);
  };

  const {
    fields: cycleDates,
    append: appendCycleDate,
    remove: removeCycleDate,
  } = useFieldArray({
    control: formReturn.control,
    name: `cycles.${selectedCycleIndex}.cycleDates`,
  });

  const deleteCycleDate = (cycleDayIndex: number) => {
    removeCycleDate(cycleDayIndex);
    return true;
  };
  const addCycleDate = (newDate: Date) => {
    appendCycleDate({
      date: newDate,
      id: uuid(),
    });
  };

  const {
    fields: cycleDays,
    append: appendCycleDay,
    remove: removeCycleDay,
  } = useFieldArray({
    control: formReturn.control,
    name: `cycles.${selectedCycleIndex}.cycleDays`,
  });

  const deleteCycleDay = (cycleDayIndex: number) => {
    if (
      !window.confirm(formatMessage(t.deleteCycleDayPrompt)) ||
      cycleDays.length === 1
    ) {
      return;
    }
    removeCycleDay(cycleDayIndex);
    return true;
  };
  const addCycleDay = () => {
    const firstCycleDay = cycleDays[0];
    appendCycleDay({ ...firstCycleDay });
  };

  const { append: appendExceptionDate, remove: removeExceptionDate } =
    useFieldArray({
      control: formReturn.control,
      name: `cycles.${selectedCycleIndex}.exceptions`,
    });

  const deleteExceptionIfExists = (date: Date) => {
    const exceptionIndex = watchExceptionDays?.findIndex(
      (x) => x.date.getTime() == date.getTime()
    );

    if (exceptionIndex > -1) {
      removeExceptionDate(exceptionIndex);
    }
  };

  useQuery({
    queryKey: [
      'cycleDays',
      selectedCycleIndex,
      watchCycleDays.map((x) => `${x.day} ${x.time}`).join(','),
      watchCycles[selectedCycleIndex].startDate.toISOString(),
      watchCycles[selectedCycleIndex].endDate.toISOString(),
    ],

    queryFn: () => {
      const endDate = new Date(watchCycles[selectedCycleIndex].endDate);
      endDate.setUTCHours(23, 59, 59, 999);

      const selectedDays: Date[] = [];
      watchCycleDays.forEach((cycleDay) => {
        const startDate = new Date();
        startDate.setDate(watchCycles[selectedCycleIndex].startDate.getDate());
        startDate.setMonth(
          watchCycles[selectedCycleIndex].startDate.getMonth()
        );
        startDate.setFullYear(
          watchCycles[selectedCycleIndex].startDate.getFullYear()
        );
        startDate.setUTCHours(0, 0, 0, 0);

        while (startDate <= endDate) {
          if (startDate.getDay() === getSelectedDayNumber(cycleDay.day)) {
            const dateToPush = new Date(startDate);
            const [hours, minutes] = cycleDay.time.split(':');
            dateToPush.setHours(Number(hours));
            dateToPush.setMinutes(Number(minutes));
            // Set time of all exceptiondays
            watchExceptionDays?.forEach((exceptionDay) => {
              const [hours, minutes] = cycleDay.time.split(':');
              exceptionDay.date.setHours(Number(hours));
              exceptionDay.date.setMinutes(Number(minutes));
            });
            if (
              !selectedDays.some((x) => x.getTime() == dateToPush.getTime()) &&
              !watchExceptionDays?.some(
                (y) => y.date.getTime() == dateToPush.getTime()
              )
            ) {
              selectedDays.push(dateToPush);
            }
          }
          startDate.setDate(startDate.getDate() + 1);
        }
      });
      valueSetter(
        `cycles.${selectedCycleIndex}.cycleDates`,
        selectedDays.map((x) => ({
          id: uuid(),
          date: x,
        }))
      );
      return true;
    },
  });

  // Fetch locations to filter on when the component mounts
  React.useEffect(() => {
    const determineFilterCriteria = async () => {
      const currentUser = await fetchCurrentUser({});
      const businessId = await currentBusinessId();

      // Directly check if the user is a manager and set locations to filter on
      if (currentUser.roles[businessId]?.includes('manager')) {
        const locations =
          currentUser.managingLocations?.[businessId]?.map((loc) => loc.id) ||
          [];
        setLocationsToFilterOn(locations);
      }
    };
    determineFilterCriteria();
  }, []);



  const sortedCycles = React.useMemo(() => {
    return cycles
      .filter(
        (cycle) =>
          locationsToFilterOn.length === 0 ||
          locationsToFilterOn.includes(
            typeof cycle.location === 'object'
              ? cycle.location.id
              : cycle.location ?? ''
          )
      )
      .sort((a, b) => (a.color || '').localeCompare(b.color || ''));
  }, [cycles, locationsToFilterOn]);

  return (
    <>
      <Form
        onErrors={(errors) => {
          console.error(errors);
          toast.error(
            formatMessage(generalTranslations.failedToSave, {
              resource: formatMessage(t.lesson),
            })
          );
        }}
        OnSubmit={onSubmit}
        formReturn={formReturn as unknown as UseFormReturn<CycleLesson>}
        readOnly={!isAllowedTo('lessons.write')}
      >
        <BaseLessonForm defaultValues={defaultValues} onDelete={onDelete} />
        <div className="border-l-primary-500 border-l-2 pl-2 mb-2 pt-4">
          <div className="flex items-center gap-4">
            <span className="pb-4">Cycle:</span>
            <div className="w-full relative flex flex-wrap mt-6 mb-6">
              {sortedCycles.map((cycle, cycleIndex) => (
                <div
                  key={cycle.id}
                  className="flex w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-2"
                >
                  <button
                    style={{
                      borderRadius: '9999px', // Make it rounded
                      padding: '10px 10px', // Adjust padding as needed
                      fontSize: '12px', // Adjust font size as needed
                      fontWeight: 'bold', // Adjust font weight as needed
                      backgroundColor: typeof cycle.location === 'object'
                        ? stringToColor(cycle.location.id)
                        : stringToColor(cycle.location?.toString() ?? '')
                      , // Default background color
                      color: '#ffffff', // Text color
                      border:
                        selectedCycleIndex === cycleIndex
                          ? '3px solid #000000'
                          : 'none', // Black border if selected
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      selectCycleIndex(cycleIndex);
                    }}
                  >
                    {cycleName(cycle as Cycle)}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div key={cycles[selectedCycleIndex].id}>
            <div className={'md:grid grid-cols-3 gap-4'}>
              <SelectField
                disabled={cycles[selectedCycleIndex].startDate < new Date()}
                name={`cycles[${selectedCycleIndex}].location`}
                label={formatMessage(t.location)}
                options={
                  locations?.map((x) => ({
                    value: x.id,
                    label: x.name,
                    key: x.id,
                  })) || []
                }
              />
              <NumberField
                name={`cycles[${selectedCycleIndex}].maxParticipants`}
                label={formatMessage(t.maxParticipants)}
              />
              <NumberField
                disabled={cycles[selectedCycleIndex].startDate < new Date()}
                name={`cycles[${selectedCycleIndex}].price`}
                label={formatMessage(t.cyclePrice)}
                prefix={'€'}
              />
            </div>
            <div className="flex w-full justify-end mt-2">
              <button
                className={`${preferredView == 'list'
                  ? 'bg-primary-300 text-white'
                  : 'bg-gray-300'
                  } p-2 rounded-l-lg`}
                onClick={(e) => {
                  e.preventDefault();
                  setPreferredView('list');
                }}
              >
                <ListBulletIcon className="w-4 h-" />
              </button>
              <button
                className={`${preferredView == 'calendar'
                  ? 'bg-primary-300 text-white'
                  : 'bg-gray-300'
                  } p-2 rounded-r-lg relative`}
                onClick={(e) => {
                  e.preventDefault();
                  setPreferredView('calendar');
                }}
              >
                <CalendarDaysIcon className="w-4 h-4" />
                <div className="absolute -top-1 -right-1 bg-primary-500 rounded-full px-1 text-xs text-primary-900">
                  {watchCycles[selectedCycleIndex].cycleDates.length}
                </div>
              </button>
            </div>
            {preferredView == 'list' ? (
              <>
                <div className={'md:grid grid-cols-2 gap-4'}>
                  <DateField
                    disabled={cycles[selectedCycleIndex].startDate < new Date()}
                    name={`cycles[${selectedCycleIndex}].startDate`}
                    label={formatMessage(t.startDate)}
                  />
                  <DateField
                    disabled={cycles[selectedCycleIndex].startDate < new Date()}
                    name={`cycles[${selectedCycleIndex}].endDate`}
                    label={formatMessage(t.endDate)}
                  />
                </div>
                <div className={'p-2 pl-4 border-l-primary-500 border-l-2'}>
                  {cycleDays.map((x, cycleDayIndex) => (
                    <div
                      className={`md:grid grid-cols-2 gap-4 items-start`}
                      key={x.id}
                    >
                      <DaySelectField
                        disabled={
                          cycles[selectedCycleIndex].startDate < new Date()
                        }
                        name={`cycles[${selectedCycleIndex}].cycleDays[${cycleDayIndex}].day`}
                        label={formatMessage(t.day)}
                      />
                      <div className="flex items-center gap-4">
                        <div className="w-full">
                          <TimeField
                            disabled={
                              cycles[selectedCycleIndex].startDate < new Date()
                            }
                            name={`cycles[${selectedCycleIndex}].cycleDays[${cycleDayIndex}].time`}
                            label={formatMessage(t.time)}
                            hint={formatMessage(t.timeHint)}
                          />
                        </div>

                        {cycleDayIndex > 0 && (
                          <button
                            disabled={
                              cycles[selectedCycleIndex].startDate <
                              new Date() || cycleDays.length == 1
                            }
                            className="text-red-500 text-center hover:text-red-900 p-2 disabled:bg-gray-200 disabled:text-gray-400"
                            onClick={(e) => {
                              e.preventDefault();
                              deleteCycleDay(cycleDayIndex);
                            }}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  disabled={cycles[selectedCycleIndex].startDate < new Date()}
                  className="flex items-center gap-4 hover:bg-gray-200 rounded py-2 px-4 disabled:text-gray-400 disabled:hover:bg-transparent disabled:cursor-not-allowed"
                  onClick={(e) => {
                    e.preventDefault();
                    addCycleDay();
                  }}
                >
                  <PlusIcon className="w-4 h-4" />
                  {formatMessage(t.addMoment)}
                </button>
              </>
            ) : (
              <>
                <SmallCalendar
                  disabled={cycles[selectedCycleIndex].startDate < new Date()}
                  selectedDays={cycleDates.map((x) => x.date)}
                  selectDay={(selectedDate: Date) => {
                    // Extend start and end date if needed
                    const cycleDateIndex = cycleDates.findIndex(
                      (x) =>
                        x.date.toDateString() == selectedDate.toDateString()
                    );
                    const selectedWeekDay = selectedDate.toLocaleDateString(
                      'en-US',
                      { weekday: 'long' }
                    );
                    const correspondingCycleDay = watchCycleDays.find(
                      (x) => x.day == selectedWeekDay
                    );
                    if (!correspondingCycleDay) {
                      console.log(
                        `Searching for ${selectedWeekDay} in ${watchCycleDays.map((x) => x.day).join(', ')}`
                      );
                      toast.error(formatMessage(t.defineTimeFirst));
                      return;
                    }

                    // Remove if exists
                    if (cycleDateIndex > -1) {
                      // check if there are still cycleDates left with this calendarDay, else remove cycleDay
                      const remainingCycleDates = cycleDates.filter(
                        (x) =>
                          x.date.toLocaleDateString('en-US', {
                            weekday: 'long',
                          }) == selectedWeekDay &&
                          !watchExceptionDays?.some(
                            (y) => y.date.getTime() == x.date.getTime()
                          )
                      );
                      if (
                        !remainingCycleDates ||
                        remainingCycleDates.length <= 1
                      ) {
                        deleteCycleDay(
                          cycleDays.findIndex((x) => x.day == selectedWeekDay)
                        );
                      }
                      appendExceptionDate({
                        id: uuid(),
                        date: selectedDate,
                      });
                      deleteCycleDate(cycleDateIndex);
                      return;
                    }

                    selectedDate.setHours(
                      Number(correspondingCycleDay.time.split(':')[0])
                    );
                    selectedDate.setMinutes(
                      Number(correspondingCycleDay.time.split(':')[1])
                    );
                    addCycleDate(selectedDate);
                    deleteExceptionIfExists(selectedDate);
                  }}
                />
              </>
            )}

            <div className="flex gap-4 justify-end">
              <button
                className="mt-4 flex items-center gap-4 text-red-500 hover:bg-red-900  hover:text-white rounded py-2 px-4 disabled:bg-gray-200 disabled:text-gray-400"
                disabled={cycles.length == 1}
                onClick={(e) => {
                  e.preventDefault();
                  deleteCycle();
                }}
              >
                <TrashIcon className="w-4 h-4" />
                {formatMessage(t.removeCycle)}
              </button>
              <button
                className="mt-4 flex items-center gap-4 hover:bg-gray-200 rounded py-2 px-4"
                onClick={(e) => {
                  e.preventDefault();
                  addCycle();
                }}
              >
                <PlusIcon className="w-4 h-4" />
                {formatMessage(t.addCycle)}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
const getSelectedDayNumber = (selectedDay: string) => {
  // Input monday
  // Output 1
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days.indexOf(selectedDay);
};
const t = defineMessages({
  locations: {
    id: 'cyclelessonform_locations',
    defaultMessage: 'Locations',
  },
  categories: {
    id: 'cyclelessonform_categories',
    defaultMessage: 'Categories',
  },
  day: {
    defaultMessage: 'Day',
    id: 'cyclelessonform__day',
  },
  cyclePrice: {
    defaultMessage: 'Cycle price (incl vat)',
    id: 'cyclelessonform__cyclePrice',
  },
  startDate: {
    defaultMessage: 'Start date',
    id: 'cyclelessonform__startDate',
  },
  endDate: {
    defaultMessage: 'End date',
    id: 'cyclelessonform__endDate',
  },
  maxParticipants: {
    defaultMessage: 'Max participants',
    id: 'cyclelessonform__maxParticipants',
  },
  maxParticipantsHint: {
    defaultMessage: 'How many participants can join this lesson?',
    id: 'cyclelessonform__maxParticipantsHint',
  },
  location: {
    defaultMessage: 'Location',
    id: 'cyclelessonform__location',
  },
  time: {
    defaultMessage: 'Time',
    id: 'cyclelessonform__time',
  },
  timeHint: {
    defaultMessage: 'Format: `10:00`',
    id: 'cyclelessonform__timeHint',
  },
  duration: {
    defaultMessage: 'Duration',
    id: 'cyclelessonform__duration',
  },

  removeCycle: {
    defaultMessage: 'Remove cycle',
    id: 'lessonform_removeCycle',
  },
  deleteCyclePrompt: {
    defaultMessage: 'Do you really want to delete this cycle?',
    id: 'lessonform_deleteCyclePrompt',
  },
  deleteCycleDayPrompt: {
    defaultMessage: 'Do you really want to delete this cycle date?',
    id: 'lessonform_deleteCycleDayPrompt',
  },
  addCycle: {
    defaultMessage: 'Add cycle',
    id: 'lessonform_addCycle',
  },
  addMoment: {
    defaultMessage: 'Add moment',
    id: 'lessonform_addMoment',
  },
  defineTimeFirst: {
    defaultMessage: 'Please define a time for this day first',
    id: 'lessonform_defineTimeFirst',
  },
  lesson: {
    defaultMessage: 'Lesson',
    id: 'lessonform_lesson',
  }
});