import { generalTranslations } from '@/generalTranslations';
import { CATEGORY_QUERY_KEY } from '@/pages/categories/lib/categoriesApi';
import { fetchCategories } from '@/services/categoryService';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { UseFormReturn, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { defineMessages, useIntl } from 'react-intl';
import { categorySchema } from 'shared/src/schemas/category';
import { CreditLesson, creditLessonSchema } from 'shared/src/schemas/lesson';
import { string } from 'zod';
import { v4 as uuid } from 'uuid';
import { BaseLessonForm } from './base';
import { Form } from 'frontend/src/layers/form/components/Form';

export interface LessonFormProps {
  defaultValues?: CreditLesson;
  onSubmit: (data: CreditLesson) => void;
  onDelete?: (id: string) => void;
}

export function CreditLessonForm({
  onSubmit,
  defaultValues,
  onDelete,
}: LessonFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();

  const { data: categories, error } = useQuery({
    queryKey: CATEGORY_QUERY_KEY,
    initialData: [],
    queryFn: () => {
      return fetchCategories();
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.categories),
      })
    );
  }

  const lessonFormSchema = creditLessonSchema.extend({
    category: string().transform((cat) => {
      return categorySchema.parse({
        id: cat,
        name: categories?.find((c) => c.id === cat)?.name ?? '',
      });
    }),
  });

  const formReturn = useForm({
    resolver: zodResolver(lessonFormSchema),
    defaultValues: {
      id: uuid(),
      vat: 21,
      duration: 0.5,
      price: defaultValues?.price || 0,
      type: 'credit',
      ...defaultValues,
      category: defaultValues?.category.id,
    },
  });

  return (
    <>
      <Form
        onErrors={(errors) => {
          console.log(errors)
          toast.error(formatMessage(generalTranslations.somethingWentWrong))
        }}
        OnSubmit={onSubmit}
        formReturn={formReturn as unknown as UseFormReturn<CreditLesson>}
        readOnly={!isAllowedTo('lessons.write')}
      >
        <BaseLessonForm defaultValues={defaultValues} onDelete={onDelete} />
      </Form>
    </>
  );
}

const t = defineMessages({
  categories: {
    id: 'creditlessonform_categories',
    defaultMessage: 'Categories',
  },
});
