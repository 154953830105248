import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style/index.css';

console.log('NODE_ENV', process.env.NODE_ENV);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
