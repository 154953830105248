import { getAuth } from 'frontend/src/lib/firebase';
import { ReactNode, useState } from 'react';

export function AuthStateProvider({ children }: { children: ReactNode }) {
  const [authStateCheck, setAuthStateCheck] = useState(false);

  getAuth().onAuthStateChanged(() => {
    setAuthStateCheck(true);
  });

  if (!authStateCheck) {
    return null;
  }

  return <>{children}</>;
}
