import { z } from 'zod';
import { categorySchema } from './category';
import { firebaseDate } from './date';
import { locationSchema } from './location';

export const lessonTypesSchema = z.enum(['credit', 'cycle']);

export const lessonSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    price: z.number({ description: 'Price, btw incl' }).default(0),
    vat: z
      .number({ description: 'VAT percentage' })
      .min(0)
      .max(100)
      .default(21),
    duration: z.number({ description: 'Length of lesson in hours' }).min(0),
    category: categorySchema,
    type: lessonTypesSchema.default('credit'),
    info: z
      .string({ description: 'Information shown in the mail' })
      .optional()
      .default(''),
    description: z
      .string({ description: 'Description shown in the app' })
      .optional()
      .default(''),
    image: z.string().optional(),
    isHidden: z.boolean().optional().default(false),
    isArchived: z.boolean().optional().default(false),
    daysBeforeBooking: z
      .number({ description: 'Min. days between Booking and Arrival' })
      .default(1),
  },
  {
    invalid_type_error: 'Invalid lesson data',
  }
);

export const cycleDaySchema = z.object(
  {
    id: z.string().default(''),
    day: z
      .enum([
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ])
      .default('Monday'),
    time: z.string().default('12:00'),
  },
  {
    invalid_type_error: 'Invalid cycle day data',
  }
);
export const cycleDateSchema = z.object(
  {
    id: z.string().default(''),
    date: firebaseDate.default(new Date()),
  },
  {
    invalid_type_error: 'Invalid cycle date data',
  }
);
export const exceptionDateSchema = z.object(
  {
    id: z.string().default(''),
    date: firebaseDate.default(new Date()),
  },
  {
    invalid_type_error: 'Invalid exception date data',
  }
);

export const cycleSchema = z.object(
  {
    id: z.string().default(''),
    cycleDays: z.array(cycleDaySchema).default([cycleDaySchema.parse({})]),
    cycleDates: z.array(cycleDateSchema).default([cycleDateSchema.parse({})]),
    exceptions: z
      .array(exceptionDateSchema)
      .default([exceptionDateSchema.parse({})]),
    startDate: firebaseDate.default(new Date()),
    endDate: firebaseDate.default(new Date()),
    location: locationSchema.optional(),
    color: z.string().default(''),
    price: z
      .number({
        description: 'Price, btw incl',
        invalid_type_error: 'bad, cycle price!',
        coerce: true,
      })
      .optional()
      .default(0),
    maxParticipants: z
      .number({ description: 'Hard cap of the amount of participants' })
      .min(1)
      .default(1),
  },
  {
    invalid_type_error: 'Invalid cycle data',
  }
);

/**
 * A lesson that takes place on a fixed schedule and location
 */
export const cycleLessonSchema = lessonSchema.extend({
  cycles: z.array(cycleSchema).default([]),
});

/**
 * A lesson that can be booked by a user
 * The date is chosen by the user based on the available dates of the trainer and location
 */
export const creditLessonSchema = lessonSchema.extend({});

export type Lesson = z.infer<typeof lessonSchema>;
export type LessonType = z.infer<typeof lessonTypesSchema>;
export type CycleDate = z.infer<typeof cycleDateSchema>;
export type Cycle = z.infer<typeof cycleSchema>;
export type CycleLesson = z.infer<typeof cycleLessonSchema>;
export type CreditLesson = z.infer<typeof creditLessonSchema>;
