import { PermissionTreeInput } from '../permissions';
import { trainerPermissionTree } from './trainer';

export const managerPermissionTree = {
  ...trainerPermissionTree,
  categories: {
    read: true,
    write: true,
  },
  users: {
    read: true,
    write: true,
  },
  lessons: {
    read: true,
    write: true,
    delete: false,
    create: true,
  },
  locations: {
    read: true,
    write: false,
  },
  reservations: {
    read: true,
    write: true,
    giveFeedback: true,
    updateLocation: false,
  },
  products: {
    read: true,
    write: false,
  },
} as const satisfies PermissionTreeInput;
