import { LoadingSpinner } from 'frontend/src/components/LoadingSpinner';
import { generalTranslations } from '../generalTranslations';
import { RESERVATIONS_QUERY_KEY } from '../pages/reservations/api/reservationsApi';
import { useQuery } from '@tanstack/react-query';
import CollapsibleField from 'frontend/src/layers/form/components/fields/CollapsibleField';
import { fetchReservations } from 'frontend/src/services/reservationService';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { defineMessages, useIntl } from 'react-intl';
import { queryClient } from '../lib/queryClient';

export default function PreviousReservations({
  userId,
  personId,
}: {
  userId: string;
  personId?: string;
}) {
  const { formatMessage } = useIntl();
  const [contentIsShown, setContentIsShown] = useState(false);
  const {
    data: reservations,
    error,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: [...RESERVATIONS_QUERY_KEY, userId, personId],
    enabled: contentIsShown && !!userId,
    queryFn: () => {
      const today = new Date();
      const oneYearAgo = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );
      return fetchReservations({
        userIds: [userId],
        start: oneYearAgo,
        end: today,
      });
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.previousReservations).toLowerCase(),
      })
    );
  }
  return (
    <CollapsibleField
      onToggle={(status) => {
        if (!status)
          queryClient.invalidateQueries({
            queryKey: [...RESERVATIONS_QUERY_KEY, userId, personId],
          });
        setContentIsShown(status);
      }}
      label={formatMessage(t.previousReservations)}
    >
      <table className="divide-y divide-gray-300 w-full">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              {formatMessage(t.date)} & {formatMessage(t.location)}
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              {formatMessage(t.lesson)} & {formatMessage(t.trainer)}{' '}
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {formatMessage(t.feedback)}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {isLoading && (
            <tr>
              <td colSpan={5} className="py-4 text-center text-gray-500">
                <LoadingSpinner />
              </td>
            </tr>
          )}
          {!isLoading &&
            isFetched &&
            (!reservations || !reservations.length) && (
              <tr>
                <td colSpan={5} className="py-4 text-center text-gray-500">
                  {formatMessage(t.noReservations)}
                </td>
              </tr>
            )}
          {reservations?.map((reservation) => (
            <tr key={reservation.id}>
              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                {reservation.start.toLocaleDateString()}
                <dt className="sr-only">{formatMessage(t.location)}</dt>
                <dd className="mt-1 truncate text-gray-700">
                  {reservation.location.name}
                </dd>
                <dl className="font-normal lg:hidden">
                  <dt className="sr-only">{formatMessage(t.lesson)}</dt>
                  <dd className="mt-1 truncate text-gray-700">
                    {reservation.lesson.name}
                  </dd>
                  <dt className="sr-only">{formatMessage(t.trainer)}</dt>
                  <dd className="mt-1 truncate text-gray-700">
                    {reservation.trainers.map((x) => x.firstName).join(', ')}
                  </dd>
                </dl>
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                <dt className="sr-only">{formatMessage(t.lesson)}</dt>
                <dd className="mt-1 truncate text-gray-700">
                  {reservation.lesson.name}
                </dd>
                <dt className="sr-only">{formatMessage(t.trainer)}</dt>
                <dd className="mt-1 truncate text-gray-700">
                  {reservation.trainers.map((x) => x.firstName).join(', ')}
                </dd>
              </td>
              <td className="px-3 py-4 text-sm text-gray-500">
                {reservation.feedback?.comment}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CollapsibleField>
  );
}

const t = defineMessages({
  previousReservations: {
    id: 'admin_PreviousReservations_previousReservations',
    defaultMessage: 'Previous Reservations',
  },
  date: {
    id: 'admin_PreviousReservations_date',
    defaultMessage: 'Date',
  },
  location: {
    id: 'admin_PreviousReservations_location',
    defaultMessage: 'Location',
  },
  trainer: {
    id: 'admin_PreviousReservations_trainer',
    defaultMessage: 'Trainer',
  },
  lesson: {
    id: 'admin_PreviousReservations_lesson',
    defaultMessage: 'Lesson',
  },
  feedback: {
    id: 'admin_PreviousReservations_feedback',
    defaultMessage: 'Feedback',
  },
  noReservations: {
    id: 'admin_PreviousReservations_noReservations',
    defaultMessage: 'No reservations',
  },
});
