import { useQuery } from '@tanstack/react-query';
import { ModuleName } from 'shared/src/schemas/modules';
import { moduleKey } from '../api';
import { fetchCurrentlyActiveModules } from '../../../services/businessService';

export function useModuleIsActivatedForBusiness() {
  const { data: isActivated } = useQuery({
    queryKey: [...moduleKey],

    queryFn: () => {
      return fetchCurrentlyActiveModules();
    },
  });

  return function isActive(module?: ModuleName) {
    if (!module) {
      return true;
    }
    const isActive = isActivated?.some((x) => x.id === module);
    return isActive;
  };
}
