import { TimeSlot } from '../schemas/timeSlot';

export default function timeSlotMerger(timeSlots: TimeSlot[]): TimeSlot[] {
  const mergedTimeSlots: TimeSlot[] = [];

  // Group time slots by location and trainer
  const groupedTimeSlots = timeSlots.reduce(
    (groups, timeSlot) => {
      const key = `${timeSlot.location.id}-${timeSlot.trainer?.id}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(timeSlot);
      return groups;
    },
    {} as { [key: string]: TimeSlot[] }
  );

  // Merge time slots within each group
  for (const key in groupedTimeSlots) {
    const group = groupedTimeSlots[key];

    // Sort time slots by start time
    const sortedGroup = group.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );

    // Initialize merged time slots array with the first time slot
    const mergedGroup: TimeSlot[] = [sortedGroup[0]];

    // Merge overlapping and adjacent time slots
    for (let i = 1; i < sortedGroup.length; i++) {
      const currentSlot = sortedGroup[i];
      const mergedSlot = mergedGroup[mergedGroup.length - 1];

      // Check if the current slot overlaps or is adjacent to the merged slot for both location and trainer
      if (
        new Date(currentSlot.start) <= new Date(mergedSlot.end) &&
        currentSlot.location.id === mergedSlot.location.id &&
        currentSlot.trainer?.id === mergedSlot.trainer?.id
      ) {
        // Merge overlapping time slots
        mergedSlot.end = currentSlot.end;
        if (!mergedSlot.ids) {
          mergedSlot.ids = [];
        }
        mergedSlot.ids.push(currentSlot.id);
      } else {
        // Add non-overlapping time slot as a new merged time slot
        mergedGroup.push({ ...currentSlot, ids: [currentSlot.id] });
      }
    }

    // Generate unique IDs for merged time slots
    mergedGroup.forEach((slot) => {
      slot.ids?.unshift(slot.id);
      slot.id = Math.random().toString(36).substr(2, 9);
    });

    // Add merged time slots to the result
    mergedTimeSlots.push(...mergedGroup);
  }

  return mergedTimeSlots;
}
