import tailwindColors from 'tailwindcss/colors';
const colors = [
  tailwindColors.indigo[900],
  tailwindColors.blue[900],
  tailwindColors.cyan[900],
  tailwindColors.teal[900],
  tailwindColors.green[900],
  tailwindColors.emerald[900],
  tailwindColors.lime[900],
  tailwindColors.yellow[900],
  tailwindColors.amber[900],
  tailwindColors.orange[900],
  tailwindColors.red[900],
  tailwindColors.pink[900],
  tailwindColors.fuchsia[900],
  tailwindColors.purple[900],
  tailwindColors.violet[900],
  tailwindColors.rose[900],
  tailwindColors.gray[900],
  tailwindColors.stone[900],
];

/**
 * Transform string to hex color
 * @param randomString string
 * @returns {string} hex color
 */
export const stringToColor = (randomString: string): string => {
  if (!randomString) return colors[0];
  let hash = 0;
  for (let i = 0; i < randomString.length; i++) {
    hash = randomString.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % colors.length;
  return colors[index];
};
