import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { BaseInput } from './BaseInput';

export interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  disabled?: boolean;
}

export function CheckboxInput<TFieldValues extends FieldValues>({
  name,
  disabled,
}: Props<TFieldValues>) {
  const inputClass = `w-6 h-6 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block flex-1 border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 rounded-md`;

  return (
    <BaseInput
      name={name}
      onWheel={() => (document.activeElement as HTMLElement).blur()}
      className={inputClass}
      disabled={disabled}
      type="checkbox"
    />
  );
}
