import { init } from '@sentry/react';

let sentryInitialized = false;
export const initSentry = (dsn: string) => {
  if (!sentryInitialized) {
    init({
      environment: window.location.hostname.includes('localhost')
        ? 'development'
        : 'production',
      dsn,
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    sentryInitialized = true;
  }
};
