import PageHeading from 'frontend/src/components/PageHeading';
import SectionHeading from 'frontend/src/components/SectionHeading';
import { useNavigate } from 'react-router-dom';
import { fetchUsers, saveUser } from 'frontend/src/services/usersService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { USER_QUERY_KEY } from '../api/usersApi';
import { generalTranslations } from '@/generalTranslations';
import UserDetailForm from '@/form/UserDetailForm';
import { submitForm } from 'frontend/src/layers/form/utils/submitForm';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import { currentBusinessId } from 'frontend/src/services/businessService';
import Tabs from 'frontend/src/components/Tabs';
import { updateUserAddress } from 'frontend/src/services/currentUserService';
import { Location } from 'shared/src/schemas/location';
import { queryClient } from '@/lib/queryClient';
import AddressInfoForm from 'frontend/src/layers/form/AddressInfoForm';
import { User } from 'shared/src/schemas/users';

export default function UsersDetailsPage() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { userId } = useParams();
  const formRef = useRef<HTMLDivElement>(null);
  const businessId = currentBusinessId();

  const { refresh } = useInstantSearch();

  const { mutate: submitCreateUser } = useMutation({
    mutationFn: saveUser,
    onSuccess: () => {
      refresh();
      toast.success(formatMessage(t.saved));
      navigate('/users');
    },
  });
  const {
    data: user,
    isFetching: isFetchingUser,
    error,
  } = useQuery({
    queryKey: [...USER_QUERY_KEY, userId],

    queryFn: async () => {
      const users = await fetchUsers(
        {
          ids: [userId!],
          inclAddress: true,
        }!
      );
      if (!users || users.length === 0) {
        return null;
      }
      return users[0];
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.user).toLowerCase(),
      })
    );
  }
  const [toastId, setToastId] = useState<string | undefined>(undefined);
  const { mutate: saveUserAddress } = useMutation({
    mutationFn: ({ address, user }: { address: Location; user?: User }) => {
      setToastId(
        toast.loading(
          formatMessage(generalTranslations.saving, {
            resource: formatMessage(t.address).toLowerCase(),
          })
        )
      );
      return updateUserAddress(address, user);
    },
    onError: (err) => {
      console.error(err);
      toast.dismiss(toastId);
      toast.error(
        formatMessage(generalTranslations.failedToSave, {
          resource: formatMessage(t.address).toLowerCase(),
        })
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: USER_QUERY_KEY });
      toast.dismiss(toastId);
      toast.success(
        formatMessage(generalTranslations.saved, {
          resource: formatMessage(t.address).toLowerCase(),
        })
      );
    },
  });

  const isEditing = user && user.email;
  const options = [
    {
      name: 'accountInfo',
      label: formatMessage(t.generalInfo),
      current: true,
    },
    {
      name: 'address',
      label: formatMessage(t.address),
      wip: false,
    },
  ] as const;
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    options[0].name
  );
  return isFetchingUser ? (
    <p>Loading...</p>
  ) : (
    <>
      <PageHeading
        title={formatMessage(isEditing ? t.editing : t.creating)}
        actions={[
          {
            label: formatMessage(t.save),
            action: () => submitForm(formRef.current!),
            requiredPermission: 'users.write',
          },
          {
            label: formatMessage(t.cancel),
            action: () => {
              toast.error(formatMessage(t.cancelled));
              navigate(-1);
            },
            requiredPermission: 'users.read',
          },
        ]}
      />
      <div ref={formRef}>
        <SectionHeading
          title={formatMessage(t.userInfo)}
          message={formatMessage(t.userInfoMessage)}
        />
        <Tabs
          options={options}
          key={user?.id || 'user'}
          selectOption={(option) => setSelectedOption(option?.name)}
        >
          <>
            {selectedOption === 'accountInfo' && (
              <UserDetailForm
                onSubmit={(data) => {
                  submitCreateUser(data);
                }}
                defaultValues={user || undefined}
                businessId={businessId || ''}
              />
            )}
            {selectedOption === 'address' && (
              <AddressInfoForm
                onErrors={(errors) => {
                  console.log(errors)
                  toast.error(formatMessage(generalTranslations.somethingWentWrong))
                }}
                defaultValues={user?.address}
                onSubmit={(address) => {
                  if (!user) throw new Error('User not found');
                  return saveUserAddress({ address, user });
                }}
              />
            )}
          </>
        </Tabs>
      </div>
    </>
  );
}

const t = defineMessages({
  creating: {
    defaultMessage: 'Creating user',
    description: '',
    id: 'UsersDetailsPage_creating',
  },
  editing: {
    defaultMessage: 'Editing user',
    description: '',
    id: 'UsersDetailsPage_editing',
  },
  save: {
    defaultMessage: 'Save',
    description: '',
    id: 'UsersDetailsPage_save',
  },
  cancel: {
    defaultMessage: 'Cancel',
    description: '',
    id: 'UsersDetailsPage_cancel',
  },
  saved: {
    defaultMessage: 'Changes to user saved successfully',
    description: '',
    id: 'UsersDetailsPage_successfullySaved',
  },
  cancelled: {
    defaultMessage: 'Changes to user cancelled',
    description: '',
    id: 'UsersDetailsPage_cancelEdit',
  },
  userInfo: {
    defaultMessage: 'Basic info',
    description: '',
    id: 'UsersDetailsPage_userInfo',
  },
  userInfoMessage: {
    defaultMessage: 'This is the basic information about the user.',
    description: '',
    id: 'UsersDetailsPage_userInfoMessage',
  },
  user: {
    defaultMessage: 'user',
    description: '',
    id: 'UsersDetailsPage_user',
  },
  generalInfo: {
    defaultMessage: 'General information',
    description: '',
    id: 'UsersDetailsPage_generalInfo',
  },
  address: {
    defaultMessage: 'Address',
    description: '',
    id: 'UsersDetailsPage_address',
  },
  family: {
    defaultMessage: 'Family',
    description: '',
    id: 'UsersDetailsPage_family',
  },
});
