import {
  currentBusinessId,
  getBusinessBranding,
} from '../services/businessService';
import { useQuery } from '@tanstack/react-query';
import chroma from 'chroma-js';
import { BUSINESS_BRANDING_QUERY_KEY } from '../api/keys';
import { downloadFile } from '../lib/storage';
import { useModuleIsActivatedForBusiness } from '../layers/moduleActivated/hooks';
import { brandingSchema } from 'shared/src/schemas/branding';

export function BrandingLoader({ superAdmin }: { superAdmin?: boolean }) {
  const moduleChecker = useModuleIsActivatedForBusiness();
  const businessId = currentBusinessId();
  const setCssVars = (colorName: string, colorHex: string) => {
    const levelNames = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];
    const baseColor = chroma(colorHex);

    const colorScale = chroma
      .scale([baseColor.darken(5), baseColor, baseColor.brighten(5)])
      .mode('lab')
      .colors(11);
    levelNames.forEach((level, i) => {
      const [r, g, b] = chroma(colorScale[i]).rgb();
      document.documentElement.style.setProperty(
        `--${colorName}-${level}`,
        `${r}, ${g}, ${b}`
      );
    });
  };
  useQuery({
    queryKey: [...BUSINESS_BRANDING_QUERY_KEY, businessId],
    queryFn: async () => {
      const isBrandingActive = moduleChecker('branding') == true;
      let branding = brandingSchema.parse({});
      if (isBrandingActive) {
        branding = await getBusinessBranding({
          superAdmin: superAdmin,
          business: businessId,
        });
      }
      const logo = await downloadFile(branding.logo);
      const logoLoginScreen = await downloadFile(branding.logoLoginScreen);
      const loginImage = await downloadFile(branding.loginImage);
      if (branding.primaryColor) {
        setCssVars('primary-color', branding.primaryColor);
      }
      if (branding.secondaryColor) {
        setCssVars('secondary-color', branding.secondaryColor);
      }
      if (logo) {
        document.documentElement.style.setProperty('--logo', `url(${logo})`);
        document.documentElement.style.setProperty(
          '--logo-login-screen',
          `url(${logo})`
        );
      }
      if (logoLoginScreen) {
        document.documentElement.style.setProperty(
          '--logo-login-screen',
          `url(${logoLoginScreen})`
        );
      }
      if (loginImage) {
        document.documentElement.style.setProperty(
          '--login-image',
          `url(${loginImage})`
        );
      }
      return branding;
    },
    enabled:
      moduleChecker('branding') === true || moduleChecker('branding') === false,
  });
  return <></>;
}
