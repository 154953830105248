import { collectionNames } from 'shared/src/collectionNames';
import { categorySchema, Category } from 'shared/src/schemas/category';
import { collection, doc, getDocs, setDoc, where } from 'firebase/firestore';
import { getFirestore } from 'frontend/src/lib/firebase';
import {
  appendBusiness,
  createBusinessQuery,
} from 'frontend/src/services/businessService';

export const fetchCategories = async (): Promise<Category[]> => {
  const fireStore = getFirestore();

  const q = await createBusinessQuery(
    collection(fireStore, collectionNames.categories),
    where('isArchived', '==', false)
  );

  const docs = getDocs(q);

  return categorySchema.array().parse(
    (await docs).docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    })
  );
};

export const saveCategory = async (cat: Category): Promise<void> => {
  const category = await appendBusiness(cat);

  const categoryDoc = doc(
    getFirestore(),
    collectionNames.categories,
    category.id
  );
  await setDoc(categoryDoc, { ...category, isArchived: false });
};

export const deleteCategory = async (id: string): Promise<void> => {
  const categoryDoc = doc(getFirestore(), collectionNames.categories, id);
  // mark as archived
  await setDoc(categoryDoc, { isArchived: true }, { merge: true });
};
