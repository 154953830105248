import { ReactNode } from 'react';
import { ModuleName } from 'shared/src/schemas/modules';
import { defineMessages, useIntl } from 'react-intl';
import { useModuleIsActivatedForBusiness } from '../hooks';

export function IsModuleIsActivated({
  children,
  module,
}: {
  children: ReactNode;
  module?: ModuleName;
}) {
  const { formatMessage } = useIntl();
  const moduleChecker = useModuleIsActivatedForBusiness();

  if (module && !moduleChecker(module)) {
    return (
      <div className="fixed left-0 top-0 bg-white z-50 grid place-items-center w-screen h-screen">
        <span className="w-auto flex gap-4">
          {formatMessage(t.moduleNotActivated)}
        </span>
      </div>
    );
  }

  return <>{children}</>;
}

const t = defineMessages({
  loading: {
    defaultMessage: 'Loading...',
    id: 'moduleChecker_loading',
  },
  moduleNotActivated: {
    defaultMessage: 'Module is not activated',
    id: 'moduleChecker_moduleNotActivated',
  },
});
