import { defineMessages, useIntl } from 'react-intl';

export default function Pagination({
  page = 0,
  totalItems = 0,
  nextPage,
  previousPage,
  pageSize = 10,
}: {
  page: number;
  totalItems: number;
  nextPage: () => void;
  previousPage: () => void;
  pageSize?: number;
}) {
  const { formatMessage } = useIntl();

  const hasNext = (page + 1) * pageSize < totalItems;
  const hasPrevious = page > 0;

  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          {formatMessage(t.showing, {
            from: page * pageSize + 1,
            to: page * pageSize + pageSize <= totalItems ? page * pageSize + pageSize : totalItems,
            total: totalItems,
          })}
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          disabled={!hasPrevious}
          onClick={() => {
            if (!hasPrevious) return;
            previousPage();
          }}
          className="disabled:bg-transparent disabled:text-gray-400 disabled:ring-gray-300 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          Previous
        </button>
        <button
          disabled={!hasNext}
          onClick={() => {
            if (!hasNext) return;
            nextPage();
          }}
          className="disabled:bg-transparent disabled:text-gray-400 disabled:ring-gray-300 relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          Next
        </button>
      </div>
    </nav>
  );
}

const t = defineMessages({
  showing: {
    defaultMessage: 'Showing {from} to {to} of {total} results',
    id: 'user-pagination-showing',
    values: {
      from: 1,
      to: 10,
      total: 100,
    },
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'user-pagination-previous',
  },
  next: {
    defaultMessage: 'Next',
    id: 'user-pagination-next',
  },
});
