import PageHeading from 'frontend/src/components/PageHeading';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Hits } from './components/Hits';
import { Pagination } from './components/Pagination';
import { SearchBox } from './components/SearchBox';
import { v4 as uuid } from 'uuid';
export default function UsersPage() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <PageHeading
        title={formatMessage(t.users)}
        actions={[
          {
            label: formatMessage(t.create),
            action: () => {
              navigate(`/users/${uuid()}`);
            },
            requiredPermission: 'users.write',
          },
        ]}
      />
      <SearchBox />
      <Pagination />
      <Hits
        onHitClick={({ userId }) => {
          navigate(`/users/${userId}`);
        }}
      />
    </>
  );
}

const t = defineMessages({
  users: {
    defaultMessage: 'Users',
    description: '',
    id: `UsersPage_users`,
  },
  create: {
    defaultMessage: 'Add user',
    description: '',
    id: `UsersPage_create`,
  },
});
