import { z } from 'zod';

export const locationSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  street: z.string(),
  number: z.string(),
  mailBox: z.string().optional(),
  city: z.string(),
  zipCode: z.string(),
  country: z.string().default('BE'),
  color: z.string().default('#00FF00'),
});

export type Location = z.infer<typeof locationSchema>;
