import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

export function SmallCalendar({
  selectedDays = [],
  selectDay,
  startsOnMonday = true,
  disabled = true,
}: {
  selectedDays?: Date[];
  selectDay?: (date: Date) => void;
  startsOnMonday?: boolean;
  disabled?: boolean;
}) {
  const days: {
    date: Date;
    isCurrentMonth?: boolean;
    isToday?: boolean;
    isSelected?: boolean;
  }[] = [];
  const start = selectedDays[0] || new Date();
  const [currentMonth, setCurrentMonth] = useState(start.getMonth());

  // Check weekday of start of month
  let startDayOfWeek = new Date(start.getFullYear(), currentMonth, 1).getDay();
  if (startsOnMonday) {
    startDayOfWeek = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;
  }

  // Add last 7 - startDayOfWeek days of previous month in reverse order
  for (let i = startDayOfWeek - 1; i >= 0; i--) {
    days.push({
      date: new Date(start.getFullYear(), currentMonth, -i),
    });
  }

  // get days of month
  const daysOfMonth = new Date(
    start.getFullYear(),
    currentMonth + 1,
    0
  ).getDate();

  for (let i = 1; i <= daysOfMonth; i++) {
    days.push({
      date: new Date(start.getFullYear(), currentMonth, i),
      isCurrentMonth: currentMonth === currentMonth,
      isToday:
        new Date().toDateString() ===
        new Date(start.getFullYear(), currentMonth, i).toDateString(),
    });
  }

  // Get week day of days[days.length - 1]
  const endDayOfWeek = new Date(days[days.length - 1].date).getDay();

  // Add next 7 - endDayOfWeek days of next month
  for (let i = 1; i <= 7 - endDayOfWeek; i++) {
    days.push({
      date: new Date(start.getFullYear(), currentMonth + 1, i),
    });
  }

  days.forEach((day) => {
    day.isSelected = selectedDays.some(
      (selectedDay) =>
        selectedDay.toDateString() === new Date(day.date).toDateString()
    );
  });
  return (
    <>
      <div className="flex items-center text-gray-900 mt-2">
        <button
          onClick={() => setCurrentMonth(currentMonth - 1)}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold text-center">
          {new Date(start.getFullYear(), currentMonth, 1).toLocaleDateString(
            'en-US',
            {
              month: 'long',
              year: 'numeric',
            }
          )}
        </div>
        <button
          onClick={() => setCurrentMonth(currentMonth + 1)}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500 text-center">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date.toLocaleDateString()}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              selectDay && selectDay(day.date);
            }}
            disabled={disabled}
            className={classNames(
              'py-1.5 hover:bg-gray-100 focus:z-10',
              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
              (day.isSelected || day.isToday) && 'font-semibold',
              day.isSelected && 'text-secondary-900',
              !day.isSelected &&
                day.isCurrentMonth &&
                !day.isToday &&
                'text-gray-900',
              !day.isSelected &&
                !day.isCurrentMonth &&
                !day.isToday &&
                'text-gray-400',
              day.isToday && !day.isSelected && 'text-primary-600',
              dayIdx === 0 && 'rounded-tl-lg',
              dayIdx === 6 && 'rounded-tr-lg',
              dayIdx === days.length - 7 && 'rounded-bl-lg',
              dayIdx === days.length - 1 && 'rounded-br-lg',
              disabled && 'cursor-not-allowed disabled:bg-white'
            )}
          >
            <time
              dateTime={day.date.toLocaleDateString()}
              className={classNames(
                'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                day.isSelected && day.isToday && 'bg-primary-500',
                day.isSelected && !day.isToday && 'bg-secondary-500'
              )}
            >
              {day.date.getDate()}
            </time>
          </button>
        ))}
      </div>
    </>
  );
}
