import BrandingFrom from '@/form/brandingForm';
import React, { useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { defineMessages, useIntl } from 'react-intl';
import PageHeading from 'frontend/src/components/PageHeading';
import SectionHeading from 'frontend/src/components/SectionHeading';
import { BRANDING_QUERY_KEY } from '../api/settingsApi';
import {
  getBusinessBranding,
  updateBusinessBranding,
} from 'frontend/src/services/businessService';
import toast from 'react-hot-toast';
import { submitForm } from 'frontend/src/layers/form/utils/submitForm';
import { queryClient } from '@/lib/queryClient';

export default function BrandingSettingsPage() {
  const { formatMessage } = useIntl();
  const formRef = useRef<HTMLDivElement>(null);

  const { data: branding, isLoading } = useQuery({
    queryKey: [...BRANDING_QUERY_KEY],

    queryFn: () => getBusinessBranding(),
  });

  const { mutate: updateBranding } = useMutation({
    mutationFn: updateBusinessBranding,
    onSuccess: () => {
      toast.success(formatMessage(t.saved));
      queryClient.invalidateQueries({ queryKey: BRANDING_QUERY_KEY });
    },
    onError: () => {
      toast.error(formatMessage(t.errorSaving));
    },
  });
  if (isLoading)
    return (
      <>
        <p>Loading...</p>
      </>
    );
  return (
    <div ref={formRef}>
      <PageHeading
        title={formatMessage(t.branding)}
        actions={[
          {
            label: formatMessage(t.save),
            action: () => {
              submitForm(formRef.current!);
            },
            requiredPermission: 'settings.write',
          },
        ]}
      />
      <SectionHeading
        title={formatMessage(t.updateBranding)}
        message={formatMessage(t.updateBrandingDescription)}
      />
      <BrandingFrom defaultValues={branding} onSubmit={updateBranding} />
    </div>
  );
}

const t = defineMessages({
  branding: {
    id: 'brandingSettingsPage_branding',
    defaultMessage: 'Branding',
  },
  save: {
    id: 'brandingSettingsPage_save',
    defaultMessage: 'Save',
  },
  updateBranding: {
    id: 'brandingSettingsPage_updateBranding',
    defaultMessage: 'Update Branding',
  },
  updateBrandingDescription: {
    id: 'brandingSettingsPage_updateBrandingDescription',
    defaultMessage: "Update your club's branding",
  },
  saved: {
    id: 'brandingSettingsPage_saved',
    defaultMessage: 'Branding saved successfully.',
  },
  errorSaving: {
    id: 'brandingSettingsPage_errorSaving',
    defaultMessage: 'Something went wrong while saving branding.',
  },
});
