import { FieldValues, Path, useFormContext } from 'react-hook-form';

export interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
}

export function HiddenInput<TFieldValues extends FieldValues>({
  name,
  ...props
}: Props<TFieldValues>) {
  const { register } = useFormContext();
  return <input {...register(name)} type={'hidden'} {...props} />;
}
