import React from 'react';
import { banner as bannerConfig } from '../../../../config';
export function Banner() {
  return (
    bannerConfig && (
      <div className="fixed top-0 left-0 bg-black border-t border-gray-700 z-50 text-white font-bold py-2 px-4 text-center">
        {bannerConfig}
      </div>
    )
  );
}
