import { ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';

export function ButtonPortal({ children }: { children: ReactNode }) {
  const [portal, setPortal] = useState<HTMLElement | null>(null);

  requestAnimationFrame(() => {
    const portalRef = document.getElementById('slide-over-button-portal');
    setPortal(portalRef);
  });

  if (!portal) {
    return null;
  }

  return createPortal(children, portal);
}
