import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Label } from '../FormField';

export default function CollapsibleField({
  label,
  children,
  onToggle,
}: {
  label: string;
  children: React.ReactNode;
  onToggle?: (toggleStatus: boolean) => void;
}) {
  const { formatMessage } = useIntl();
  const [showContent, setShowContent] = useState(false);

  const toggle = () => {
    const newShowContent = !showContent;
    setShowContent(newShowContent);
    if (onToggle) onToggle(newShowContent);
  };

  const collapsibleButton = (
    <button
      title={formatMessage(showContent ? t.hide : t.show)}
      type="button"
      onClick={() => {
        toggle();
      }}
    >
      <ChevronDownIcon
        className={`h-5 w-5 transition-all ${showContent ? 'rotate-180' : ''}`}
      />
    </button>
  );
  return (
    <div className="py-1.5 px-3 mb-4 rounded-md border border-gray-300">
      <Label label={label} decoration={collapsibleButton}>
        {showContent && <div className="mt-2">{children}</div>}
      </Label>
    </div>
  );
}

const t = defineMessages({
  show: {
    id: 'admin_CollapsibleField_show',
    defaultMessage: 'Show',
  },
  hide: {
    id: 'admin_CollapsibleField_hide',
    defaultMessage: 'Hide',
  },
});
