import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { BaseGraph } from "./base";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { INVOICES_QUERY_KEY } from "@/pages/invoices/api/invoicesApi";
import { fetchRevenueForTimePeriod } from "@/services/invoicesService";

export default function revenueGraph() {
  const { formatMessage } = useIntl();
  const today = DateTime.now();
  // Get past 12 months
  const initialData = Array.from({ length: 12 }, () => 0);
  const pastMonths = Array.from({ length: 12 }, (_, i) => today.minus({ months: i })).reverse();
  console.log({ pastMonths })
  const { data: revenueByMonth, isFetching, isLoading } = useQuery({
    queryKey: [...INVOICES_QUERY_KEY, "byMonth", "sum"],
    queryFn: () => {
      return Promise.all(pastMonths.map(async month =>
        await fetchRevenueForTimePeriod({
          from: month.startOf('month').toJSDate(),
          to: month.endOf('month').toJSDate()
        })
      ))
    },
    initialData: initialData
  })
  return (
    <>
      <BaseGraph
        categories={pastMonths.map(m => m.monthShort)}
        values={revenueByMonth}
        title={formatMessage(t.revenueByMonth)}
        isLoading={isFetching || isLoading}
        type="valuta"
      />
    </>
  );
}

const t = defineMessages({
  revenueByMonth: {
    id: "revenue_graph_revenue_by_month",
    defaultMessage: "Revenue by month"
  }
});