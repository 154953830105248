import React, { ReactNode } from 'react';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';

export interface FormFieldProps<TFieldValues extends FieldValues> {
  label?: string | ReactNode;
  className?: string;
  hint?: string | ReactNode;
  children?: ReactNode;
  name: Path<TFieldValues>;
}

export function FormField<TFieldValues extends FieldValues>({
  label,
  className,
  hint,
  children,
  name,
}: FormFieldProps<TFieldValues>) {
  const { control } = useFormContext();
  const {
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  return (
    <div className={`${className} mb-4`}>
      <Label label={label}>
        <div className="flex flex-col">
          {children}
          {invalid && (
            <p className="text-xs text-red-600" id={`${name}-error`}>
              {error?.message}
            </p>
          )}
          {hint && (
            <p className="text-xs  text-ellipsis whitespace-nowrap">{hint}</p>
          )}
        </div>
      </Label>
    </div>
  );
}

export function Label({
  label,
  children,
  decoration,
  onClick,
}: {
  label?: string | ReactNode;
  children: ReactNode;
  decoration?: ReactNode;
  onClick?: () => void;
}) {
  if (!label) {
    return <>{children}</>;
  }
  return (
    <label
      className={`h-full block text-sm font-medium leading-6 text-gray-900 text-ellipsis whitespace-nowrap ${onClick ? 'cursor-pointer' : ''}`}
    >
      <div
        className={`flex justify-between ${onClick ? 'hover:underline' : ''}`}
      >
        {label}
        {decoration}
      </div>
      {children}
    </label>
  );
}
