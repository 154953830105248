import { useForm } from 'react-hook-form';
import { Business, businessSchema } from 'shared/src/schemas/business';
import { zodResolver } from '@hookform/resolvers/zod';
import { v4 as uuid } from 'uuid';
import { Form } from 'frontend/src/layers/form/components/Form';
import { defineMessages, useIntl } from 'react-intl';
import { ShortTextField } from 'frontend/src/layers/form/components/fields/ShortTextField';
import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { EmailField } from 'frontend/src/layers/form/components/fields/EmailField';
import { PhoneField } from 'frontend/src/layers/form/components/fields/PhoneField';
import { NumberField } from 'frontend/src/layers/form/components/fields/NumberField';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { useModuleIsActivatedForBusiness } from 'frontend/src/layers/moduleActivated/hooks';
import toast from 'react-hot-toast';
import { generalTranslations } from '@/generalTranslations';

export interface BusinessFormProps {
  defaultValues?: Business;
  onSubmit: (data: Business) => void;
}

export function BusinessForm({ onSubmit, defaultValues }: BusinessFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();
  const isModuleActive = useModuleIsActivatedForBusiness();
  const formReturn = useForm<Business>({
    resolver: zodResolver(businessSchema),
    defaultValues: {
      id: uuid(),
      ...defaultValues,
    },
  });

  return (
    <div>
      <Form
        onErrors={(errors) => {
          console.log(errors);
          toast.error(formatMessage(generalTranslations.somethingWentWrong))
        }}
        OnSubmit={onSubmit}
        formReturn={formReturn}
        readOnly={!isAllowedTo('settings.write')}
      >
        <HiddenField name="id" />
        <ShortTextField
          name="name"
          label={formatMessage(t.businessName)}
          hint={formatMessage(t.businessNameHint)}
        />
        <EmailField name="email" label={formatMessage(t.email)} />
        <PhoneField name="phone" label={formatMessage(t.phone)} />
        {isModuleActive('assist') && (
          <ShortTextField
            name="assistToken"
            label={formatMessage(t.assistToken)}
          />
        )}
        <ShortTextField name="vat" label={formatMessage(t.vatNumber)} />
        <ShortTextField name="iban" label={formatMessage(t.iban)} />
        <ShortTextField name="bic" label={formatMessage(t.bic)} />
        <NumberField
          name="paymentTime"
          label={formatMessage(t.paymentTime)}
          postfix={formatMessage(t.days)}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-3">
            <ShortTextField name="street" label={formatMessage(t.street)} />
          </div>
          <ShortTextField name="number" label={formatMessage(t.streetNumber)} />
          <ShortTextField name="mailBox" label={formatMessage(t.mailBox)} />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <ShortTextField name="zipCode" label={formatMessage(t.zipCode)} />
          <ShortTextField name="city" label={formatMessage(t.city)} />
          <ShortTextField name="country" label={formatMessage(t.country)} />

        </div>
        <ShortTextField
          name="remarks"
          label={formatMessage(t.remarks)}
          hint={formatMessage(t.remarksHint)}
        />
        {isModuleActive('bookkeepingemail') && (
          <ShortTextField
            name="forwardInvoiceEmail"
            label={formatMessage(t.bookkeepingEmail)}
            hint={formatMessage(t.bookkeepingHint)}
          />
        )}
      </Form>
    </div>
  );
}
const t = defineMessages({
  businessName: {
    defaultMessage: 'Business name',
    id: 'businessform_businessName',
  },
  businessNameHint: {
    defaultMessage: 'Commercial name of the business',
    id: 'businessform_businessNameHint',
  },
  email: {
    defaultMessage: 'Email',
    id: 'businessform_email',
  },
  phone: {
    defaultMessage: 'Phone',
    id: 'businessform_phone',
  },
  assistToken: {
    defaultMessage: 'Assist token',
    id: 'businessform_assistToken',
  },
  vatNumber: {
    defaultMessage: 'VAT number',
    id: 'businessform_vatNumber',
  },
  iban: {
    defaultMessage: 'IBAN',
    id: 'businessform_iban',
  },
  bic: {
    defaultMessage: 'BIC',
    id: 'businessform_bic',
  },
  paymentTime: {
    defaultMessage: 'Payment time',
    id: 'businessform_paymentTime',
  },
  days: {
    defaultMessage: 'days',
    id: 'businessform_days',
  },
  street: {
    defaultMessage: 'Street',
    id: 'businessform_street',
  },
  streetNumber: {
    defaultMessage: 'Number',
    id: 'businessform_streetNumber',
  },
  mailBox: {
    defaultMessage: 'Mailbox',
    id: 'businessform_mailBox',
  },
  zipCode: {
    defaultMessage: 'Zip code',
    id: 'businessform_zipCode',
  },
  city: {
    defaultMessage: 'City',
    id: 'businessform_city',
  },
  country: {
    defaultMessage: 'Country',
    id: 'businessform_country',
  },
  bookkeepingEmail: {
    defaultMessage: 'Bookkeeping email',
    id: 'businessform_BokkeepingEmail',
  },
  bookkeepingHint: {
    defaultMessage: 'Where can we send your pdf invoices to?',
    id: 'businessform_BokkeepingEmailHint',
  },
  remarks: {
    defaultMessage: 'Remarks',
    id: 'businessform_Remarks',
  },
  remarksHint: {
    defaultMessage: 'Can we add some remarks on your invoice?',
    id: 'businessform_RemarksHint',
  },
});
