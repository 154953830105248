import {
  User,
  userSchema,
  Person,
  personSchema,
  Role,
  roles,
} from 'shared/src/schemas/users';
import { collectionNames } from 'shared/src/collectionNames';
import {
  DocumentReference,
  collection,
  collectionGroup,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { getFirestore, getFunctions } from '../lib/firebase';
import { currentBusinessId } from './businessService';
import { any } from 'zod';
import { categorySchema } from 'shared/src/schemas/category';
import { Location, locationSchema } from 'shared/src/schemas/location';
import { httpsCallable } from 'firebase/functions';

const businessId = currentBusinessId();

export const fetchUserSchema = userSchema.extend({
  trainerCategories: any().transform(
    async (trainerCategories: Record<string, DocumentReference[]>) => {
      if (
        !trainerCategories ||
        !trainerCategories[businessId] ||
        !trainerCategories[businessId].length
      )
        return { [businessId]: [] };
      const categories = trainerCategories[businessId];
      // Fetch all categories for this user
      const categoryDocs = await Promise.all(
        categories.map((ref) => getDoc(ref))
      );
      // Parse the categories
      return {
        [businessId]: categoryDocs.map((doc) =>
          categorySchema.parse({
            ...doc.data(),
            id: doc.id,
          })
        ),
      };
    }
  ),
  managingLocations: any().transform(
    async (managingLocations: Record<string, DocumentReference[]>) => {
      if (!managingLocations || !managingLocations[await businessId])
        return { [await businessId]: [] };
      const locations = managingLocations[await businessId];

      // Fetch all locations for this user
      const locationDocs = await Promise.all(
        locations.map((ref) => getDoc(ref))
      );
      // Parse the locations
      return {
        [await businessId]: locationDocs.map((doc) =>
          locationSchema.parse({
            ...doc.data(),
            id: doc.id,
          })
        ),
      };
    }
  ),
});

export const fetchUser = async (id: string) => {
  const users = await fetchUsers({ ids: [id] });
  return users[0] || null;
};

export const fetchAddress = async (user: User): Promise<Location[]> => {
  if (!user.id) return [];
  const q = await query(
    collection(
      getFirestore(),
      `${collectionNames.users}/${user.id}/${collectionNames.addresses}`
    )
  );

  const documentSnapShot = await getDocs(q);

  const addresses = locationSchema.array().parse(
    documentSnapShot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    })
  );
  return addresses;
};

export const fetchFamily = async (user: User): Promise<Person[]> => {
  if (!user.id) return [];
  const q = await query(
    collection(
      getFirestore(),
      `${collectionNames.users}/${user.id}/${collectionNames.family}`
    )
  );

  const documentSnapShot = await getDocs(q);

  const family = personSchema.array().parse(
    documentSnapShot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        birthDate: doc.data().birthDate ? doc.data().birthDate.toDate() : null,
      };
    })
  );
  if (!family.some((p) => p.id === user.id)) {
    family.push(
      personSchema.parse({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      })
    );
  }
  return family;
};

export const saveUser = async (user: User): Promise<void> => {
  const userDoc = doc(getFirestore(), collectionNames.users, user.id);
  const bId = await currentBusinessId();
  const trainerCategories = {
    [bId]: user.trainerCategories?.[bId].map((c) =>
      doc(getFirestore(), collectionNames.categories, c.id)
    ),
  };
  const managingLocations = {
    [bId]: user.managingLocations?.[bId].map((l) =>
      doc(getFirestore(), collectionNames.addresses, l.id)
    ),
  };
  const roles = {
    ...user.roles,
    [bId]: user.roles?.[bId] || ['user'],
  };

  const address = user.address;
  if (address) {
    const addressDoc = doc(
      getFirestore(),
      `${collectionNames.users}/${user.id}/${collectionNames.addresses}`,
      address.id
    );
    await setDoc(addressDoc, address);
    delete user.address;
  }

  const family = user.family;
  if (family) {
    const familyDocs = family.map((p) => {
      const personDoc = doc(
        getFirestore(),
        `${collectionNames.users}/${user.id}/${collectionNames.family}`,
        p.id
      );
      return setDoc(personDoc, p);
    });
    await Promise.all(familyDocs);
    delete user.family;
  }

  await setDoc(
    userDoc,
    {
      ...user,
      trainerCategories,
      managingLocations,
      roles,
    },
    { merge: true }
  );
};

export const fetchTrainers = async () => {
  return fetchUsers({ role: 'trainer' });
};

export const fetchUsers = async ({
  ids,
  role = 'user',
  inclFamily = false,
  inclAddress = false,
  debug = false,
}: {
  ids?: string[];
  role?: Role;
  inclFamily?: boolean;
  inclAddress?: boolean;
  debug?: boolean;
} = {}) => {
  debug && console.log({ ids, role, inclFamily, inclAddress });

  const baseFilters = query(
    collection(getFirestore(), collectionNames.users),
    ...(role && (!ids || !ids.length)
      ? [where(`roles.${businessId}`, 'array-contains', role)]
      : [
        where(
          `roles.${businessId}`,
          'array-contains-any',
          roles
        ),
      ])
  );

  let documentData;
  // if ids -> loop through ids and fetch each user with the base filters and save the docs in documentSnapshot
  if (ids && ids.length) {
    documentData = await Promise.all(
      ids.map(async (id) => {
        debug && console.log('fetching user', id);
        const snapshot = await getDocs(
          query(baseFilters, where('id', '==', id))
        );
        debug && console.log('fetched users', snapshot.docs);
        return snapshot.docs[0]?.data();
      })
    );
  } else {
    documentData = (await getDocs(baseFilters)).docs.map((doc) => doc.data());
  }

  debug && console.log(`Found ${documentData?.length} users`, documentData);

  let users = await Promise.all(
    documentData.filter(x => !!x).map((doc) => {
      debug && console.log('parsing user', doc);
      return fetchUserSchema.parseAsync({
        ...doc,
        id: doc.id,
      });
    })
  );

  if (inclFamily) {
    debug && console.log('fetching family');
    users = await Promise.all(
      users.map(async (user) => {
        return {
          ...user,
          family: await fetchFamily(user),
        };
      })
    );
  }

  if (inclAddress) {
    debug && console.log('fetching address');
    users = await Promise.all(
      users.map(async (user) => {
        const addresses = await fetchAddress(user);
        return {
          ...user,
          address: addresses[0],
        };
      })
    );
  }

  debug && console.log('fetched users', JSON.stringify(users, null, 2));
  return users;
};

export const fetchPerson = async (id: string) => {
  const q = query(
    collectionGroup(getFirestore(), collectionNames.family),
    where('id', '==', id)
  );

  const documentSnapShot = await getDocs(q);
  if (documentSnapShot.size === 0) return undefined;
  return personSchema.parseAsync(documentSnapShot.docs[0].data());
};

export const syncUsers = async () => {
  const status = await httpsCallable(getFunctions(), 'user-syncAll')({
  });
  return status;
};

export const usersCount = async () => {
  const businessId = currentBusinessId();
  const baseFilters = query(
    collection(getFirestore(), collectionNames.users),
    where(
      `roles.${businessId}`,
      'array-contains-any',
      roles.filter(x => x !== 'anonymous')
    ),
  );

  const count = getCountFromServer(baseFilters);
  return (await count).data().count;
}

export const mergeAccounts = (data: { oldEmail: string; newEmail: string }) => {
  console.log('Merging accounts', data)
  return httpsCallable(getFunctions(), 'user-merge')(data);
}

