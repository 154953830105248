import React from 'react';
import {
  usePagination,
  UsePaginationProps,
} from 'react-instantsearch-hooks-web';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid';

export function Pagination(props: UsePaginationProps) {
  const { pages, refine, currentRefinement, isFirstPage, isLastPage } =
    usePagination(props);

  if (pages.length <= 1) {
    return null;
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        {!isFirstPage && (
          <a
            onClick={() => refine(currentRefinement - 1)}
            className="cursor-pointer inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </a>
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map((page) => (
          <a
            key={page}
            onClick={() => refine(page)}
            className={`${
              page === currentRefinement
                ? 'border-[color:var(--pool-blue)] text-[color:var(--pool-blue)]'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            } cursor-pointer inline-flex items-center border-t-2 pt-4 px-4 text-sm font-medium`}
          >
            {page + 1}
          </a>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {!isLastPage && (
          <a
            onClick={() => refine(currentRefinement + 1)}
            className="cursor-pointer inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </a>
        )}
      </div>
    </nav>
  );
}
