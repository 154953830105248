import {
  CreditLesson,
  CycleLesson,
  Lesson,
  creditLessonSchema,
  cycleLessonSchema,
  cycleSchema,
  lessonSchema,
} from 'shared/src/schemas/lesson';
import { collectionNames } from 'shared/src/collectionNames';
import { appendBusiness, createBusinessQuery } from './businessService';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  QueryDocumentSnapshot,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import { Schema, any } from 'zod';
import { categorySchema } from 'shared/src/schemas/category';
import { getFirestore } from '../lib/firebase';
import { uploadFile } from '../lib/storage';
import { locationSchema } from 'shared/src/schemas/location';

export const pageSize = 10;
let pages: {
  page: number;
  lastVisible: QueryDocumentSnapshot<DocumentData> | undefined;
}[] = [];

const lessonExtension = {
  category: any().transform(async (ref: DocumentReference) => {
    const snap = await getDoc(ref);

    const catDocumentSnapData = snap.data();
    return categorySchema.parse(catDocumentSnapData);
  }),
};
export const fetchLessonSchema = lessonSchema.extend(lessonExtension);
export const fetchCreditLessonSchema =
  creditLessonSchema.extend(lessonExtension);
export const fetchCycleLessonSchema = cycleLessonSchema.extend({
  ...lessonExtension,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cycles: any().transform(async (cycles: any[]) => {
    return await Promise.all(
      cycles.map(async (cycle) => {
        return cycleSchema.parseAsync({
          ...cycle,
          location: await locationSchema.parseAsync(
            (await getDoc(cycle.location)).data()
          ),
        });
      })
    );
  }),
});

export const fetchLessons = async ({
  page = 0,
  ids = [],
  all = false,
  showHidden = false,
  showArchived = false,
  type = 'all'
}: {
  ids?: string[];
  page?: number;
  all?: boolean;
  showHidden?: boolean;
  showArchived?: boolean;
  type?: 'all' | 'credit' | 'cycle';
}): Promise<Lesson[] | CreditLesson[] | CycleLesson[]> => {
  const lastVisible = pages.find((p) => p.page === page - 1)?.lastVisible;
  const fireStore = getFirestore();

  const q = await createBusinessQuery(
    collection(fireStore, collectionNames.lesson),
    ...(ids.length ? [where('id', 'in', ids)] : []),
    ...(page && lastVisible ? [startAfter(lastVisible)] : []),
    ...(showArchived || ids.length ? [] : [where('isArchived', '==', false)]),
    ...(showHidden || ids.length ? [] : [where('isHidden', '==', false)]),
    ...(all ? [] : [limit(pageSize)]),
    ...(type === 'all' ? [] : [where('type', '==', type)])
  );

  const docs = await getDocs(q);
  pages = [
    ...pages,
    {
      page,
      lastVisible: docs.docs[docs.docs.length - 1],
    },
  ];

  return Promise.all(
    docs.docs.map(async (doc) => {
      const lesson = doc.data();
      // This is just for backwards compatibility
      let schema: Schema = fetchLessonSchema;
      if (lesson.type === 'cycle') {
        schema = fetchCycleLessonSchema;
      }

      // Check and set default values for properties
      lesson.daysBeforeBooking = lesson.daysBeforeBooking || 0; // Default to 0 if undefined
      return await schema.parseAsync({
        id: doc.id,
        ...lesson,
      });
    })
  );
};

export const saveLesson = async (
  _lesson: Lesson | CreditLesson | CycleLesson
): Promise<void> => {
  const lesson = await appendBusiness(_lesson);
  const lessonDoc = doc(getFirestore(), collectionNames.lesson, lesson.id);

  // Download image to firebase storage if no image is set
  let uploadedFile = { path: '' };
  if (!lesson.image) {
    const imageUrl = `https://api.dicebear.com/6.x/shapes/png?seed=${lesson.id}`;
    const image = new File(
      [await (await fetch(imageUrl)).blob()],
      `${lesson.name}.svg`
    );
    uploadedFile = await uploadFile({
      path: collectionNames.lesson,
      file: image,
    });
  }
  const data = await appendBusiness({
    ...lesson,
    category: doc(
      getFirestore(),
      collectionNames.categories,
      lesson.category.id
    ),
    image: lesson.image || uploadedFile.path,
    cycles: [{}],
  });

  if (_lesson.type === 'cycle') {
    data.cycles = (_lesson as CycleLesson).cycles.map((cycle) => {
      const cyclePrice = cycle.price !== undefined ? cycle.price : lesson.price;
      return {
        ...cycle,
        location: doc(
          getFirestore(),
          collectionNames.addresses,
          cycle.location?.id || ''
        ),
        price: cyclePrice,
      };
    });
  }

  await setDoc(lessonDoc, { ...data, isArchived: false });
};

export const deleteLesson = async (id: string): Promise<void> => {
  const lessonDoc = doc(getFirestore(), collectionNames.lesson, id);
  // mark as archived
  await setDoc(lessonDoc, { isArchived: true }, { merge: true });
};
