import {
  collection,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
  getCountFromServer,
  QueryDocumentSnapshot,
  DocumentData,
  DocumentReference,
  getDoc,
  getAggregateFromServer,
  sum,
} from 'firebase/firestore';
import { collectionNames } from 'shared/src/collectionNames';
import { invoiceSchema } from 'shared/src/schemas/invoice';
import { createBusinessQuery } from 'frontend/src/services/businessService';
import { getFirestore, getFunctions } from 'frontend/src/lib/firebase';
import { any } from 'zod';
import { httpsCallable } from 'firebase/functions';
import { fetchUserSchema } from 'frontend/src/services/usersService';
import { downloadFile } from 'frontend/src/lib/storage';

const fetchInvoiceSchema = invoiceSchema
  .extend({
    client: any().transform(async (ref: DocumentReference) => {
      const snap = await getDoc(ref);
      return fetchUserSchema.parseAsync(snap.data());
    }),
  })
  .omit({ order: true, business: true });

export const pageSize = 10;
let pages: {
  page: number;
  lastVisible: QueryDocumentSnapshot<DocumentData> | undefined;
}[] = [];
export const fetchInvoices = async ({
  from,
  to,
  userId,
  page = 0,
}: {
  from?: Date;
  to?: Date;
  userId?: string;
  page?: number;
}) => {
  const lastVisible = pages.find((p) => p.page === page - 1)?.lastVisible;

  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.invoices),
    ...(from ? [where('createdAt', '>=', from)] : []),
    ...(to ? [where('createdAt', '<=', to)] : []),
    ...(userId ? [where('userId', '==', userId)] : []),
    orderBy('createdAt', 'desc'),
    ...(page && lastVisible ? [startAfter(lastVisible)] : []),
    limit(pageSize)
  );

  const documentSnapShot = await getDocs(q);
  pages = [
    ...pages,
    {
      page,
      lastVisible: documentSnapShot.docs[documentSnapShot.docs.length - 1],
    },
  ];
  return fetchInvoiceSchema.array().parseAsync(
    documentSnapShot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    })
  );
};

export const fetchAmountOfInvoices = async ({
  from,
  to,
  userId,
}: {
  from?: Date;
  to?: Date;
  userId?: string;
}): Promise<number> => {
  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.invoices),
    ...(from ? [where('createdAt', '>=', from)] : []),
    ...(to ? [where('createdAt', '<=', to)] : []),
    ...(userId ? [where('userId', '==', userId)] : [])
  );

  return (await getCountFromServer(q)).data().count;
};

export const regenerateInvoice = async ({
  invoiceNr,
}: {
  invoiceNr: string;
}) => {
  return httpsCallable(
    getFunctions(),
    'invoice-invoiceRegeneration'
  )({ invoiceNr }).then((res) => {
    console.log(res);
  });
};

export const downloadInvoices = async ({
  start,
  end,
  businessId

}: {
  start: string;
  end: string;
  businessId: string;
}) => {
  const downloadFunction = httpsCallable(getFunctions(), 'invoice-downloadInvoices');

  try {
    const result = await downloadFunction({
      startDate: start,
      endDate: end,
      businessId: businessId
    }) as { data: { path: string, message: string } };

    const downloadPath = result.data.path;
    const file = downloadFile(downloadPath);
    window.open(await file, '_blank');

  } catch (error) {
    console.error("Error downloading invoices:", error);
    throw error; // Re-throwing the error to be handled by the caller
  }
};

export const fetchRevenueForTimePeriod = async ({
  from,
  to,
}: {
  from: Date,
  to: Date,
}) => {
  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.invoices),
    where('createdAt', '>=', from),
    where('createdAt', '<=', to),
  );


  return (await getAggregateFromServer(q, {
    totalRevenue: sum('totals.total')
  })).data().totalRevenue;
}