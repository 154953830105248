import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import { ChangeEvent, InputHTMLAttributes } from 'react';
import zod from 'zod';

export interface BaseInputProps<TFieldValues extends FieldValues>
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'name' | 'onChange' | 'onBlur' | 'value' | 'ref'
  > {
  name: Path<TFieldValues>;
}

export function BaseInput<TFieldValues extends FieldValues>({
  name,
  type = 'text',
  ...props
}: BaseInputProps<TFieldValues>) {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
  });

  const inputValue = getInputValue(type, value);

  function change(e: ChangeEvent<HTMLInputElement>) {
    if (type === 'datetime-local' || type === 'date') {
      // @ts-ignore
      onChange(zod.date().parse(new Date(e.target.value)));
      return;
    }

    if (type === 'number') {
      // @ts-ignore
      onChange(Number(e.target.value));
      return;
    }
    onChange(e);
  }

  return (
    <>
      <input
        id={name}
        type={type}
        name={name}
        {...props}
        ref={ref}
        defaultValue={type !== 'file' ? inputValue : ''}
        onChange={(e) => {
          change(e);
        }}
        {...(type === 'checkbox' ? { checked: value } : {})}
        onBlur={onBlur}
      />
    </>
  );
}

function getInputValue(type: string, value: unknown) {
  if (type === 'number') {
    return isNaN(Number(value)) ? '' : Number(value);
  }

  if (type === 'datetime-local' && value instanceof Date) {
    const date = new Date(value.getTime() + _getTimeZoneOffsetInMs());
    return date.toISOString().slice(0, 16);
  }
  if (type === 'date' && value instanceof Date) {
    const date = new Date(value.getTime() + _getTimeZoneOffsetInMs());
    return date.toISOString().slice(0, 10);
  }

  if (!value) return '';

  return value as string | number | readonly string[];
}

function _getTimeZoneOffsetInMs() {
  return new Date().getTimezoneOffset() * -60 * 1000;
}
