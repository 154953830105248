import React from "react";
import BaseWidget from "./base";
import { defineMessages, useIntl } from "react-intl";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import { countReservations } from "frontend/src/services/reservationService";
import { DateTime } from "luxon";
import { nFormatter } from "shared/src/utils/numberFormatter";


export default function ReservationsWidget() {
  const { formatMessage } = useIntl();
  const { data: theNumberOfReservations, isLoading } = useQuery({
    queryKey: ["reservations", "count"],
    queryFn: () => {
      const beginOfMonth = DateTime.now().startOf('month').toJSDate();
      const endOfMonth = DateTime.now().endOf('month').toJSDate();
      return countReservations({
        from: beginOfMonth,
        to: endOfMonth
      })
    },
    initialData: 0
  });
  return (
    <BaseWidget
      icon={(<CalendarDaysIcon />)}
      isLoading={isLoading}
      number={theNumberOfReservations || "0"}
      title={formatMessage(t.reservationsThisMonth)}
      formatter={nFormatter}
      extraText={formatMessage(t.thisMonth)}
    />
  );
}
const t = defineMessages({
  reservationsThisMonth: {
    id: "reservations_widget_reservations_this_month",
    defaultMessage: "Reservations"
  },
  thisMonth: {
    id: "reservations_widget_this_month",
    defaultMessage: "This month"
  }
})